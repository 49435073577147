import React, { useState, useRef, useEffect }  from "react";
import produce from 'immer';
import auth from "../auth";
import Version from "../version";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import ServerState from '../controls/ServerState';
import SparkleDialog from '../controls/components/SparkleDialog';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles'
import TopContainer from '../controls/TopContainer';
import { SubscriptionBox } from '../controls/SubscriptionBox';
import { Grid, Divider } from '@material-ui/core';
import { LargeButton } from "../controls/components/Buttons";
import { List, ListItem, ListSubheader, FormControlLabel, Checkbox } from "@material-ui/core";

export default function Subscriptions() {
    const { t } = useTranslation();
    const theme = useTheme();
    const { state, vm } = useConnect("Subscriptions", { AllowServiceChanges: false, AllowPriceplanChanges: false, NotifyAddressChanges: false, NotifyPriceplanChanges: false, NotifyServiceChanges: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, showBuyDataButton: false, dialogOpen: false });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    const dialogRef = useRef();
    const handleRefresh = () => {
        vm.$dispatch({ Refresh: true });
    }
    const changeSetting = (e, setting) => {
        vm.$dispatch({ ChangeSetting: { Setting: setting, Value: e.target.checked } });
    };

    useEffect(() => {
        if (state.WebOrderUrl !== undefined && state.WebOrderUrl !== '' && state.WebOrderUrl !== null) {
            dialogRef.current.openUrl(state.WebOrderUrl);
        }
    }, [state.WebOrderUrl]);


    return (
        <React.Fragment>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            <SparkleDialog ref={dialogRef} handleRefresh={handleRefresh} subscriptionNo={state.SubscriptionNo} />
            <TopContainer title={gstate.IsSweden.get() ? 'Abonnemang' : 'Abonnement' } noSelect>
                <div style={{ width: '100%', marginBottom: '17px', fontSize: '14px', textAlign: 'center' }}>{gstate.IsNorway.get() ? 'Endre eller legg til tjenester' : 'Lägg till eller ändra tjänster'}</div>
                <SubscriptionBox main inverse subscription={state.Subscription} isAdmin={true} isNorway={gstate.IsNorway.get()} />
                {state.SubscriptionList?.length > 0 &&
                    <>
                        <div style={{ width: '100%', textAlign: 'center', fontSize: '16px' }}>{gstate.IsNorway.get() ? state.IsResidential ? 'Familie' : 'Bedrift' : state.IsResidential  ? 'Familjen' : 'Företaget' }</div>
                        <Divider style={{ width: '80%', marginBottom: '10px' }} />
                    </>
                }
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    {state.SubscriptionList?.map((sub, index) => {
                        return (
                            <SubscriptionBox key={index} subscription={sub} isAdmin={state.IsAdmin} isNorway={gstate.IsNorway.get()} />
                        )
                    })}
                </Grid>
                {state.WebOrderButtonText &&
                    <div style={{ width: '100%', marginBottom: '35px', marginTop: '15px', textAlign: 'center' }}>
                        <LargeButton color="primary" onClick={() => { vm.$dispatch({ OrderSubscription: true}) } }>{state.WebOrderButtonText}</LargeButton>
                    </div>
                }
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <List>
                        <ListSubheader style={{ color: '#e91e60', position: 'static', padding: '0px', lineHeight: '28px', marginBottom: '-8px' }}>{t('selfcare:title.changes')}</ListSubheader>
                        <ListItem style={{ padding: '0px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        disabled={!state.IsAdmin}
                                        checked={state.AllowServiceChanges}
                                        onClick={(e) => { changeSetting(e, 'allowservice'); }}
                                    />
                                }
                                label={<div style={{ fontFamily: theme.typography.fontFamily2 }}>{t('selfcare:label.shouldallowservicechanges')}</div>}
                                labelPlacement="end"
                            />
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        disabled={!state.IsAdmin}
                                        checked={state.AllowPriceplanChanges}
                                        onClick={(e) => { changeSetting(e, 'allowpriceplan'); }}
                                    />
                                }
                                label={<div style={{ fontFamily: theme.typography.fontFamily2 }}>{t('selfcare:label.shouldallowsubscriptionchanges')}</div>}
                                labelPlacement="end"
                            />
                        </ListItem>
                        <ListSubheader style={{ color: '#e91e60', position: 'static', padding: '0px', lineHeight: '28px', marginBottom: '-8px' }}>{t('selfcare:title.notification-by-email')}</ListSubheader>
                        <ListItem style={{ padding: '0px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        disabled={!state.IsAdmin}
                                        checked={state.NotifyAddressChanges}
                                        onClick={(e) => { changeSetting(e, 'notifyaddress'); }}
                                    />
                                }
                                label={<div style={{ fontFamily: theme.typography.fontFamily2 }}>{t('selfcare:label.notifystaffchanges')}</div>}
                                labelPlacement="end"
                            />
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        disabled={!state.IsAdmin}
                                        checked={state.NotifyPriceplanChanges}
                                        onClick={(e) => { changeSetting(e, 'notifypriceplan'); }}
                                    />
                                }
                                label={<div style={{ fontFamily: theme.typography.fontFamily2 }}>{t('selfcare:label.notifysubscriptionchanges')}</div>}
                                labelPlacement="end"
                            />
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        disabled={!state.IsAdmin}
                                        checked={state.NotifyServiceChanges}
                                        onClick={(e) => { changeSetting(e, 'notifyservice'); }}
                                    />
                                }
                                label={<div style={{ fontFamily: theme.typography.fontFamily2 }}>{t('selfcare:label.notifyservicechanges')}</div>}
                                labelPlacement="end"
                            />
                        </ListItem>
                    </List>

                </Grid>
            </TopContainer>
        </React.Fragment>
    );
}