import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SupportIcon from "./components/SupportIcon";

export const Box = (props) => {
    const wDim = useWindowDimensions();
    const theme = useTheme();
    const width = props.fullWidth ? '100%' : wDim.desktop ? '47%' : '100%';
    return (
        <Grid
            item
            role={props.linkAction && !props.disabled ? "button" : ""}
            style={{
                border: props.border
                    ? `1px solid ${theme.palette.primary.main}`
                    : "none",
                background: props.background
                    ? `${theme.palette.boxBackground.main} 0% 0% no-repeat padding-box`
                    : "transparent",
                width: width,
                color: theme.palette.text.primary,
                opacity: 1,
                textDecoration: "none",
                borderRadius: "10px",
                padding: "17px 17px",
                marginBottom: "19px",
                cursor: props.linkAction && !props.disabled ? "pointer" : "auto",
                position: props.relative ? "relative" : "static",
                ...props.style
            }}
            onClick={
                props.linkAction && !props.disabled
                    ? () => {
                        props.linkAction();
                    }
                    : null
            }
            weight={props.weight ? props.weight : 2}
        >
            {props.children}
        </Grid>
    );
};

export const BoxTitle = (props) => {
    const theme = useTheme();
    return (
        <div
            style={{
                fontFamily: theme.typography.fontFamily,
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                fontSize: "14px",
                color: props.primaryColor
                    ? theme.palette.primary.main
                    : theme.palette.text
            }}
        >
            {props.children}
        </div>
    );
};

export const BoxContent = (props) => {
    const theme = useTheme();
    return (
        <div
            style={{
                minHeight: "40px",
                fontSize: "12px",
                fontFamily: theme.typography.fontFamily2,
                fontWeight: 300
            }}
        >
            {props.children}
        </div>
    );
};

export const BoxLink = (props) => {
    const theme = useTheme();
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            {props.icon && (
                <SupportIcon
                    name={props.icon}
                    color={theme.palette.primary.main}
                    size={32}
                />
            )}
            <div style={{ marginLeft: "10px" }}>{props.text}</div>
        </Grid>
    );
};

Box.defaultProps = {
    weight: 2
};
