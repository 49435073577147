import React from "react";
import { useTheme } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import { Grid } from '@material-ui/core';
import { LargeButton } from './components/Buttons';
import Icon from "../components/Selfcare/Common/Icon";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const stepperStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        "& .Mui-disabled .MuiStepIcon-root": {
            width: '32px',
            height: '32px',
            padding: "4px 2px 0px 2px",
            borderRadius: "50%",
            border: "2px solid " + theme.palette.text.primary,
            fill: 'white',
            "& text": {
                fill: theme.palette.text.primary,
                fontSize: '16px',
                fontWeight: '700',
            },
        },
        "& .MuiStepIcon-active": {
            width: '32px',
            height: '32px',
            padding: "4px 2px 0px 2px",
            borderRadius: "50%",
            border: "2px solid " + theme.palette.primary.main,
            fill: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            "& text": {
                fill: 'white',
                fontSize: '16px',
                fontWeight: '700',
            },
        },
        "& .MuiStepIcon-completed": {
            width: '40px',
            height: '40px',
            fill: theme.palette.primary.main,
        },
        "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
            fontSize: '11px',
            fontWeight: 200,
        },
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const InsuranceTestResult = (props) => {
    const theme = useTheme();
    const classes = stepperStyles();
    const state = props.state;
    const setState = props.setState;
    const vm = props.vm;
    const isNorway = state.IsNorway;
    const isCallback = window.location.href.includes("callback");
    const getSteps = () => {
        return ['', '', '', '', ''];
    }
    const steps = getSteps();

    return (
        <>
            {state.TestPassed &&
                <>
                <Grid container direction="column" justifyContent="center" textAlign="center">
                    <div style={{ maxWidth: '760px' }}>
                        <div className={classes.root}>
                            <Stepper activeStep={5} alternativeLabel>
                                {steps.map((label, index) => (
                                    <Step key={index}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </div>
                </Grid>
                    <div style={{ marginTop: '10px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center' }}>
                        <div style={{ fontSize: '20px', fontWeight: 700, textAlign: 'center', marginBottom: '10px' }}>
                            {isNorway ? 'Godkjent' : 'Godkänd'}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Icon name='correct' size={48} color={theme.palette.primary.main} style={{ display: 'inline-block' }} />
                        </div>
                        <div style={{ fontSize: '17px', fontWeight: 700, textAlign: 'center', marginTop: '10px' }}>
                            {isNorway ? 'Din Chilimobil forsking er nå aktiv' : 'Din försäkring är nu aktiv'}
                        </div>
                    </div>
                    <div style={{ fontSize: '16px', marginTop: '23px', textAlign: 'left', padding: '0px 46px', fontWeight: 200 }}>
                        {isNorway ? 'Vi sender deg straks en e-post med avtaledokumentet og bekreftelse.' : 'Vi skickar strax ett mejl till med bekräftelse samt avtal.'}
                    </div>
                    <div style={{ fontSize: '16px', marginTop: '35px', textAlign: 'center', padding: '0px 46px', fontWeight: 200 }}>
                        {isNorway ? 'E-post sendes til:' : 'Mejlet skickas till:'}
                    </div>
                    <div style={{ fontSize: '16px', textAlign: 'center', padding: '0px 46px' }}>
                        {state.Email}
                    </div>
                    <div style={{ marginTop: '40px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center', fontWeight: 200 }}>
                        <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 700 }}>
                            {state.Name}
                        </div>
                        <Grid container justifyContent="space-between" style={{ marginTop: '15px' }}>
                            <Grid item>
                                {isNorway ? 'Merke:' : 'Märke:'}
                            </Grid>
                            <Grid item>
                                {state.Brand}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                Modell:
                            </Grid>
                            <Grid item>
                                {state.MarketName}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                IMEI:
                            </Grid>
                            <Grid item>
                                {state.IMEI}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                Mobilnummer:
                            </Grid>
                            <Grid item>
                                {state.MSISDN}
                            </Grid>
                        </Grid>
                    </div>
                    {isCallback && <div style={{ fontSize: '16px', textAlign: 'left', marginTop: '49px', maxWidth: '350px', padding: '0px 17px', fontWeight: 200 }}>
                        {isNorway ? 'Du kan lukke dette vinduet.' : 'Du kan nu stänga detta fönster'}
                    </div>}
                    <div style={{ fontSize: '10px', textAlign: 'center', marginTop: '49px', fontWeight: 200 }}>
                        <div>{isNorway ? 'Forskring i sammarbied med Berkley Nordic' : 'Försäkring i sammarbete med Berkley Nordic'}</div>
                    </div>
                </>
            }
            {!state.TestPassed &&
                <>
                    <div style={{ fontSize: '20px', marginTop: '10px', textAlign: 'center', padding: '0px 46px', fontWeight: 700 }}>
                        <div style={{ fontSize: '20px', fontWeight: 700, textAlign: 'center', marginBottom: '10px' }}>
                            {isNorway ? 'Ikke godkjent' : 'Inte godkänd'}
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Icon name='failure' size={48} color={theme.palette.primary.main} style={{ display: 'inline-block' }} />
                        </div>
                    </div>
                    <div style={{ marginTop: '40px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center', fontWeight: 200 }}>
                        <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 700 }}>
                            {state.Name}
                        </div>
                        <Grid container justifyContent="space-between" style={{ marginTop: '15px' }}>
                            <Grid item>
                                {isNorway ? 'Merke:' : 'Märke:'}
                            </Grid>
                            <Grid item>
                                {state.Brand}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                Modell:
                            </Grid>
                            <Grid item>
                                {state.MarketName}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                IMEI:
                            </Grid>
                            <Grid item>
                                {state.IMEI}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                Mobilnummer:
                            </Grid>
                            <Grid item>
                                {state.MSISDN}
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '18px', fontWeight: 200 }}>
                        {isNorway ? <div>Beløpet på {state.InsurancePriceShort} vil <span style={{ textDecoration: 'underline' }}>ikke</span> bli belastet din faktura.</div> : <div>Kostnaden på {state.InsurancePriceShort} kommer <span style={{ textDecoration: 'underline' }}>inte</span> att belasta din faktura.</div>}
                    </div>
                    <div style={{ fontSize: '20px', textAlign: 'center', marginTop: '47px', fontWeight: 700 }}>
                        Problem funnet
                    </div>
                    <div style={{ fontSize: '14px', textAlign: 'left', marginTop: '14px', maxWidth: '350px', padding: '0px 17px', fontWeight: 200 }}>
                        {isNorway ? 'Dessverre har testen funnet at det er problemer med skjermen på din mobil. Det betyr at mobilen din ikke er mulig å forsikre akkurat nå.' : 'Testen visar att det är problem med skärmen på din mobil. Det betyder att vi inte har möjlighet att försäkra din mobil.'}
                    </div>
                </>
            }
        </>
    );
}

export default InsuranceTestResult;