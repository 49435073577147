import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Field from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { InputAdornment } from '@material-ui/core';


export const StyledTextField = withStyles({
    root: {
        '& .Mui-error': {
            'white-space': 'nowrap',
        },
        '& .MuiFormHelperText-root': {
            'white-space': 'nowrap',
        },
        '& .MuiOutlinedInput-root': {
            '&:focus': {
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&:hover': {
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&.Mui-focused': {
                background: '#da2f630d 0% 0% no-repeat padding-box',
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&:hover .MuiOutlinedInput-notchedOutline':
            {
                //border: '2px solid #da2f6380',
            },
            borderRadius: '4px',
            border: '2px solid #da2f6380',
            outline: 0,
            boxSizing: 'border-box',
            '& fieldset': {
                border: 0,
            },
            '& input': {
                "&:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 1000px white inset !important",
                    "-webkit-box-shadow": "0 0 0 1000px white inset !important",
                    filter: 'none',
                    backgroundColor: 'transparent !important',
                    '&:focus': {
                        WebkitBoxShadow: "0 0 0 1000px #FDF4F7 inset !important",
                        "-webkit-box-shadow": "0 0 0 1000px #FDF4F7 inset !important",
                        backgroundColor: 'transparent !important',
                    },
                },
                fontWeight: 400,
                '&::placeholder': {
                    fontWeight: 200,
                },
                zIndex: 1,
            },
        },
        '& .MuiFormLabel-root': {
            fontSize: '18px',
            marginTop: '-10px',
            marginLeft: '-10px',
            color: '#575757',
            '&.Mui-focused': {
                color: '#575757',
            },
        },
    },
})(Field);
export const StyledMultilineTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-inputMultiline':
        {
            padding: '10px',
        },
        '& .MuiOutlinedInput-root': {
            '&:focus': {
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&:hover': {
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&.Mui-focused': {
                background: '#da2f630d 0% 0% no-repeat padding-box',
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&:hover .MuiOutlinedInput-notchedOutline':
            {
                //border: '2px solid #da2f6380',
            },
            borderRadius: '4px',
            border: '2px solid #da2f6380',
            outline: 0,
            boxSizing: 'border-box',
            '& fieldset': {
                border: 0,
            },
            '& input': {
                "&:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 1000px white inset !important",
                    "-webkit-box-shadow": "0 0 0 1000px white inset !important",
                    filter: 'none',
                    backgroundColor: 'transparent !important',
                    '&:focus': {
                        WebkitBoxShadow: "0 0 0 1000px #FDF4F7 inset !important",
                        "-webkit-box-shadow": "0 0 0 1000px #FDF4F7 inset !important",
                        backgroundColor: 'transparent !important',
                    },
                },
                fontWeight: 400,
                '&::placeholder': {
                    fontWeight: 200,
                },
                zIndex: 1,
            },
        },
        '& .MuiFormLabel-root': {
            fontSize: '18px',
            marginTop: '-10px',
            marginLeft: '-10px',
            color: '#575757',
            '&.Mui-focused': {
                color: '#575757',
            },
        },
    },
})(Field);

export const StyledTextFieldLowerCase = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&:focus': {
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&:hover': {
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&.Mui-focused': {
                background: '#da2f630d 0% 0% no-repeat padding-box',
                border: '2px solid #da2f6380',
                '& fieldset': {
                    border: 0,
                },
            },
            '&:hover .MuiOutlinedInput-notchedOutline':
            {
                //border: '2px solid #da2f6380',
            },
            borderRadius: '4px',
            border: '2px solid #da2f6380',
            outline: 0,
            boxSizing: 'border-box',
            '& fieldset': {
                border: 0,
            },
            '& input': {
                "&:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 1000px white inset !important",
                    "-webkit-box-shadow": "0 0 0 1000px white inset !important",
                    filter: 'none',
                    backgroundColor: 'transparent !important',
                    '&:focus': {
                        WebkitBoxShadow: "0 0 0 1000px #FDF4F7 inset !important",
                        "-webkit-box-shadow": "0 0 0 1000px #FDF4F7 inset !important",
                        backgroundColor: 'transparent !important',
                    },
                },
                fontWeight: 400,
                '&::placeholder': {
                    fontWeight: 200,
                },
                zIndex: 1,
                textTransform: 'lowercase',
            },
        },
        '& .MuiFormLabel-root': {
            fontSize: '18px',
            marginTop: '-10px',
            marginLeft: '-10px',
            color: '#575757',
            '&.Mui-focused': {
                color: '#575757',
            },
        },
    },
})(Field);

export function TextField({ localState, setLocalState, afterChange, onpaste, disabled, readonly, name, label, labelWidth, placeholder, maxLength, inputComponent, inputPropsStyle, type, autoFocus, autoComplete, fullWidth, width, noPadding, endIcon, denseBottom, cols, backgroundColor }) {
    const clearError = () => {
        if (localState.ErrorField !== '') {
            setLocalState({ ErrorField: '' });
            setLocalState({ ErrorText: '' });
        }
    }
    return (
        <Grid item xs={cols ? cols : 12} sm={fullWidth ? cols ? cols : 12 : cols ? cols : 6} style={{ width: width ? width : '100%', padding: noPadding ? '0px' : '17px', marginBottom: denseBottom ? '-17px' : '0px' }}>
            <StyledTextField error={localState.ErrorField === name}
                disabled={disabled !== undefined ? disabled : localState.Disabled}
                autoFocus={autoFocus}
                id={name}
                label={label}
                value={localState[name]}
                onChange={(e) => { setLocalState({ [name]: e.target.value }); clearError(); if (afterChange) { afterChange(e.target.value); } }}
                helperText={localState.ErrorField === name && localState.ErrorText}
                autoComplete={autoComplete}
                margin="dense"
                type={type}
                placeholder={placeholder}
                InputLabelProps={{
                    shrink: true,
                    style: { width: labelWidth }
                }}
                inputProps={{
                    maxLength: maxLength,
                    readOnly: readonly ? true : false,
                    style: inputPropsStyle,
                }}
                InputProps={{
                    notched: false,
                    inputComponent: inputComponent,
                    endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
                }}
                variant="outlined"
                style={{ width: width ? width : '100%' }}
                onPaste={onpaste}
            />
        </Grid>
    );
}
export function TextFieldLowerCase({ localState, setLocalState, afterChange, disabled, readonly, name, label, labelWidth, placeholder, maxLength, inputComponent, type, autoFocus, autoComplete, fullWidth, width, noPadding, endIcon, denseBottom, cols }) {
    const clearError = () => {
        if (localState.ErrorField !== '') {
            setLocalState({ ErrorField: '' });
            setLocalState({ ErrorText: '' });
        }
    }
    return (
        <Grid item xs={cols ? cols : 12} sm={fullWidth ? cols ? cols : 12 : cols ? cols : 6} style={{ width: width ? width : '100%', padding: noPadding ? '0px' : '17px', marginBottom: denseBottom ? '-17px' : '0px' }}>
            <StyledTextFieldLowerCase error={localState.ErrorField === name}
                disabled={disabled !== undefined ? disabled : localState.Disabled}
                autoFocus={autoFocus}
                id={name}
                label={label}
                value={localState[name]}
                onChange={(e) => { setLocalState({ [name]: e.target.value }); clearError(); if (afterChange) { afterChange(e.target.value); } }}
                helperText={localState.ErrorField === name && localState.ErrorText}
                autoComplete={autoComplete}
                margin="dense"
                type={type}
                placeholder={placeholder}
                InputLabelProps={{
                    shrink: true,
                    style: { width: labelWidth }
                }}
                inputProps={{
                    maxLength: maxLength,
                    readOnly: readonly ? true : false,
                }}
                InputProps={{
                    notched: false,
                    inputComponent: inputComponent,
                    endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
                }}
                variant="outlined"
                style={{ width: width ? width : '100%' }}
            />
        </Grid>
    );
}

