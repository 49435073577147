import React, { useRef, useState, useEffect } from "react";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import useWindowDimensions from "../hooks/useWindowDimensions";
import produce from 'immer';
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import PageContainer from '../controls/PageContainer';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Divider, Switch, FormControlLabel } from "@material-ui/core";
import ServerState from '../controls/ServerState';
import SparkleDialog from '../controls/components/SparkleDialog';
import { Box, BoxTitle, BoxContent } from '../controls/Box';
import { LargeButton, SmallButton } from '../controls/components/Buttons';

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: theme.palette.primary.main,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function Profile() {
    const { t } = useTranslation();
    const dialogRef = useRef();
    const wDim = useWindowDimensions();

    const { vm, state } = useConnect("Profile", {}, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, showBuyDataButton: false, dialogOpen: false, ViaplayBuzy: false, CancelViaplayDialogOpen: false, ActivateViaplayDialogOpen: false });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    useEffect(() => {
        // code to run on component mount
        gstate.currentIndex.set(10);
        const callbackDialog = sessionStorage.CallbackDialogRet;
        if (callbackDialog) {
            sessionStorage.removeItem('CallbackDialogRet');
            dialogRef.current.showDialog(callbackDialog);
        }
    }, [])

    const handleRefresh = () => {
        vm.$dispatch({ Refresh: true });
    }
    return (
        <React.Fragment>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            <SparkleDialog ref={dialogRef} handleRefresh={handleRefresh} />
            <PageContainer back logo noSelect id="profile">
                <Grid container direction="column" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    <div style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, fontSize: '14px', marginTop: '19px' }}>{state.UserFullname}</div>
                    <Box border fullWidth linkAction={() => { dialogRef.current.showDialog('changeUser'); }}>
                        <BoxTitle>{gstate.IsNorway.get() ? 'Kontaktinformasjon' : 'Kontaktinformation'}</BoxTitle>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <BoxContent>
                                    <div>
                                        <span dangerouslySetInnerHTML={{ __html: state.ContactInfo }} />
                                    </div>
                                </BoxContent>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" justifyContent="space-between" alignItems="center">
                                    <SmallButton>{t('button.change')}</SmallButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box background fullWidth linkAction={() => { dialogRef.current.showDialog('changePassword'); }}>
                        <BoxTitle>{gstate.IsNorway.get() ? 'Passord til appen' : 'Lösenord till appen'}</BoxTitle>
                        <BoxContent>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item xs={9}>

                                    <div>
                                        {gstate.IsNorway.get() ? 'Dette er passordet til både Min Side og MinChili-appen' : 'Detta är lösenordet till både MittChili och MittChili-appen'}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" justifyContent="space-between" alignItems="center">
                                        <SmallButton>{t('button.change')}</SmallButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BoxContent>
                    </Box>
                    {state.CanNewsLetterSMS &&
                        <>
                            <Divider style={{ width: '90%' }} />
                            <div style={{ padding: '10px 17px 10px 0px' }}>
                                <FormControlLabel
                                control={<IOSSwitch checked={state.NewsLetterSMS} onChange={() => { vm.$dispatch({ ChangeSMS: !state.NewsLetterSMS }); }} name="SMS" />}
                                    label={<div style={{ fontSize: '12px', paddingRight: '30%' }}>{state.SMSText}</div>}
                                    labelPlacement="start"
                                />
                            </div>
                        {!state.CanNewsLetterEmail && <Divider style={{ width: '90%', marginBottom: '17px' }} />}
                        </>
                    }
                    {state.CanNewsLetterEmail &&
                        <>
                            <Divider style={{ width: '90%' }} />
                            <div style={{ padding: '10px 17px 10px 0px' }}>
                                <FormControlLabel
                                control={<IOSSwitch checked={state.NewsLetterEmail} onChange={() => { vm.$dispatch({ ChangeEmail: !state.NewsLetterEmail }); }} name="Email" />}
                                    label={<div style={{ fontSize: '12px', paddingRight: '30%', width: '100%' }}>{state.EmailText}</div>}
                                    labelPlacement="start"
                                />
                            </div>
                            <Divider style={{ width: '90%', marginBottom: '17px' }} />
                        </>
                    }
                    {!wDim.large &&
                        <LargeButton style={{ marginTop: '27px' }} onClick={() => auth.signOutManually()}>{gstate.IsNorway.get() ? 'Logg ut' : 'Logga ut'}</LargeButton>
                    }
                </Grid>
            </PageContainer>
        </React.Fragment>
    );
}