import React, { Component } from 'react';
import { Redirect } from 'react-router'
import { Grid } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "../components/Selfcare/Common/Icon";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { TextField } from '../controls/TextField';
import setNewPasswordPageStyle from "../assets/jss/material-dashboard-pro-react/views/setNewPasswordPageStyle";

import compose from 'recompose/compose';
import theme from '../theme';

import { LargeButton } from '../controls/components/Buttons';

import { withTranslation } from 'react-i18next';

// dotNetify
import dotnetify from 'dotnetify';

import auth from "../auth";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();

const MySwal = withReactContent(Swal);



class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.vm = dotnetify.react.connect("ChangePassword", this, {
            headers: { Authorization: "Bearer " + auth.getAccessToken(), ServerToken: window.sessionStorage.ServerToken },
            exceptionHandler: _ => auth.signOut()
        });
        // Set up function to dispatch state to the back-end.
        this.dispatchState = state => this.vm.$dispatch(state);

        // we use this to make the card to appear after the page has been rendered
        this.state = {
            show: false,
            redirect: null,
            toUrl: null,
            snackbarOpen: false,
            snackbarMessage: '',
            username: '',
            oldPassword: '',
            password: '',
            passwordRepeat: '',
        };
        this.oldPasswordInput = React.createRef();
        this.passwordInput = React.createRef();
        this.passwordRepeatInput = React.createRef();
        this.emailInput = React.createRef();
    }
    componentDidMount() {
        setTimeout(
            function () {
                this.setState({ show: true });
                var el = document.getElementById("username");
                el.style.display = "none";
                el = document.getElementById("oldPassword");
                el.type = "password";
                el = document.getElementById("password");
                el.type = "password";
                el = document.getElementById("passwordRepeat");
                el.type = "password";
            }.bind(this),
            300
        );

    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.SelfcareDisabled && !this.state.SelfcareDisabled) {
            auth.signOut();
        }
        if (!this.state.UnAuthorized && nextState.UnAuthorized) {
            auth.signOut();
        }
        if (!this.state.AlertPasswordChanged && nextState.AlertPasswordChanged) {
            if (this.hasLocalStorage() && localStorage.chRememberMe) {
                localStorage.chPassword = nextState.EncryptedPassword;
            }
            setTimeout(
                function () {
                    MySwal.fire({
                        title: this.props.t('selfcare:notification.passwordischanged'),
                        icon: 'success',
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        this.props.handleClose();
                    });
                }.bind(this),
                100
            );
        }
        if (nextState.ErrorField && !this.state.ErrorField) {
            setTimeout(
                function () {
                    this.setState({ buzy: false });
                    this.setFocus(nextState.ErrorField);
                }.bind(this),
                10
            );
        }
        if (!this.state.AlertPasswordChangeFailed && nextState.AlertPasswordChangeFailed) {
            setTimeout(
                function () {
                    this.setState({ AlertPasswordChangeFailed: false });
                    this.setState({ snackbarOpen: true, snackbarMessage: this.props.t('selfcare:notification.passwordchangefailed') });
                    this.dispatchState({ AlertPasswordChangeFailed: false });
                }.bind(this),
                100
            );
        }
    }
    componentDidUpdate() {
        if (this.state.PasswordErrorText === undefined) {
            return;
        }
        if (this.state.OldPasswordErrorText !== '') {
            this.oldPasswordInput.current.focus();
        }
        if (this.state.PasswordErrorText !== '') {
            this.passwordInput.current.focus();
        }
        if (this.state.PasswordRepeatErrorText !== '') {
            this.passwordRepeatInput.current.focus();
        }
    }
    componentWillUnmount() {
        this.vm.$destroy();
    }

    hasLocalStorage = function () {
        try {
            window.localStorage.setItem("__test", "data");
            window.localStorage.removeItem("__test");
        } catch (e) {
            return false;
        }
        return true;
    }

    handleChange = name => event => {
        this.dispatchState({ [name]: event.target.checked, ClearErrors: true });
    };

    setFocus = (id) => {
        setTimeout(
            function () {
                var el = document.getElementById(id);
                if (el)
                    el.focus();
            },
            0
        );
    }

    navigateTo = url => {
        this.setState({ show: false });
        setTimeout(
            function () {
                this.setState({
                    toUrl: url,
                    redirect: true
                });
            }.bind(this),
            300
        );
    }

    render() {
        const { t } = this.props;
        let { oldPassword, password, passwordRepeat } = this.state;
        const hideSnackbar = _ => {
            this.setState({ snackbarOpen: false });
        }
        return (
            <div>
                {this.state.redirect ? <Redirect to={this.state.toUrl} /> : null}
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={6}>
                            <Icon name="lock" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                {t('selfcare:item.changepassword')}
                            </div>
                            <div style={{ fontSize: '12px', paddingTop: '20px' }}>{t('selfcare:info.passwordrequirements')}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ padding: '20px' }}>
                    <form autoComplete="off" onSubmit={(e) => { e.preventDefault(); this.setState({ ErrorField: '' }); this.dispatchState({ PerformChange: { oldPassword: oldPassword, password: password, passwordRepeat: passwordRepeat } }); }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <input
                                            id="username"
                                            autoComplete="username"
                                            type="text"
                                            value={this.state.username}
                                            color="secondary"
                                        />
                                        <TextField fullWidth localState={this.state} setLocalState={this.setState.bind(this)} name='oldPassword' label={t('selfcare:label.oldpassword')} type="text" autoFocus autoComplete="off" />
                                        <TextField fullWidth localState={this.state} setLocalState={this.setState.bind(this)} name='password' label={t('selfcare:label.newpassword')} type="text" autoComplete="off" />
                                        <TextField fullWidth localState={this.state} setLocalState={this.setState.bind(this)} name='passwordRepeat' label={t('selfcare:label.confirmpassword')} type="text" autoComplete="off" />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" direction="row">
                                    <div style={{ paddingTop: '20px' }}>
                                        <LargeButton type="submit" style={{ marginRight: '10px' }}>{t('selfcare:button.changepassword')}</LargeButton>
                                        <LargeButton variant="text" color="secondary" id='cancel' onClick={() => { this.props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        );
    }
}
ChangePassword.propTypes = {
};

export default compose(
    withStyles(setNewPasswordPageStyle),
    withTranslation(),
)(withAITracking(reactPlugin, ChangePassword));