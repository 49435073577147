import React from "react";
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactLoading from 'react-loading';
import Icon from "../../components/Selfcare/Common/Icon";

const SmallDataMeter = (props) => {
    const theme = useTheme();
    return (
        <React.Fragment>
            <div style={{ width: '88px', height: '88px' }}>
                <CircularProgressbarWithChildren
                    value={props && props.Pot && props.Pot.values && props.Pot.values.length > 0 ? props.Pot.values[0].percentage : 100}
                    strokeWidth={8}
                    styles={buildStyles({
                        pathColor: props.inverse ? '#A81D3B' : theme.palette.primary.main,
                        trailColor: "#fff",
                        pathTransitionDuration: 0,
                    })}
                >
                    {/* Foreground path */}
                    {props && props.Pot ? <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ color: props.inverse ? theme.palette.common.white : theme.palette.text.primary }}
                    >
                        {props.Pot.label1 && <div style={{ fontSize: '8px', height: '14px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, fontStyle: 'normal' }} ><span dangerouslySetInnerHTML={{ __html: props.Pot.label1 }} /></div>}
                        {!props.Pot.isFreeData ?
                            <>
                                {props.Pot.value1 && <div style={{ fontSize: '12px', height: '15px', fontWeight: 500 }}>{props.Pot.value1}</div>}
                                {props.Pot.label2 && <div style={{ fontSize: '8px', height: '12px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, fontStyle: 'normal' }}>{props.Pot.label2}</div>}
                                {props.Pot.value2 && <div style={{ fontSize: '10px', height: '14px', fontWeight: 500 }}>{props.Pot.value2}</div>}
                            </>
                            :
                            <div style={{ marginTop: '-8px' }}>
                                <Icon name="infinity" size={30} color={props.inverse ? theme.palette.common.white : theme.palette.text.primary} />
                            </div>
                        }

                    </Grid>
                        :
                        <div style={{ marginTop: '-30px' }}>
                            <ReactLoading type="spinningBubbles" color={props.inverse ? theme.palette.common.white : theme.palette.primary.main} height={32} widht={32} />
                        </div>
                    }
                </CircularProgressbarWithChildren>
            </div>
        </React.Fragment>
    );
}

export default SmallDataMeter;