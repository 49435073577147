import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Version from "../version";
import Grid from '@material-ui/core/Grid';
import Button from "../components/CustomButtons/Button";
import ButtonX from '@material-ui/core/Button';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";
import pink from '@material-ui/core/colors/pink';
import Clipboard from 'react-clipboard.js';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import pricingPageStyle from "../assets/jss/material-dashboard-pro-react/views/pricingPageStyle";

import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';

import dotnetify from 'dotnetify';

import auth from "../auth";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();

const MySwal = withReactContent(Swal);

const styles = theme => ({
    ...pricingPageStyle,
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    cssLabel: {
        '&$cssFocused': {
            color: pink[500],
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: pink[500],
        },
    },
});

const theme = createTheme({
    palette: {
        primary: pink,
    },
    typography: { useNextVariants: true },
});


class FriendsList extends Component {
    constructor(props) {
        super(props);
        this.vm = dotnetify.react.connect("FriendsList", this, {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
        // Set up function to dispatch state to the back-end.
        this.dispatchState = state => this.vm.$dispatch(state);

        // we use this to make the card to appear after the page has been rendered
        this.state = {
            show: false,
            redirect: null,
            toUrl: null,
            buzy: true,
            DataLoading: true,
            ErrorField: '',
            ErrorText: '',
        };
    }
    componentDidMount() {
        setTimeout(
            function () {
                this.setState({ show: true });
            }.bind(this),
            700
        );
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.StateName && !this.state.StateName) {
            setTimeout(
                function () {
                    this.setState({ [nextState.StateName]: nextState.StateValue });
                }.bind(this),
                5
            );
        }
        if (nextState.Message && !this.state.Message) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: nextState.Message.MessageTitle,
                        html: nextState.Message.MessageText,
                        icon: nextState.Message.MessageType,
                        confirmButtonText: nextState.Message.ConfirmButtonText,
                        confirmButtonColor: '#e91e63',
                    }).then((result) => {
                        if (nextState.Message.OpenUrl) {
                            this.openExternalDialog(nextState.Message.OpenUrl);
                        }
                        if (nextState.Message.RedirectToUrl) {
                            this.setState({ redirect: true, toUrl: nextState.Message.RedirectToUrl });
                        }
                    });
                }.bind(this),
                10
            );
        }
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.vm.$destroy();
    }

    hideAlert = () => {
        this.setState({
            alert: null, buzy: false, OrderError: '', OrderSent: false
        });
    }

    navigateTo = url => {
        this.setState({ show: false });
        setTimeout(
            function () {
                this.setState({
                    toUrl: url,
                    redirect: true
                });
            }.bind(this),
            300
        );
    }
    render() {
        const classes = this.props;
        const { t } = this.props;
        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect ? <Redirect to={this.state.toUrl} /> : null}
                <div style={{ backgroundColor: '#e91e63', color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="friends" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                Mina Kompisar
                                </div>
                            <div style={{ fontSize: '14px' }}>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container justifyContent="center">
                        <MuiThemeProvider theme={theme}>
                            <Grid item xs={12} sm={10} style={{ padding: '20px' }} >
                                <div style={{ marginBottom: '4px' }}>
                                    <b>Lista över Chilikompisar</b>
                                </div>
                                <div style={{ fontStyle: 'italic', marginLeft: '10px', marginBottom: '20px' }}>
                                    {this.state.HasEnlistedByMe ?
                                        <div>
                                            {this.state.EnlistedByMe.map((name, index) => {
                                                return (
                                                    <div key={index}>{name}</div>
                                                )

                                            })}
                                        </div>
                                        :
                                        <div>
                                            Du har inte värvat några kompisar ännu.
                                        </div>
                                    }
                                </div>
                                <div style={{ fontStyle: 'italic' }}>
                                    {this.state.EnlistedBy &&
                                        <div>
                                            Jag är värvad av {this.state.EnlistedBy}
                                        </div>
                                    }
                                </div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '50px', marginBottom: '-10px' }}>
                                    Din rabatt per månad just nu:
                                    </div>
                                <div style={{ fontSize: '100px', fontWeight: 'bold' }}>
                                    {this.state.EnlistedDiscount} kr
                                    </div>
                                <div style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '-20px' }}>
                                    Rabatt/mån
                                    </div>
                                {this.state.DataLoading ? <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                </div>
                                    :
                                    <div style={{ width: '100%', height: '50px' }}>
                                    </div>}
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <Button id='back' color="rose" disabled={this.state.buzy} onClick={() => { this.props.GoBack(); }}><Icon name="back" size={32} color="#FFFFFF" />{t('selfcare:button.back')}</Button>
                                </div>
                            </Grid>
                        </MuiThemeProvider>
                    </Grid>
                </div>
            </div>
        );
    }
}
FriendsList.propTypes = {
};

export default compose(
    withStyles(styles),
    withTranslation(),
)(withAITracking(reactPlugin, FriendsList));
