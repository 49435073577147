import React, { useEffect, useRef } from "react";
import Version from "../version";
import useWindowDimensions from "../hooks/useWindowDimensions";
import dotnetify, { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import ServerState from '../controls/ServerState';
import Icon from "../components/Selfcare/Common/Icon";
import ReactLoading from 'react-loading';
import * as rdd from 'react-device-detect';
import { Grid, FormControlLabel, Checkbox, Link, Button } from '@material-ui/core';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import { Box, BoxTitle } from '../controls/Box';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Iframe from 'react-iframe';
import TopContainer from '../controls/TopContainer';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import Alert from '@material-ui/lab/Alert';
import QRCode from 'qrcode.react'
import bankid from "../assets/img/bankid.png";

const BankIdButton = withStyles({
    root: {
        backgroundColor: '#225971',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
        '&:active': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
        '&:visited': {
            backgroundColor: '#225971',
            color: '#FFF',
        },
        '&:focus': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
    },
})(Button);
export default function ConfirmOwnerChange(props) {
    let interval = useRef(0);
    let interval2 = useRef(0);
    const { t } = useTranslation();
    var biData, cbState;
    const callbackState = sessionStorage.CallbackStateRet;
    if (callbackState) {
        if (callbackState !== '[object Object]' && callbackState !== '') {
            cbState = callbackState;
        }
        else {
            sessionStorage.removeItem('CallbackStateRet');
        }
    }
    const bankIdData = sessionStorage.BankIdData;
    if (bankIdData) {
        biData = JSON.parse(bankIdData);
        sessionStorage.removeItem('BankIdData');
    }

    const getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };
    const { vm, state, setState } = useConnect("ConfirmOwnerChange", { TransId: '', Step: 1, termsAccepted: true }, {
        headers: {
            ClientVersion: Version.getVersion(),
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            TransId: getParameterByName('id'),
            BankIdData: biData,
            CallbackState: cbState,
        },
        exceptionHandler: _ => console.log("Connection failed.")
    });

    const handleChange = (event) => {
        setState({ termsAccepted: event.target.checked })
    }

    const locale = process.env.REACT_APP_DEFAULTLOCALE;
    var isNorway = false;
    if (locale.toLowerCase() === 'nb-no') {
        isNorway = true;
    }
    const preventDefault = (event) => event.preventDefault();
    var isSweden = !isNorway;
    const ReactGA = window.ReactGA;
    const theme = useTheme();
    const wDim = useWindowDimensions();

    const TimerElapsed = () => {
        if (vm)
            vm.$dispatch({ TimerElapsed: true });
    }
    const UpdateImage = () => {
        if (vm) {
            vm.$dispatch({ UpdateImage: true });
        }
    }
    const initBankIDLocal = () => {
        console.log(window.location);
        var returnUrl;
        if (rdd.isIOS) {
            if (rdd.isSafari) {
                returnUrl = window.location.href; // window.location.protocol + "//" + window.location.host + "/";
            } else if (rdd.isChrome) {
                returnUrl = "googlechromes://";
            } else if (rdd.isFirefox) {
                returnUrl = "firefox:://";
            } else if (rdd.isEdge || rdd.isEdgeChromium) {
                returnUrl = "microsoft-edge-https://";
            } else {
                returnUrl = "null";
            }
        } else {
            returnUrl = "null";
        }
        vm.$dispatch({ InitBankIDSE: { returnUrl: returnUrl, type: 'local' } });
        setState({ BankIDBuzy: true, dialogDisabled: true, BankIDStep: 'local' });
    }
    const initBankIDOtherDevice = () => {
        console.log(window.location);
        var returnUrl;
        if (rdd.isIOS) {
            returnUrl = window.location.href; // window.location.protocol + "//" + window.location.host + "/";
        } else {
            returnUrl = "null";
        }
        vm.$dispatch({ InitBankIDSE: { returnUrl: returnUrl, type: 'qr' } });
        setState({ BankIDBuzy: true, dialogDisabled: true, BankIDStep: 'otherDevice' });
    }
    useEffect(() => {
        let int1 = interval.current;
        let int2 = interval2.current;
        const loc = process.env.REACT_APP_DEFAULTLOCALE;
        var isSwe = false;
        if (loc.toLowerCase() === 'sv-se') {
            isSwe = true;
        }
        return () => {
            if (isSwe) {
                clearInterval(int1);
                clearInterval(int2);
            }
        }
    }, []);

    useEffect(() => {
        if (state.BankIdUrl !== undefined && state.BankIdUrl !== null && state.BankIdUrl !== '') {
            window.location.href = state.BankIdUrl;
        }
    }, [state.BankIdUrl])
    useEffect(() => {
        const loc = process.env.REACT_APP_DEFAULTLOCALE;
        var isSwe = false;
        if (loc.toLowerCase() === 'sv-se') {
            isSwe = true;
        }
        if (isSwe) {
            if (state.SetTimer !== undefined && state.SetTimer !== null && state.SetTimer !== '') {
                if (state.SetTimer === 'start') {
                    setState({ SetTimer: '' });
                    interval.current = setInterval(() => {
                        TimerElapsed();
                    }, 2000);
                    interval2.current = setInterval(() => {
                        UpdateImage();
                    }, 1000);
                    setState({ interval: interval, interval2: interval2 });
                }
                if (state.SetTimer === 'stop') {
                    setState({ SetTimer: '' });
                    clearInterval(interval.current);
                    clearInterval(interval2.current);
                }
            }
        }
    }, [state.SetTimer, setState]);

    return (
        <TopContainer logo hideprofile>
            <ServerState serverState={state} setState={setState} />
            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ maxWidth: '760px' }}>
                {state.Step === 1 && state.ChangeInfo &&
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px', width: '100%' }}>
                        <div style={{ fontSize: '20px', fontWeight: 700 }}>
                            {isNorway ? 'Bytte av eier' : 'Ägarbyte'}
                        </div>
                        {state.NeedConfirmation ?
                            <div style={{ fontSize: '12px', margin: '19px 0px' }}>
                                {state.ChangeInfo.FromFullName}{isNorway ? ' har startet overføringen av eierskap. For å godkjenne overtagelse følg stegene under.' : ' har startat ägarbytet. För att godkänna övertagandet följ stegen nedan.'}
                            </div>
                            :
                            <div style={{ fontSize: '16px', marginTop: '30px', marginBottom: '27px', textAlign: 'center' }}>
                                {isNorway ?
                                    <span>Eierskifte godkjent.<br />Overføring til ny eier trer i kraft:<br /></span>
                                    :
                                    <span>Ägarbyte godkänt.<br />Överföring till ny ägare sker:<br /></span>
                                }
                                {state.ChangeDate}
                            </div>
                        }
                        <Grid container direction="row" justifyContent="space-between" style={{ maxWidth: '700px', marginBottom: '20px' }}>
                            <Box background>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ flexWrap: 'nowrap' }}>
                                    <div style={{ marginRight: '17px', alignSelf: 'flex-start' }}>
                                        <CheckCircleIcon style={{ fontSize: '30px', color: '#3EE665' }} />
                                    </div>
                                    <div>
                                        <div style={{ fontSize: '18px', fontWeight: 600 }}>
                                            {state.ChangeInfo.FromFullName}
                                        </div>
                                        <div style={{ fontSize: '16px' }}>
                                            {isNorway ? 'Godkjent' : 'Godkänt'}
                                        </div>
                                    </div>
                                </Grid>
                            </Box>
                            <Box background>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ flexWrap: 'nowrap' }}>
                                    <div style={{ marginRight: '17px', alignSelf: 'flex-start' }}>
                                        {state.ChangeInfo.ToConfirmed ? <CheckCircleIcon style={{ fontSize: '30px', color: '#3EE665' }} /> : <FiberManualRecordIcon style={{ fontSize: '38px', color: '#FDB33C', marginTop: '-4px' }} />}
                                    </div>
                                    <div>
                                        <div style={{ fontSize: '18px', fontWeight: 600 }}>
                                            {state.ChangeInfo.ToFullName}
                                        </div>
                                        <div style={{ fontSize: '16px' }}>
                                            {isNorway ?
                                                state.ChangeInfo.ToConfirmed ? 'Godkjent' : 'Venter på godkjenning'
                                                :
                                                state.ChangeInfo.ToConfirmed ? 'Godkänt' : 'Väntar på godkännande'
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            </Box>
                        </Grid>
                        <Box background>
                            <div style={{ fontSize: '14px', textAlign: 'center' }}>
                                {isNorway ?
                                    state.NeedConfirmation ? 'Godkjenn overtagelse av nummer:' : 'Nummer som blir overført:'
                                    :
                                    state.NeedConfirmation ? 'Godkänn övertagande av nummer:' : 'Nummer som flyttas över:'
                                }
                            </div>
                            {state.ChangeInfo.MSISDNs.map(element => {
                                return (
                                    <Grid key={element} container direction="row" style={{ marginTop: '10px' }}>
                                        <FiberManualRecordIcon style={{ fontSize: '14px', color: '#E3264E', marginRight: '13px' }} />
                                        <div key={element}>{element}</div>
                                    </Grid>
                                )
                            })}
                        </Box>
                        {state.NeedConfirmation ?
                            <>
                                <FormControlLabel
                                    style={{ display: 'table', maxWidth: '500px' }}
                                    id="termsAccepted"
                                    control={
                                        <Checkbox
                                            disableRipple
                                            icon={<CheckBoxIcon color="primary" />}
                                            checkedIcon={<CheckBoxOutlineBlank color="primary" />}
                                            disabled={state.Disable}
                                            checked={state.termsAccepted}
                                            onChange={handleChange}
                                            color="primary"
                                            style={{ display: 'table-cell', verticalAlign: 'top', paddingTop: '0px', background: 'none' }}
                                            name="termsAccepted"
                                        />
                                    }
                                    label=
                                    {
                                        <div style={{ fontSize: '12px' }} className="noselect">
                                            {isNorway ?
                                                <span>Jeg har forstått og akseptert Chilimobil sine <Link color="primary" target="_blank" rel="noopener" href={state.TermsUrl}>vilkår</Link> og  <Link color="primary" target="_blank" rel="noopener" href={state.PrivacyPolicyUrl}>personvernerklæring</Link>. Jeg er kjent med at det vil bli gjort en kredittsjekk av meg og at gjenpartsbrevet blir distribuert <Link color="primary" target="_blank" rel="noopener" href={state.CreditCheckInfoUrl}>elektronisk</Link>. Jeg ønsker oppstart og levering av tjenesten før angrefristens utløp.<br /><br />
                                                    Ved oppsigelse løper abonnementet ut inneværende måned. Du har rett til 14 dagers angrerett på din bestilling. Les mer om <Link color="primary" target="_blank" rel="noopener" href={state.CancelationTermsUrl}>angreretten</Link> din her.</span>
                                                :
                                                <span> Jag godkänner Chilimobils <Link color="primary" target="_blank" rel="noopener" href={state.TermsUrl}>allmänna villkor</Link> och att mina personuppgifter behandlas i enlighet med nu gällande och kommande <Link color="primary" target="_blank" rel="noopener" href={state.PrivacyPolicyUrl}>integritetspolicy</Link>.</span>}
                                        </div>
                                    }
                                />
                                {isNorway ?
                                    <LargeButton disabled={state.termsAccepted || state.buzy} style={{ margin: '46px 0px 36px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ InitBankId: true }); }}>Godkjenn overtagelse</LargeButton>
                                    :
                                    <LargeButton disabled={state.termsAccepted || state.buzy} style={{ margin: '46px 0px 36px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ GoBankIdSE: true }); }}>Godkänn övertagande</LargeButton>}
                                {state.buzy && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                                <div style={{ fontSize: '12px', maxWidth: '300px', textAlign: 'center' }}>
                                    {isNorway ? 'Godkjenning med BankID samt kredittsjekk vil bli gjort på neste side.' : 'Godkänn med BankID på nästa sida'}
                                </div>
                                <div style={{ fontSize: '12px', maxWidth: '300px', textAlign: 'center', marginTop: '16px' }}>
                                    {isNorway ? 'Når begge parter har godkjent overføringen, vil nummeret flyttes over til ny eier ved månedskiftet.' : 'När båda parter har godkänt, så kommer nummer att flyttas till ny ägare vid månadsskiftet.'}
                                </div>
                            </>
                            :
                            <div style={{ fontSize: '12px', maxWidth: '300px', textAlign: 'center', marginTop: '4px' }}>
                                {isNorway ? 'For å kansellere overføringen, ta kontakt med kundeservice.' : 'Om något är oklart, kontakta kundservice på kundervice@chilimobil.se.'}
                            </div>
                        }

                    </div>
                }
                {state.Step === 3 &&
                    <>
                        <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px', width: '100%' }}>
                            <div style={{ fontSize: '20px', fontWeight: 700 }}>
                                {state.ErrorTitle}
                            </div>
                            <div style={{ fontSize: '12px', margin: '19px 0px', textAlign: 'center', maxWidth: '300px' }}>
                                {state.ErrorSubTitle}
                            </div>
                            <Icon name="exclamation" size={72} color="#FDB33C" />
                            <div style={{ fontSize: '12px', margin: '19px 0px', textAlign: 'center', maxWidth: '300px' }}>
                                {state.ErrorMessage}
                            </div>
                        </div>
                    </>
                }
                {state.Step === 4 &&
                    <>
                        <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', }}>
                            {state.BankIDStep === 'start' &&
                                <Grid container direction="column" justifyContent="center" alignItems="center">
                                    <div style={{ fontSize: '16px', marginTop: '30px', marginBottom: '39px', padding: '0px 17px' }}>
                                        Godkänn övertagande av nummer med BankID.
                                    </div>
                                    <img src={bankid} alt="Logga in med BankID" style={{ marginTop: '30px', marginBottom: '80px' }} />
                                    {!state.failureText &&
                                        <div style={{ maxWidth: '280px' }}>
                                            {rdd.isMobile &&
                                                <React.Fragment>
                                                    <BankIdButton onClick={(event) => { initBankIDLocal(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled} style={{ marginBottom: '10px' }}>
                                                        BankID på denna enhet
                                                    </BankIdButton>
                                                    <BankIdButton onClick={(event) => { initBankIDOtherDevice(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                        BankID på annan enhet
                                                    </BankIdButton>
                                                </React.Fragment>
                                            }
                                            {!rdd.isMobile &&
                                                <>
                                                    <BankIdButton onClick={(event) => { initBankIDOtherDevice(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled} style={{ marginBottom: '10px' }}>
                                                        Mobilt BankID
                                                    </BankIdButton>
                                                    <BankIdButton onClick={(event) => { initBankIDLocal(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                        BankID på denna enhet
                                                    </BankIdButton>
                                                </>
                                            }
                                        </div>}
                                    {state.failureText &&
                                        <React.Fragment>
                                            <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                            <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null }); }}>
                                                Försök igen
                                            </Button>
                                        </React.Fragment>}
                                </Grid>}
                            {state.BankIDStep === "otherDevice" &&
                                <div style={{ padding: '0px', textAlign: 'center' }}>
                                    {state.startMessageText && !state.failureText &&
                                        <div style={{ marginBottom: '10px' }}>
                                            {state.startMessageText}
                                        </div>}
                                    {state.failureText &&
                                        <>
                                            <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                            <BankIdButton style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                                Försök igen
                                            </BankIdButton>
                                        </>}
                                    {(state.BankIDBuzy || state.startMessageText) && !state.failureText &&
                                        <div style={{ width: '100%', paddingBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                        </div>}
                                    {!state.startMessageText && state.QRValue &&
                                        <>
                                            <div style={{ marginTop: '50px', marginBottom: '10px', width: '100%' }}>
                                                Starta din BankID-app
                                            </div>
                                            <QRCode value={state.QRValue} size={200} />
                                            <div style={{ fontSize: '12px', marginBottom: '20px', marginTop: '10px' }}>
                                                Starta BankID-appen på din mobil eller surfplatta.<br />
                                                Klicka på QR-ikonen i appen och läs av QR-koden ovan.
                                            </div>
                                        </>}
                                    {!state.failureText && <Button variant="outlined" color="primary" size="large" fullWidth disabled={state.dialogDisabled} onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                        Avbryt
                                    </Button>}

                                </div>}
                            {state.BankIDStep === "local" &&
                                <>
                                    {!rdd.isMobile && state.BankIDUrlSchema &&
                                        <iframe title="BankId" src={state.BankIDUrlSchema} width={0} height={0} frameBorder={0} />}
                                    <div style={{ padding: '0px', textAlign: 'center' }}>
                                        {state.startMessageText && !state.failureText &&
                                            <div style={{ marginBottom: '10px' }}>
                                                {state.startMessageText}
                                            </div>}
                                        {state.failureText &&
                                            <>
                                                <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                                <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                                    Försök igen
                                                </Button>
                                            </>}
                                        {!state.failureText && state.startMessageText &&
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                            </div>}

                                        {!state.failureText && !state.startMessageText &&
                                            <React.Fragment>
                                                <div>
                                                    Startar BankID appen
                                                </div>
                                                <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                                </div>
                                                <BankIdButton href={state.BankIDUrlSchema} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                    Öppna BankID
                                                </BankIdButton>
                                                <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '' }); }}>
                                                    Avbryt BankId
                                                </Button>
                                            </React.Fragment>}
                                    </div>
                                </>}
                            {state.buzy &&
                                <Grid item>
                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                </Grid>
                            }

                        </div>
                    </>
                }

            </Grid>
        </TopContainer>
    );
}

