import React from "react";
import auth from "../auth";
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Icon from "../components/Selfcare/Common/Icon";
import { Grid } from '@material-ui/core';
import { LargeButton } from '../controls/components/Buttons';

export default function FUP(props) {
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("FUP", { buzy: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });

    const theme = useTheme();
    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} handleRefresh={props.handleRefresh} />
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div>
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px 0px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="settings" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px', marginBottom: '20px' }}>
                                Dagliga datamängder enligt FUP-Fair Usage Policy.
                            </div>
                            <div>
                                Chilimobil tillämpar en s.k Fair Usage Policy, vilket betyder att det finns gränser för hur mycket data man kan använda innan denna policy minskar hastigheten. Det finns inga begränsningar för den totala mängden data per dygn, däremot behöver du som kund aktivera tillgång till mer data om det är så att du uppnått vissa nivåer av dataförbrukning.
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <form onSubmit={(e) => { setState({ buzy: true }); e.preventDefault(); vm.$dispatch({ ReopenFUP: true }); }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={10} style={{ width: '100%' }}>
                            <div style={{ paddingTop: '20px', width: '100%' }}>
                                <b>Så här går det till:</b>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <ol>
                                <li>
                                    Per dygn når du första nivån när en förbrukning om 20 GB nås. Hastigheten går då ner till 1 Mbit/s.
                                </li>
                                <li>
                                    Genom att svara på ett sms eller aktivera mer data via MittChili, så fylls din tillgängliga datamängd med ytterligare 5 GB och full hastighet. Efter dessa 5 GB så återupprepas proceduren med att aktivera mer data via sms eller via MittChili för att då få tillgång till ytterligare 5 GB data/surfmängd.
                                </li>
                            </ol>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <div style={{ marginBottom: '14px' }}>
                                <b>Status på ditt abonnemang:</b>
                            </div>
                            <div>
                                {state.FUPActive ?
                                    'Du har idag förbrukat mer än 20 GB data/surf och behöver därför aktivera tillgång till ytterligare 5 GB.'
                                    :
                                    'Du har idag förbrukat mer än 20 GB data/surf och ytterligare 5 GB har aktiverats på ditt abonnemang.'
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10} style={{ paddingTop: '20px' }}>
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <Grid container justifyContent="center" direction="row">
                                    <LargeButton type="submit" disabled={state.buzy || !state.FUPActive} style={{ marginRight: '10px' }} >Aktivera mer data</LargeButton>
                                    <LargeButton id='cancel' variant="text" color="secondary" onClick={() => { props.handleClose() }}>{t('selfcare:button.cancel')}</LargeButton>
                                </Grid>
                                {state.buzy && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}