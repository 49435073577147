import React, { Component } from 'react';
import { Redirect } from 'react-router';
import withStyles from "@material-ui/core/styles/withStyles";
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import Icon from "../components/Selfcare/Common/Icon";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Version from "../version";
import ReactLoading from 'react-loading';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import pricingPageStyle from "../assets/jss/material-dashboard-pro-react/views/pricingPageStyle";

import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';

// dotNetify
import dotnetify from 'dotnetify';
import theme from '../theme';
import auth from "../auth";
import ReactGA from 'react-ga';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { Alert, AlertTitle } from '@material-ui/lab';

var reactPlugin = new ReactPlugin();

const MySwal = withReactContent(Swal);


class ChangeSubscriptionDataLevel extends Component {
    constructor(props) {
        super(props);
        this.vm = dotnetify.react.connect("ChangeSubscriptionDataLevel", this, {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
        // Set up function to dispatch state to the back-end.
        this.dispatchState = state => this.vm.$dispatch(state);

        // we use this to make the card to appear after the page has been rendered
        this.state = {
            show: false,
            redirect: null,
            toUrl: null,
            ChangeDataLevelDialogOpen: false,
            CancelChangeDialogOpen: false,
            OrderSent: false,
            OrderError: '',
            OrderOpen: '',
            DisableButtons: false,
            buzy: false,
            Loading: false,
            EndOfMonth: false,
            SelectedPackageNo: '',
            CurrentPackageNo: '',
        };
    }
    componentDidMount() {
        setTimeout(
            function () {
                this.setState({ show: true });
            }.bind(this),
            700
        );
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.SelfcareDisabled && !this.state.SelfcareDisabled) {
            auth.signOut();
        }
        if (nextState.OrderSent && !this.state.OrderSent) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: this.props.t('selfcare:notification.thankyou'),
                        html: nextState.EndOfMonth ? this.props.t('selfcare:notification.orderregisteredmontheffective') : this.props.t('selfcare:notification.orderregisteredminuteseffective'),
                        icon: 'success',
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        if (this.props.handleRefresh)
                            this.props.handleRefresh();
                        if (this.props.handleClose)
                            this.props.handleClose();
                    });
                }.bind(this),
                100
            );
        }
        if (nextState.OrderError && !this.state.OrderError) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: this.props.t('selfcare:notification.orderfailed'),
                        html: nextState.OrderError,
                        icon: 'error',
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        this.setState({ buzy: false });
                    });
                }.bind(this),
                100
            );

        }
        if (nextState.OrderOpen && !this.state.OrderOpen) {
            setTimeout(
                function () {
                    this.setState({ DisableButtons: true });
                }.bind(this),
                100
            );
        }
        if (!nextState.OrderOpen && this.state.OrderOpen) {
            setTimeout(
                function () {
                    this.setState({ DisableButtons: false });
                }.bind(this),
                100
            );
        }
        if (nextState.Message && !this.state.Message) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: nextState.Message.MessageTitle,
                        html: nextState.Message.MessageText,
                        icon: nextState.Message.MessageType,
                        confirmButtonText: nextState.Message.ConfirmButtonText,
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        switch (nextState.Message.Action) {
                            case 'close':
                                if (this.props.handleRefresh)
                                    this.props.handleRefresh();
                                if (this.props.handleClose)
                                    this.props.handleClose();
                                break;
                            case 'none':
                                break;
                            case 'reload':
                                window.location.reload();
                                break;
                            case 'redirect':
                                window.location.assign(nextState.Message.ActionParam);
                                break;
                            default:
                                break;
                        }
                    });
                }.bind(this),
                10
            );
        }        if (nextState.StateName && !this.state.StateName) {
            setTimeout(
                function () {
                    this.setState({ [nextState.StateName]: nextState.StateValue });
                }.bind(this),
                5
            );
        }
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.vm.$destroy();
    }
    handleConfirmChangeDataLevelDialogClose = () => {
        this.dispatchState({ PerformChange: this.state.SelectedPackageNo });
        this.setState({ ChangeDataLevelDialogOpen: false, buzy: true });
    }
    handleChange = name => event => {
        this.dispatchState({ [name]: event.target.checked, ClearErrors: true });
    }
    handleRBChange = (event) => {
        this.setState({ EndOfMonth: event.target.value === 'true' ? true : false });
    }
    handleLevelChange = (event) => {
        this.dispatchState({ SelectLevel: event.target.value });
        this.setState({ SelectedPackageNo: event.target.value });
    }

    handleChangeDataLevelDialogClose = () => {
        this.setState({ ChangeDataLevelDialogOpen: false, error: null });
    }
    handleCancelChangeDialogClose = () => {
        this.setState({ CancelChangeDialogOpen: false, error: null });
    }
    handlePerformCancel = () => {
        this.dispatchState({ PerformCancel: true });
        this.setState({ CancelChangeDialogOpen: false });
    }
    hideAlert = () => {
        this.setState({
            alert: null, buzy: false, OrderError: '', OrderSent: false
        });
    }

    handleSelectPackage = selectedPackage => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Priceplan selected'
        });
        this.setState({ SelectedPackage: selectedPackage, ChangePriceplanDialogOpen: true });

    };

    navigateTo = url => {
        this.setState({ show: false });
        setTimeout(
            function () {
                this.setState({
                    toUrl: url,
                    redirect: true
                });
            }.bind(this),
            300
        );
    }

    render() {
        const classes = this.props;
        const { t } = this.props;
        const { theme } = this.props;
        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect ? <Redirect to={this.state.toUrl} /> : null}
                <Dialog
                    open={this.state.ChangeDataLevelDialogOpen}
                    onClose={this.handleChangeDataLevelDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Endre datagrense</DialogTitle>
                    <DialogContent>
                        <div>Ønsker du å bytte datagrense fra {this.state.CurrentLevelDescription} til {this.state.SelectedLevelDescription}?</div>
                        {this.state.EndOfMonth ? <div>Siden du allerede har brukt mer enn {this.state.SelectedLevelDescription}, vil endringen skje fra og med neste måned.</div> : <div>Endringen av datagrensen vil skje umiddelbart så fort endringen er bekreftet.</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton variant="contained" color="primary" onClick={this.handleConfirmChangeDataLevelDialogClose}>
                            {t('selfcare:button.confirmuc')}
                        </LargeButton>
                        <LargeButton variant="text" color="secondary" onClick={this.handleChangeDataLevelDialogClose}>
                            {t('selfcare:button.canceluc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.CancelChangeDialogOpen}
                    onClose={this.handleCancelChangeDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{this.state.IsSweden ? 'Ångra beställning' : 'Kansellere bestilling'}</DialogTitle>
                    <DialogContent>
                        {this.state.IsSweden ? 'Önskar du avbryta beställningen?' : 'Vil du kansellere bestillingen?'}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton variant="contained" color="primary" onClick={this.handlePerformCancel} >
                            {t('selfcare:button.confirmuc')}
                        </LargeButton>
                        <LargeButton variant="text" color="secondary" onClick={this.handleCancelChangeDialogClose}>
                            {t('selfcare:button.canceluc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                {this.state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: this.state.AdministratedMsisdn }) }} /></div>}
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="money" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                Datagrense Hyttebredbånd
                                </div>
                            <p>Her kan du sette en grense på hvor mye data du kan bruke. Du betaler fortsatt bare etter hvor mye data du faktisk bruker.</p>
                            <p>De første 25 GB er inkludert i månedsprisen på 349,-. Etter det betaler du 2 kr per GB.</p>
                        </Grid>
                    </Grid>
                </div>
                <form>
                    {!this.state.CanChangePriceplan && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center', borderBottom: '1px grey solid' }}>For å endre datagrense må ditt mobilnummer være administrator eller administratoren for ditt kundeforhold må gi ditt mobilnummer tilgang til dette.</div>}
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={10}>
                            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ padding: '20px 10px' }}>
                                <RadioGroup aria-label="Velg datagrense" value={this.state.SelectedPackageNo} onChange={this.handleLevelChange} style={{ width: '100%', maxWidth: '320px' }}>
                                    {this.state.Levels ? this.state.Levels.map((level) => {
                                        return (
                                            <FormControlLabel key={level.SortNo} style={{ borderRadius: '10px', padding: '18px 18px 18px 8px', marginBottom: '20px', marginRight: '0px', marginLeft: '0px', width: '100%', maxWidth: '320px', border: level.PackageNo === this.state.SelectedPackageNo ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.border.main}`, background: level.PackageNo === this.state.SelectedPackageNo ? `${theme.palette.background.selected} 0% 0% no-repeat padding-box` : '#FFFFFF 0% 0% no-repeat padding-box' }}
                                                value={level.PackageNo}
                                                control={<Radio color="primary" />}
                                                label={<div><div style={{ fontSize: '18px', fontWeight: 700 }}>{level.Description}{level.EndOfMonth && <span style={{ fontSize: '14px', fontWeight: 500, verticalAlign: 'text-top' }}> (Endres ved månedsskifte)</span>}</div><div style={{ fontSize: '14px', fontWeight: 300 }}>{level.MaxPrice}</div></div>}
                                                labelPlacement="end"
                                                disabled={this.state.buzy || !this.state.CanChangePriceplan || this.state.DisableButtons}
                                            />
                                        )
                                    }) : null}
                                </RadioGroup>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <SmallButton color="primary" variant="contained" disabled={this.state.SelectedPackageNo === this.state.CurrentPackageNo || !this.state.CanChangePriceplan || this.state.buzy || this.state.DisableButtons} onClick={() => { this.setState({ ChangeDataLevelDialogOpen: true }); }} color="primary" style={{ marginRight: '10px' }}>
                                        {t('selfcare:button.confirmuc')}
                                    </SmallButton>
                                    <SmallButton variant="text" color="secondary" onClick={() => { this.props.handleRefresh(); this.props.handleClose(); }}>
                                        {t('selfcare:button.canceluc')}
                                    </SmallButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            {this.state.buzy && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                        </Grid>
                        <Grid item>
                            <div style={{ paddingTop: '40px', textAlign: 'center' }}>
                                {this.state.OrderOpen && !this.state.CancelText && <div>{t('selfcare:info.orderprocessed')}</div>}
                                {this.state.CancelText &&
                                    <Alert severity="info" style={{ textAlign: 'left', marginBottom: '10px' }}>
                                        <AlertTitle>{t('selfcare:info.orderprocessed')}</AlertTitle>
                                        <span dangerouslySetInnerHTML={{ __html: this.state.CancelText }} /><br /><a href="#" onClick={(event) => { this.setState({ CancelChangeDialogOpen: true }); event.preventDefault(); }}>{this.state.IsSweden ? 'Ångra byte' : 'Angre endring'}</a>
                                    </Alert>}
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}
ChangeSubscriptionDataLevel.propTypes = {
};

export default compose(
    withStyles(pricingPageStyle, { withTheme: true }),
    withTranslation(),
)(withAITracking(reactPlugin, ChangeSubscriptionDataLevel));