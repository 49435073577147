import React, { useState, useEffect } from "react";
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import useClipboard from "react-use-clipboard";
import Version from "../version";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ServerState from '../controls/ServerState';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Clipboard from 'react-clipboard.js';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid, Divider } from '@material-ui/core';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import Icon from "../components/Selfcare/Common/Icon";
import SamsungChilimobil from '../assets/img/SamsungS24Header.png';
import SamsungChilimobilSE from '../assets/img/SamsungChilimobilSE.png';
import SamsungPhones from '../assets/img/S24Offerx2.png';
import SamsungLogo from '../assets/img/SamsungLogo.png';
import Checkmark from '../assets/img/svg/Checkmark.svg';

const BlackTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontFamily: 'Arial',
    },
    arrow: {
        color: theme.palette.common.black,
    }
}))(Tooltip);

export default function DiscountCoupon(props) {
    const wDim = useWindowDimensions();
    const gstate = useGlobalState(globalState);
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("DiscountCoupon", { buzy: true, shareDialogOpen: false, smsDialogOpen: false, DataLoading: true, to: '', currentPage: 'firstpage', stack: ['firstpage'] }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    const theme = useTheme();
    useEffect(() => {
        props.setTitle(gstate.IsSweden.get() ? 'Samsung erbjudande' : 'Samsung tilbud');
    }, [])

    const [isCodeCopied, setCodeCopied] = useClipboard(state.DiscountCouponString);
    const [CodeTipOpen, setCodeTipOpen] = useState(false);
    const copySuccess = () => {
        console.log(state.DiscountCouponString);
        setCodeCopied();
        setCodeTipOpen(true);
        setTimeout(
            function () {
                setCodeTipOpen(false);
            },
            2000
        );
    };


    return (
        <>
            <ServerState serverState={state} setState={setState} />
            <>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img src={gstate.IsSweden.get() ? SamsungChilimobilSE : SamsungChilimobil} style={{ margin: '0px', width: '100%' }} alt="" />
                    <div style={{ textAlign: 'left', padding: wDim.phone ? '0px 17px' : '0px 34px', marginTop: '41px' }}>
                        <div style={{ fontSize: '18px', fontWeight: 700, marginBottom: '12px' }}>{gstate.IsSweden.get() ? '3 000 kr i rabatt på hela Samsungs nya S22-serie' : '1500 kr rabatt på nye Samsung Galaxy S24'}</div>
                        <div style={{ marginTop: '11px', fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>{gstate.IsSweden.get() ? 'Utöver vår vanliga rabatt på 1 500 kr får du nu 1 500 kr extra med rabattkoden nedan. Som Chilimobil-kund får du alltså hela 3 000 kr rabatt på de nya serierna S22 och S22 Ultra. Gäller alla färger och modeller. Använd koden nedan i kassan hos Samsung, så har du sommarens bästa deal!' : 'Som Chilimobil-kunde får du 1500 kr rabatt på helt nye Samsung Galaxy S24.'}</div>
                        <div style={{ marginTop: gstate.IsNorway.get() ? '9px' : '26px', fontSize: '14px' }}><div style={{ display: 'inline-block' }}><img src={Checkmark} alt="" width="16" /><div style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'top' }}>{gstate.IsSweden.get() ? 'Räntefri delbetalning.' : '1500 kr rabatt'}</div></div></div>
{/*                        <div style={{ marginTop: gstate.IsNorway.get() ? '9px' : '26px', fontSize: '14px' }}><div style={{ display: 'inline-block' }}><img src={Checkmark} alt="" width="16" /><div style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'top' }}>{gstate.IsSweden.get() ? 'Räntefri delbetalning.' : 'Uansett 1500 kr rabatt for innbytte'}</div></div></div>*/}
                        <div style={{ marginTop: gstate.IsNorway.get() ? '9px' : '26px', fontSize: '14px' }}><div style={{ display: 'inline-block' }}><img src={Checkmark} alt="" width="16" /><div style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'top' }}>{gstate.IsSweden.get() ? 'Räntefri delbetalning.' : 'Rentefri nedbetaling.'}</div></div></div>
                        <div style={{ marginTop: '9px', fontSize: '14px' }}><div style={{ display: 'inline-block' }}><img src={Checkmark} alt="" width="16" /><div style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'top' }}>{gstate.IsSweden.get() ? 'Erbjudandet är givetvis helt utan bindningstid.' : 'Tilbudet er selvfølgelig helt uten binding.'}</div></div></div>
                        {gstate.IsSweden.get() && <div style={{ marginTop: '9px', fontSize: '14px' }}><div style={{ display: 'inline-block' }}><img src={Checkmark} alt="" width="16" /><div style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'top' }}>Den unika koden som ger 1 500 kr extra i rabatt är giltig tom 26 juni.</div></div></div>}
                    </div>
                    <div style={{ borderRadius: '10px', backgroundColor: theme.palette.boxBackground.main, width: '100%', maxWidth: '320px', margin: '17px 34px 17px 34px' }}>
                        <div style={{ textAlign: 'center', marginTop: '17px', fontSize: '16px', fontWeight: 700 }}>
                            {gstate.IsSweden.get() ? 'Din unika rabattkod' : 'Rabattkode'}
                        </div>
                        {!state.buzy && <BlackTooltip
                            PopperProps={{
                                disablePortal: true,
                            }}

                            open={CodeTipOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={gstate.IsSweden.get() ? "Rabattkoden är kopierad" : "Din unike rabattkode kopiert"}
                            placement="top"
                            arrow
                        >
                            <Clipboard component="div" data-clipboard-text={state.DiscountCouponString} onSuccess={() => copySuccess()} style={{ cursor: 'pointer', borderRadius: '10px', backgroundColor: '#FFF', margin: '17px 17px 10px 17px', padding: '17px', textAlign: 'center', fontSize: '15px' }}>
                                <Grid container direction="row" alignItems="center" justifyContent="center">
                                    <div>{state.DiscountCouponString}</div><div style={{ marginLeft: '10px', marginRight: '-30px' }}><Icon name="copy-content" color={theme.palette.primary.main} size={24} /></div>
                                </Grid>
                            </Clipboard>
                        </BlackTooltip>}
                        <div style={{ fontSize: '10px', fontFamily: theme.typography.fontFamily2, fontWeight: 100, textAlign: 'center' }}>
                            {gstate.IsSweden.get() ? 'Kom ihåg att kopiera rabattkoden innan du går vidare.' : 'Husk å kopiere rabattkoden din før du går videre.'}
                        </div>
                        <div style={{ textAlign: 'center', width: '100%', padding: '17px' }}>
                            <LargeButton href={state.DiscountCuponUrl} target="_blank" rel="noopener">{gstate.IsSweden.get() ? 'Gå till Samsung.se' : 'Gå til Samsung.no'}</LargeButton>
                        </div>
                    </div>
                    {/*<Grid container direction="row" justifyContent="space-evenly">*/}
                    {/*<div style={{ backgroundColor: theme.palette.boxGreyBackground2.main, borderRadius: '10px', width: '150px', height: '220px', marginTop: '46px' }}>*/}
                    {/*    <div style={{ textAlign: 'right' }}>*/}
                    {/*        <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', color: '#FFF', marginTop: '-5px', fontFamily: theme.typography.fontFamily2, fontSize: '10px', width: '70px', textAlign: 'center', marginLeft: '75px' }}>*/}
                    {/*            {gstate.IsSweden.get() ? 'Spara 3000' : 'Spar 2500'}*/}
                    {/*        </div>*/}
                    {/*        <div style={{ textAlign: 'center' }}>*/}
                    {/*            <img src={Flip4} style={{ margin: '0px', height: '120px' }} alt="" />*/}
                    {/*        </div>*/}
                    {/*        <div style={{ fontSize: '11px', textAlign: 'left', marginLeft: '13px' }}>*/}
                    {/*            Galaxy Z Flip4*/}
                    {/*        </div>*/}
                    {/*        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: '22px' }}>*/}
                    {/*            <div style={{ fontSize: '11px', textAlign: 'left', marginLeft: '13px' }}>*/}
                    {/*                {gstate.IsSweden.get() ? '9 690 kr' : '11 990,-'}*/}
                    {/*                <div style={{ marginTop: '-16px' }}>*/}
                    {/*                    <svg height="12" width="35">*/}
                    {/*                        <line x1="0" y1="12" x2="35" y2="0" style={{ stroke: 'rgb(255,0,0)', strokeWidth: 1 }} />*/}
                    {/*                    </svg>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div style={{ fontSize: '19px', marginRight: '8px' }}>*/}
                    {/*                {gstate.IsSweden.get() ? '6 690 kr' : '9 490,-'}*/}
                    {/*            </div>*/}
                    {/*        </Grid>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div style={{ backgroundColor: theme.palette.boxGreyBackground2.main, borderRadius: '10px', width: '150px', height: '220px', marginTop: '46px' }}>*/}
                    {/*            <div style={{ textAlign: 'right' }}>*/}
                    {/*                <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', color: '#FFF', marginTop: '-5px', fontFamily: theme.typography.fontFamily2, fontSize: '10px', width: '70px', textAlign: 'center', marginLeft: '75px' }}>*/}
                    {/*                    {gstate.IsSweden.get() ? 'Spara 3000' : 'Spar 5700'}*/}
                    {/*                </div>*/}
                    {/*                <div style={{ textAlign: 'center' }}>*/}
                    {/*                    <img src={S22PlusBlue} style={{ margin: '0px', height: '120px' }} alt="" />*/}
                    {/*                </div>*/}
                    {/*                <div style={{ fontSize: '11px', textAlign: 'left', marginLeft: '13px' }}>*/}
                    {/*                    Galaxy S22+*/}
                    {/*                </div>*/}
                    {/*                <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: '22px' }}>*/}
                    {/*                    <div style={{ fontSize: '11px', textAlign: 'left', marginLeft: '13px' }}>*/}
                    {/*                        {gstate.IsSweden.get() ? '13 990 kr' : '11 990,-'}*/}
                    {/*                        <div style={{ marginTop: '-16px' }}>*/}
                    {/*                            <svg height="12" width="40">*/}
                    {/*                                <line x1="0" y1="12" x2="40" y2="0" style={{ stroke: 'rgb(255,0,0)', strokeWidth: 1 }} />*/}
                    {/*                            </svg>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div style={{ fontSize: '19px', marginRight: '8px' }}>*/}
                    {/*                        {gstate.IsSweden.get() ? '10 990 kr' : '6 290,-'}*/}
                    {/*                    </div>*/}
                    {/*                </Grid>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div style={{ backgroundColor: theme.palette.boxGreyBackground2.main, borderRadius: '10px', width: '150px', height: '220px', marginTop: '46px' }}>*/}
                    {/*            <div style={{ textAlign: 'right' }}>*/}
                    {/*                <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', color: '#FFF', marginTop: '-5px', fontFamily: theme.typography.fontFamily2, fontSize: '10px', width: '70px', textAlign: 'center', marginLeft: '75px' }}>*/}
                    {/*                    {gstate.IsSweden.get() ? 'Spara 3000' : 'Spar 5500'}*/}
                    {/*                </div>*/}
                    {/*                <div style={{ textAlign: 'center' }}>*/}
                    {/*                    <img src={S22Ultra} style={{ margin: '0px', height: '120px' }} alt="" />*/}
                    {/*                </div>*/}
                    {/*                <div style={{ fontSize: '11px', textAlign: 'left', marginLeft: '13px' }}>*/}
                    {/*                    Galaxy S22 Ultra*/}
                    {/*                </div>*/}
                    {/*                <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: '22px' }}>*/}
                    {/*                    <div style={{ fontSize: '11px', textAlign: 'left', marginLeft: '13px' }}>*/}
                    {/*                        {gstate.IsSweden.get() ? '11 690 kr' : '13 990,-'}*/}
                    {/*                        <div style={{ marginTop: '-16px' }}>*/}
                    {/*                            <svg height="12" width="40">*/}
                    {/*                                <line x1="0" y1="12" x2="40" y2="0" style={{ stroke: 'rgb(255,0,0)', strokeWidth: 1 }} />*/}
                    {/*                            </svg>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div style={{ fontSize: '19px', marginRight: '8px' }}>*/}
                    {/*                        {gstate.IsSweden.get() ? '8 690 kr' : '8 490,-'}*/}
                    {/*                    </div>*/}
                    {/*                </Grid>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Grid>*/}
                    <div style={{ padding: wDim.phone ? '0px 17px' : '0px 100px' }}>
                        <img src={SamsungPhones} alt="" width="100%" />
                    </div>
                    <div style={{ padding: '50px 0px' }}><img src={SamsungLogo} alt="" width="160" /></div>
                </div>
            </>
        </>
    );
}