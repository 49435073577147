import React, { useState, useEffect } from "react";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import useWindowDimensions from "../hooks/useWindowDimensions";
import * as rdd from 'react-device-detect';
import { Paper, Grid } from "@material-ui/core";
import { useTheme, makeStyles } from '@material-ui/core/styles';
import MenuItem from './components/MenuItem';
import NavigationHelper from '../utils/NavigationHelper';

const nh = NavigationHelper;

const useStyles = makeStyles({
    fixTop: {
        display: 'none',
        position: 'fixed',
        left: '9px',
        right: '9px',
        zIndex: 1000,
        height: '72px',
        top: '9px',
    },
    fixBottom: {
        position: 'fixed',
        left: '9px',
        right: '9px',
        zIndex: 1000,
        height: '72px',
        bottom: '18px',
    },
    shadowBox: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 10px #00000027',
        borderRadius: '14px',
        opacity: 1,
        paddingBottom: '6px',
        //padding: '12px 6px 6px 6px',
        fontSize: '12px',
        width: '100%',
    }
});


const MenuBar = (props) => {
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const wDim = useWindowDimensions();
    const classes = useStyles();
    const history = props.history;
    history.listen((location, action) => {
        if (window.location.pathname !== '/' && window.location.pathname !== '/new-password' && window.location.pathname !== '/set-new-password' && window.location.pathname !== '/inscallback' && window.location.pathname !== '/authcallback' && !window.location.pathname.includes('/confirmownerchange')) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
        }
    });
    const [showMenu, setShowMenu] = useState(false);
    useEffect(() => {
        if (window.location.pathname !== '/' && window.location.pathname !== '/new-password' && window.location.pathname !== '/set-new-password' && window.location.pathname !== '/inscallback' && window.location.pathname !== '/authcallback' && !window.location.pathname.includes('/confirmownerchange')) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
        }
    }, []);

    return (
        <React.Fragment>
            <div style={{ display: gstate.HideMenu.get() ? 'none' : showMenu ? wDim.large ? 'none' : 'block' : 'none' }} className={wDim.phone || wDim.tablet ? classes.fixBottom : classes.fixTop}>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <Paper className={classes.shadowBox} style={{ fontFamily: theme.typography.fontFamily2, maxWidth: wDim.desktop ? '743px' : 'unset' }}>
                        <div style={{ maxWidth: wDim.phone || wDim.tablet ? '600px' : 'unset', overflowX: 'auto', scrollbarWidth: rdd.isFirefox ? 'thin' : null, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Grid role="tabpanel" id="meny" container direction="row" justifyContent="space-around" alignItems="center" style={{ minWidth: '300px' }}>
                                <MenuItem index={0} />
                                <MenuItem index={1} />
                                <MenuItem index={2} />
                                <MenuItem index={3} />
                                <MenuItem index={4} />
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default MenuBar;