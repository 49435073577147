// ##############################
// // // SetNewPasswordPage view styles
// #############################
import theme from '../../../../theme';
//import pink from "@material-ui/core/colors/pink";
//import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

var pink = theme.palette.primary.main;
const setNewPasswordPageStyle = {
    '@global': {
        'body': { backgroundColor: '#FFF' },
    },
    textCenter: {
        textAlign: "center"
    },
    content: {
        paddingTop: "18vh",
        minHeight: "calc(100vh - 80px)",
        position: "relative",
        zIndex: "4"
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    customButtonClass: {
        "&,&:focus,&:hover": {
            color: "#FFFFFF"
        },
        marginLeft: "5px",
        marginRight: "5px"
    },
    inputAdornment: {
        marginRight: "18px"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
        marginBottom: "20px"
    },
    socialLine: {
        padding: "0.9375rem 0"
    },
    wrapper: {
        position: 'relative',
        width: '100%',
    },
    buttonProgress: {
        color: pink[700],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    switch: {
        height: '0px'
    },
    margin: {
        margin: '5px',
    },
    cssLabel: {
        '&$cssFocused': {
            color: pink[500],
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: pink[500],
        },
    },
    //...buttonStyle,
};

export default setNewPasswordPageStyle;
