import React from "react";
import PropTypes from 'prop-types';
import svg4everybody from "svg4everybody";
import Icons from "../../../assets/img/Icons.svg"; 

svg4everybody();
const Icon = ({ name, color, size }) => (
    <svg className={`icon icon-${name}`} fill={color} width={size} height={size}>
        <use xlinkHref={`${Icons}#icon-${name}`} />
    </svg>
);

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number
};

export default Icon;