import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Version from "../version";
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LargeButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';

// dotNetify
import dotnetify from 'dotnetify';

import auth from "../auth";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import theme from '../theme';
var reactPlugin = new ReactPlugin();

const MySwal = withReactContent(Swal);

class PrepaidTopup extends Component {
    constructor(props) {
        super(props);
        this.vm = dotnetify.react.connect("PrepaidTopup", this, {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg:
            {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
        // Set up function to dispatch state to the back-end.
        this.dispatchState = state => this.vm.$dispatch(state);

        // we use this to make the card to appear after the page has been rendered
        this.state = {
            show: false,
            redirect: null,
            toUrl: null,
            Packages: null,
            ChangePriceplanDialogOpen: false,
            OrderSent: false,
            OrderError: '',
            OrderOpen: '',
            DisableButtons: false,
            buzy: false,
            canbuy: false,
            Loading: true,
            password: '',
            EnterPasswordDialogOpen: false,
        };
    }
    componentDidMount() {
        setTimeout(
            function () {
                this.setState({ show: true });
            }.bind(this),
            700
        );
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.StateName && !this.state.StateName) {
            setTimeout(
                function () {
                    this.setState({ [nextState.StateName]: nextState.StateValue });
                }.bind(this),
                5
            );
        }
        if (nextState.Message && !this.state.Message) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: nextState.Message.MessageTitle,
                        html: nextState.Message.MessageText,
                        icon: nextState.Message.MessageType,
                        confirmButtonText: nextState.Message.ConfirmButtonText,
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        switch (nextState.Message.Action) {
                            case 'close':
                                if (this.props.handleRefresh)
                                    this.props.handleRefresh();
                                if (this.props.handleClose)
                                    this.props.handleClose();
                                break;
                            case 'none':
                                break;
                            case 'reload':
                                window.location.reload();
                                break;
                            case 'redirect':
                                window.location.assign(nextState.Message.ActionParam);
                                break;
                            default:
                                break;
                        }
                    });
                }.bind(this),
                5
            );
        }
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.vm.$destroy();
    }
    handleConfirmChangePackageDialogClose = () => {
        this.dispatchState({
            PerformChange: { SelectedPackageNo: this.state.SelectedPackage.PackageNo }
        });
        this.setState({ ChangePriceplanDialogOpen: false, buzy: true });
    }
    handleChange = name => event => {
        this.dispatchState({ [name]: event.target.checked, ClearErrors: true });
    }

    handleChangePriceplanDialogClose = () => {
        this.setState({ ChangePriceplanDialogOpen: false, SelectedPackage: null, error: null });
    }

    handleEnterPasswordDialogClose = () => {
        this.dispatchState({ EnterPasswordDialogOpen: false });
    }

    handleInputChange = name => event => {
        this.setState({ [name]: event.target.value, PasswordErrorText: '' });
    };

    hideAlert = () => {
        this.setState({
            alert: null, buzy: false, OrderError: '', OrderSent: false
        });
    }

    navigateTo = url => {
        this.setState({ show: false });
        setTimeout(
            function () {
                this.setState({
                    toUrl: url,
                    redirect: true
                });
            }.bind(this),
            300
        );
    }

    handleChange = event => {
        this.setState({ value: event.target.value, canbuy: true });
    };
    render() {
        const classes = this.props;
        const { t } = this.props;
        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect ? <Redirect to={this.state.toUrl} /> : null}
                <Dialog
                    open={this.state.EnterPasswordDialogOpen}
                    onClose={() => { this.setState({ EnterPasswordDialogOpen: false, buzy: false }); }}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <Icon name="edit" size={36} color={theme.palette.primary.main} />
                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{t('selfcare:title.code-set-by-legal-owner')}</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.PasswordText}<br />
                        <TextField fullWidth 
                            autoFocus
                            localState={this.state}
                            name="password"
                            setLocalState={this.setState.bind(this)}
                            id="password"
                            label={t('selfcare:label.code')}
                            type="text"
                            margin="normal"
                        />
                        {this.state.PasswordErrorText && <div style={{ width: '100%', padding: '5px', backgroundColor: theme.palette.primary.main, color: 'white', fontSize: '12px' }}>{this.state.PasswordErrorText}</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton onClick={() => { this.dispatchState({ SetPassword: this.state.password }); }}>
                            {t('selfcare:button.proceed-uc')}
                        </LargeButton>
                        <LargeButton color="secondary" variant="text" onClick={() => { this.setState({ EnterPasswordDialogOpen: false, buzy: false }); this.props.handleClose(); }}>
                            {t('selfcare:button.cancel-uc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                {this.state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: this.state.AdministratedMsisdn }) }} /></div>}
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="money" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                {t('selfcare:item.datapacket-chili-prepaid')}
                            </div>
                            <div style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>
                                <span dangerouslySetInnerHTML={{ __html: t('selfcare:info.datapacket-description') }} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); this.setState({ Processing: true }); this.dispatchState({ PerformChange: { SelectedProductNo: this.state.value } }); }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <div style={{ paddingTop: '20px' }}>
                                {t('selfcare:item.choose-datapacket')}
                            </div>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup
                                    aria-label={t('selfcare:label.datapackets')}
                                    name="data"
                                    className={classes.group}
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                >
                                    {this.state.TopUps ? this.state.TopUps.map((option) => {
                                        return (
                                            <FormControlLabel key={option.ProductNo} value={option.ProductNo} control={<Radio color="primary" />} label={option.Description} style={{ fontWeight: 700 }} />
                                        );
                                    }) : null}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {t('selfcare:item.datapacket-duration')}
                        </Grid>
                        <Grid item style={{ paddingTop: '20px' }}>
                            <Grid container direction="column" justifyContent="center">
                                <Grid container justifyContent="center" direction="row">
                                    <LargeButton type="submit" disabled={!this.state.canbuy || this.state.OrderOpen || this.state.Processing} style={{ marginRight: '10px' }}>{t('selfcare:button.confirm-purchase')}</LargeButton>
                                    <LargeButton color="secondary" variant="text" id='cancel' onClick={() => { this.props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                </Grid>
                                {this.state.Processing && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}
PrepaidTopup.propTypes = {
};

export default compose(
    withTranslation(),
)(withAITracking(reactPlugin, PrepaidTopup));