import React, { useState, useEffect } from "react";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTheme } from '@material-ui/core/styles';
import useWindowDimensions from "../hooks/useWindowDimensions";
import auth from "../auth";
import * as rdd from 'react-device-detect';
import { Grid, Link } from "@material-ui/core";
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import MenuDrawerItem from './components/MenuDrawerItem';
import LeftChevronIcon from '../assets/img/svg/left-chevron.svg';

const MenuDrawer = (props) => {
    const wDim = useWindowDimensions();
    const history = props.history;
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    history.listen((location, action) => {
        if (window.location.pathname !== '/' && window.location.pathname !== '/new-password' && window.location.pathname !== '/set-new-password' && window.location.pathname !== '/inscallback' && window.location.pathname !== '/authcallback' && !window.location.pathname.includes('/confirmownerchange')) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
        }
    });
    const [showMenu, setShowMenu] = useState(false);
    useEffect(() => {
        if (window.location.pathname !== '/' && window.location.pathname !== '/new-password' && window.location.pathname !== '/set-new-password' && window.location.pathname !== '/inscallback' && window.location.pathname !== '/authcallback' && !window.location.pathname.includes('/confirmownerchange')) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
        }
    }, []);
    return (
        <div style={{ width: '300px', display: gstate.HideMenu.get() ? 'none' : showMenu ? wDim.large ? 'block' : 'none' : 'none', backgroundColor: '#F7F7F7' }}>
            <div style={{ maxWidth: !wDim.desktop ? '600px' : 'unset', overflowY: 'auto', scrollbarWidth: rdd.isFirefox ? 'thin' : null, marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px' }}>
                <Grid role="tabpanel" id="menudrawer" container direction="column" justifyContent="space-around" alignItems="center">
                    <MenuDrawerItem index={10} profile />
                    <MenuDrawerItem index={0} />
                    <MenuDrawerItem index={1} />
                    <MenuDrawerItem index={2} />
                    <MenuDrawerItem index={3} />
                    <MenuDrawerItem index={4} />
                </Grid>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <LargeButton style={{ marginTop: '50px' }} onClick={() => auth.signOutManually()}>{gstate.IsSweden.get() ? 'Logga ut' : 'Logg ut'}</LargeButton>
                </div>
                {gstate.IsNorway.get() &&
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                        <Link role="button" tabIndex="0" aria-label='Til chilimobil.no' id="chilimobil" href="https://www.chilimobil.no/?utm_source=minside&utm_medium=linkframinside&utm_term=linktilbake&utm_content=&utm_campaign=linktilbake" target="_blank" rel="noopener" style={{ marginLeft: '-20px', outline: 'none', textDecoration: 'none', fontSize: '14px', fontFamily: theme.typography.fontFamily2, fontWeight: 300 }}><img src={LeftChevronIcon} height="32" alt="" style={{ marginTop: '-5px', marginRight: '-6px' }} /><div style={{ verticalAlign: 'top', marginTop: '2px', color: theme.palette.primary.main, display: 'inline-block' }}>Gå til chilimobil.no</div></Link>
                    </div>
                }
            </div>
        </div>
    );
};

export default MenuDrawer;