import React from "react";
import DataMeter from './components/DataMeter';
import Icon from '../components/Selfcare/Common/Icon';
import theme from './../theme';

const PotControl = (props) => {
    var left, right = false;
    if (props.multiple) {
        if (props.Pot.index % 2 === 0) {
            right = true;
        } else {
            left = true;
        }
    }
    return (
        <div style={{ padding: props.multiple ? left ? '0px 10px 0px 0px' : '0px 0px 0px 10px' : '0px 25px 0px 25px', marginRight: props.multiple ? right ? '-20px' : 'unset' : 'unset', marginLeft: props.multiple ? left ? '-20px' : 'unset' : 'unset' }}>
            {props.Pot &&
                <React.Fragment>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '12px', lineHeight: '12px', fontWeight: '300', marginBottom: '8px', marginTop: '5px' }}><span dangerouslySetInnerHTML={{ __html: props.Pot.title }} /></div>
                    <DataMeter Pot={props.Pot} />
                    {props.Pot.hasDetails && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '-40px', marginBottom: '20px' }}><div style={{ height: '24px' }} className={props.Pot.showDetails ? 'downarrow active' : 'downarrow'}><Icon name="down-arrow" size={24} color={theme.palette.primary.main} /></div></div>}
                </React.Fragment>
            }
        </div>
    );
}

export default PotControl;