import React, { useState } from "react";
import globalState from '../../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import MenuIcon from './MenuIcon';
import NavigationHelper from '../../utils/NavigationHelper';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import ProfileIcon from '../../assets/img/svg/profile.svg';

const nh = NavigationHelper;

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 13,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        fontFamily: theme.typography.fontFamilyRoboto,
        padding: '2px',
    },
}))(Badge);

const MenuDrawerItem = (props) => {
    const { t } = useTranslation();
    const gstate = useGlobalState(globalState);
    const wDim = useWindowDimensions();
    const iconSize = 32;
    const theme = useTheme();
    const [temp, setTemp] = useState(0);
    const getPageName = function (index, packageName) {
        return t(nh.getPageName(index, packageName));
    }
    const currentIndex = gstate.currentIndex.get();
    const nav = index => {
        if (currentIndex !== index) {
            var elem = document.getElementById('menu-item-' + index);
            elem.click();
        }
    }
    
    const invCount = gstate.InvoiceCount.get();
    return (
        <>
            {props.profile && temp === 0 ?
                <Grid item aria-label="Profil" role="button" aria-selected={currentIndex === props.index} id={"tab-" + props.index} style={{ cursor: 'pointer', borderRadius: '10px', padding: '0px 17px 0px 8px', marginBottom: '40px', marginRight: '20px', marginLeft: '20px', width: '100%', maxWidth: '260px', border: currentIndex === props.index ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent', background: currentIndex === props.index ? `${theme.palette.boxBackground.main} 0% 0% no-repeat padding-box` : 'transparent' }} onClick={(e) => { nav(props.index); }} className="noselect">
                    <IconButton>
                        <img src={ProfileIcon} height="32" alt="" />
                    </IconButton>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '13px' }}>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '175px' }}>{gstate.Fullname.get()}</div>
                        <div style={{ fontWeight: 200 }}>{gstate.Msisdn.get()}</div>
                    </div>
                </Grid>
                :
                <Grid item aria-label={getPageName(props.index, gstate.PackageType.get())} role="tab" aria-selected={currentIndex === props.index} aria-controls="tabpanel" id={"tab-" + props.index} style={{ cursor: 'pointer', borderRadius: '10px', padding: '0px 17px 0px 8px', marginBottom: '20px', marginRight: '20px', marginLeft: '20px', width: '100%', maxWidth: '260px', border: currentIndex === props.index ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent', background: currentIndex === props.index ? `${theme.palette.boxBackground.main} 0% 0% no-repeat padding-box` : 'transparent 0% 0% no-repeat padding-box', color: currentIndex === props.index ? theme.palette.primary.main : theme.palette.text.main }} onClick={(e) => { nav(props.index); }} className="noselect">
                    <StyledBadge badgeContent={props.index === 1 ? invCount === 'undefined' ? 0 : invCount : 0} color="primary" overlap="rectangular">
                        <IconButton>
                            <MenuIcon name={currentIndex === props.index ? 'menu-' + props.index + '-fill' : 'menu-' + props.index} size={iconSize} />
                        </IconButton>
                    </StyledBadge>
                    <span style={{ marginTop: '-10px' }}>{getPageName(props.index, gstate.PackageType.get())}</span>
                </Grid>
            }
        </>
    );
}

export default MenuDrawerItem;