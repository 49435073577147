import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Icon from "../../components/Selfcare/Common/Icon";
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

const CustomDialog = withStyles({
    root: {
        '& .MuiDialog-paperFullScreen': {
            maxWidth: '350px',
            height: '300px',
            overflowY: 'hidden',
            backgroundColor: 'black',
            color: 'white',
            position: 'absolute',
            bottom: 0,
        },
    },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={1000} ref={ref} {...props} />;
});

export default function ShareDialog(props) {
    const theme = useTheme();
    return (
        <CustomDialog fullScreen open={props.dialogOpen} onClose={props.handleClose} TransitionComponent={Transition}>
            <Toolbar variant="dense">
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        Del vervelenke på
                    </div>
                    <IconButton color="inherit" onClick={props.handleClose} aria-label='Lukk' edge="end">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Toolbar>
            <DialogContent>
                <Grid container direction="row" justifyContent="flex-start" spacing={6} alignItems="center">
                    <Grid item>
                        <Button style={{ width: '60px', height: '60px', borderRadius: '20px', backgroundColor: 'blue' }} href={`mailto:?subject=${props.title}&body=${props.emailMessage}%0D%0A${props.url}%0D%0A%0D%0A`} onClick={() => { props.handleClose(); }}>
                            <Icon name="share-email" size={48} color="#fff" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button style={{ width: '60px', height: '60px', borderRadius: '20px', backgroundColor: '#34cb34' }} onClick={() => { props.openSMS(); props.handleClose(); } }>
                            <Icon name="share-sms" size={48} color="#fff" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" fullWidth style={{ color: 'white', backgroundColor: '#FFFFFF2E' }} onClick={async () => { await navigator.clipboard.writeText(props.url); props.handleClose(); }}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item edge="start">
                            Kopier vervelenke
                        </Grid>
                        <Grid item edge="end" style={{ marginBottom: '-7px' }}>
                            <Icon name="copy" size={24} color="#FFFFFF" />
                        </Grid>
                    </Grid>
                </Button>
            </DialogActions>
        </CustomDialog >
    );
}
