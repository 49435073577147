import React, { useState, useRef, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import Version from "../version";
import * as rdd from 'react-device-detect';
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useTranslation } from 'react-i18next';
import { useConnect } from 'dotnetify';
import globalState from '../state/GlobalState';
import { useTheme } from '@material-ui/core/styles';
import { useState as globalUseState } from '@hookstate/core';
import ServerState from '../controls/ServerState';
import ToggleButton from 'react-toggle-button';
import { ActionBox } from '../controls/ActionBox';
import { Grid } from '@material-ui/core';
import { Link } from 'react-tiger-transition';
import LeftChevronIcon from '../assets/img/svg/left-chevron.svg';
import SparkleDialog from '../controls/components/SparkleDialog';
import PrepaidContainer from '../controls/PrepaidContainer';
import PotContainer from '../controls/PotContainer';
import { Box, BoxTitle, BoxContent } from '../controls/Box';
import { SmallButton } from '../controls/components/Buttons';


export default function Subscription() {
    const { t } = useTranslation();
    const dialogRef = useRef();
    const getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };
    const { state, vm, setState } = useConnect("Subscription", {}, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: { SubscriptionNo: getParameterByName('subscriptionno') },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, showBuyDataButton: false, dialogOpen: false });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const [leftMargin, setLeftMargin] = useState('0px');

    const setVoiceOverFocus = (element) => {
        var focusInterval = 10; // ms, time between function calls
        var focusTotalRepetitions = 10; // number of repetitions

        element.setAttribute('tabindex', '0');
        element.blur();

        var focusRepetitions = 0;
        var interval = window.setInterval(function () {
            element.focus();
            focusRepetitions++;
            if (focusRepetitions >= focusTotalRepetitions) {
                window.clearInterval(interval);
            }
        }, focusInterval);
    }

    useEffect(() => {
        function handleResize() {
            var el = document.getElementById('pageContainer-subscription');
            if (el) {
                var offset = el.offsetLeft;
                var margin = ("-" + offset + "px");
                setLeftMargin(margin);
            } else {
                setLeftMargin('0px');
            }
        }
        setTimeout(
            function () {
                handleResize();
                var el = document.getElementById('back');
                if (el)
                    setVoiceOverFocus(el);
            },
            150
        );

        window.addEventListener('resize', handleResize);
        const callbackDialog = sessionStorage.CallbackDialogRet;
        if (callbackDialog) {
            sessionStorage.removeItem('CallbackDialogRet');
            dialogRef.current.showDialog(callbackDialog);
        }
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const handleOpenPostpaidTopupDialog = () => {
        dialogRef.current.showDialog("postpaidTopup");
    }
    const handleOpenPostpaidEUTopupDialog = () => {
        dialogRef.current.showDialog("postpaidEUTopup");
    }
    const handleOpenDataReloadDialog = () => {
        dialogRef.current.showDialog("postpaidDataReload");
    }
    const handleOpenPostpaidSuperboostDialog = () => {
        dialogRef.current.showDialog("postpaidSuperboost");
    }
    const handleOpenPrepaidTopupDialog = () => {
        dialogRef.current.showDialog("prepaidTopup");
    }
    const handleOpenChargePrepaidDialog = () => {
        dialogRef.current.showDialog("chargePrepaid");
    }
    const handleChangeDataLevel = () => {
        dialogRef.current.showDialog("changeSubscriptionDataLevel");
    }
    const handleDailyData = () => {
        dialogRef.current.showDialog("dailyData");
    }
    const handleSubscriptionPause = (pause) => {
        if (pause) {
            dialogRef.current.showDialog('subscriptionpause');
        } else {
            dialogRef.current.showDialog('subscriptionunpause');
        }
    }
    const handleRefresh = () => {
        vm.$dispatch({ Refresh: true });
    }

    const gstate = globalUseState(globalState);
    const theme = useTheme();
    const wDim = useWindowDimensions();
    return (
        <>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            <SparkleDialog ref={dialogRef} handleRefresh={handleRefresh} subscriptionNo={state.SubscriptionNo} />
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', overflowX: 'hidden', overflowY: rdd.isFirefox ? 'auto' : wDim.desktop ? 'overlay' : 'scroll', height: wDim.height, WebkitOverflowScrolling: 'touch', scrollbarWidth: rdd.isFirefox ? 'thin' : null }}>
                <div id="pageContainer-subscription" className="noselect" style={{ padding: wDim.phone ? '25px 7px' : '35px 17px', maxWidth: wDim.desktop ? '1024px' : 'unset', width: '100%' }}>
                    <div><Link tabIndex="0" role="button" aria-label={(gstate.IsSweden.get() ? 'Tillbaka till ' : 'Tilbake til ') + gstate.topModuleName.get()} id="back" replace to={gstate.topModuleUrl.get() ? gstate.topModuleUrl.get() : '/home'} transition="glide-right" style={{ outline: 'none', marginLeft: leftMargin, textDecoration: 'none', fontSize: '14px', fontFamily: theme.typography.fontFamily2, fontWeight: 300 }}><img src={LeftChevronIcon} height="32" alt="" style={{ marginTop: '-5px', marginRight: '-6px' }} /><div style={{ verticalAlign: 'top', marginTop: '2px', color: theme.palette.primary.main, display: 'inline-block' }}>{gstate.topModuleName.get()}</div></Link></div>
                    <Grid container direction="row" justifyContent="space-around" alignItems="center" style={{ paddingBottom: wDim.phone || wDim.tablet ? '100px' : '0px' }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            {/*<div style={{ fontSize: '16px', marginBottom: '10px' }}>{state.Title}</div>*/}
                            {state.IsAdministrating &&
                                <>
                                    <ToggleButton
                                        colors={{
                                            active: {
                                                base: theme.palette.primary.main,
                                            },
                                        }}
                                        containerStyle={{ display: 'inline-block', width: '150px', height: '40px' }} trackStyle={{ width: '160px', borderRadius: 2, height: '40px' }} thumbStyle={{ borderRadius: 2, width: '38px', height: '38px' }} thumbAnimateRange={[1, 120]} activeLabelStyle={{ width: '100px', fontSize: '14px', left: '20px', fontFamily: theme.typography.fontFamily }} inactiveLabelStyle={{ width: '100px', fontSize: '14px', right: '20px', fontFamily: theme.typography.fontFamily }}
                                        inactiveLabel={gstate.IsSweden.get() ? "Användare" : "Bruker"}
                                        activeLabel={gstate.IsSweden.get() ? "Administratör" : "Administrator"}
                                        value={state.subscription?.IsAdmin}
                                        onToggle={(value) => {
                                            vm.$dispatch({ ChangeAdmin: !value });
                                        }} />
                                    <div style={{ marginTop: '20px', width: '100%' }}></div>
                                    {(state.subscription?.IsPostpaid || state.subscription?.IsData) &&
                                    <PotContainer disabled={state.IsPaused} Pots={state.subscription?.Pots} vm={vm} state={state} localState={localState} setLocalState={setLocalState} handleBuyData={handleOpenPostpaidTopupDialog} handleChangeDataLevel={handleChangeDataLevel} handleBuyEUData={handleOpenPostpaidEUTopupDialog} handleBuySuperboost={handleOpenPostpaidSuperboostDialog} handleBuyDataReload={handleOpenDataReloadDialog} handleDailyData={handleDailyData} handleSubscriptionPause={handleSubscriptionPause} />}
                                    {state.subscription?.IsPrepaid &&
                                        <PrepaidContainer state={state} handleBuyData={handleOpenPrepaidTopupDialog} handleChargePrepaid={handleOpenChargePrepaidDialog} />
                                    }
                                </>
                            }
                        </Grid>
                        {state.subscription &&
                            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: '17px' }}>
                                <Box border linkAction={() => { dialogRef.current.showDialog('changeUser'); }}>
                                    <BoxTitle>{gstate.IsNorway.get() ? 'Kontaktinformasjon' : 'Kontaktinformation'}</BoxTitle>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <BoxContent>
                                                <div>
                                                    <span dangerouslySetInnerHTML={{ __html: state.ContactInfo }} />
                                                </div>
                                            </BoxContent>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column" justifyContent="space-between" alignItems="center">
                                                <SmallButton>{t('button.change')}</SmallButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        }
                        {state.subscription ?
                            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                                <Grid item style={{ width: wDim.desktop ? '47%' : '100%' }}>
                                    <div style={{ color: theme.palette.primary.main, margin: '10px' }}>{gstate.IsNorway.get() ? 'Handlinger' : 'Åtgärder'}</div>
                                    {state.subscription?.CanActivateSimcard &&
                                        <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                            title={t('selfcare:item.activatesim')}
                                            subTitle={t('selfcare:item.receivedsimcard')}
                                            icon="simcard"
                                            buttonText={gstate.IsNorway.get() ? "Aktiver" : "Aktivera"}
                                            action={() => { dialogRef.current.showDialog('activateSimcard'); }}
                                        />
                                    }
                                    {state.subscription?.CanChangePackage &&
                                        <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                            title={gstate.IsSweden.get() ? t('selfcare:item.change-subscription-type-swe') : state.IsPrepaid ? 'Endre til abonnement' : 'Endre abonnement'}
                                            subTitle={state.subscription?.OpenOrder ? t('selfcare:item.orderbeingprocessed', { 0: state.subscription?.PackageDescription }) : state.subscription?.PackageDescription}
                                            icon="money"
                                            buttonText={gstate.IsNorway.get() ? "Endre" : "Byt"}
                                            action={() => { dialogRef.current.showDialog('changePackage'); }}
                                        />
                                    }
                                    {state.subscription?.CanChangeDataLevel &&
                                        <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                            title="Endre datagrense"
                                            subTitle={state.subscription?.OpenOrder ? t('selfcare:item.orderbeingprocessed', { 0: state.subscription?.TotalData }) : state.subscription?.TotalData}
                                            icon="money"
                                            buttonText={gstate.IsNorway.get() ? "Endre" : "Byt"}
                                            action={() => { dialogRef.current.showDialog('changeSubscriptionDataLevel'); }}
                                        />
                                    }
                                    <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                        title={t('selfcare:item.pinslashpuk')}
                                        subTitle={t('selfcare:item.showpinandpuk')}
                                        icon="pin"
                                        buttonText={gstate.IsNorway.get() ? "Vis" : "Visa"}
                                        action={() => { dialogRef.current.showDialog('viewPinPuk'); }}
                                    />
                                    <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                        title={t('selfcare:item.blockunlocksim')}
                                        subTitle={t('selfcare:item.lostphone')}
                                        icon="block-sim"
                                        buttonText={gstate.IsNorway.get() ? "Administrer" : "Hantera"}
                                        action={() => { dialogRef.current.showDialog('blocking'); }}
                                    />
                                    <ActionBox disabled={state.IsCancelled}
                                        title={state.subscription?.SimCardOrderOpen ? t('selfcare:item.ordernewsimprocessed') : t('selfcare:item.ordernewsim')}
                                        subTitle={t('selfcare:item.neednewsim')}
                                        icon="simcard"
                                        buttonText={gstate.IsNorway.get() ? "Bestill" : "Beställ"}
                                        action={() => { if (state.subscription?.SimCount > 1) { dialogRef.current.navigatePage('/subscriptions/subscription/selectsimtype/?subscriptionno=' + state.subscription?.SubscriptionNo, 'glide-left'); } else { sessionStorage.simType = 'main'; dialogRef.current.showDialog('orderSimcard'); } }}
                                    />
                                    {state.subscription?.CanPause &&
                                        <ActionBox disabled={state.IsCancelled}
                                        title={gstate.IsNorway.get() ? "Sett på pause" : "Pausa abonnemang"}
                                            subTitle={gstate.IsNorway.get() ? "Abonnementet kan være fryst i opptil 6 måneder. " : "Pausa ditt abonnemang så länge du vill"}
                                            icon="pause-button"
                                        buttonText={gstate.IsNorway.get() ? "Sett på pause" : "Pausa"}
                                            action={() => { dialogRef.current.showDialog('subscriptionpause'); }}
                                        />
                                    }
                                    {state.subscription?.CanUnPause &&
                                        <ActionBox disabled={state.IsCancelled}
                                        title={gstate.IsNorway.get() ? "Gjenåpne abonnement" : "Återöppna abonnemang"}
                                        subTitle={gstate.IsNorway.get() ? "Gjenåpne Hyttebredbåndet mitt etter Frysperiode" : "Återöppna ditt abonnemang efter paus"}
                                            icon="play-button"
                                        buttonText={gstate.IsNorway.get() ? "Gjenåpne" : "Återöppna"}
                                            action={() => { dialogRef.current.showDialog('subscriptionunpause'); }}
                                        />
                                    }
                                    {state.IsAdmin && !state.IsAdministrating &&
                                        <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                            title={state.subscription?.IsPostpaid ? t('selfcare:item.invoicerecipient') : t('selfcare:item.owner')}
                                            subTitle={t('selfcare:item.newadress')}
                                            icon="profile"
                                            buttonText={gstate.IsNorway.get() ? "Endre" : "Ändra"}
                                            action={() => { dialogRef.current.showDialog('changeAddress'); }}
                                        />
                                    }
                                    {state.IsAdmin && state.CanChangeOwner &&
                                        <ActionBox disabled={state.IsCancelled}
                                        title={gstate.IsNorway.get() ? "Endre eier" : "Ägarbyte"}
                                        subTitle={gstate.IsNorway.get() ? "Overfør nummer til en annen eier" : "Flytta nummer till en annan ägare"}
                                            icon="change-owner"
                                            buttonText={gstate.IsNorway.get() ? "Endre" : "Flytta"}
                                            action={() => { dialogRef.current.showDialog('changeOwner'); }}
                                        />
                                    }
                                    {gstate.IsSweden.get() && !state.IsAdministrating &&
                                        <>
                                            {!state.BillogramStep1 &&
                                                <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                                    title="Betalmetod - Faktura eller Betalkort"
                                                    subTitle="Byt betalmetod"
                                                    icon="invoice"
                                                    buttonText="Byt"
                                                    action={() => { dialogRef.current.showDialog('changePaymentMethod'); }}
                                                />}
                                            {!state.BillogramStep1 && state.subscription?.PaymentMethod === 'credit-card' &&
                                                <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                                    title="Betalkort"
                                                    subTitle="Se och ändra betalkort"
                                                    icon="credit-card"
                                                    buttonText="Ändra"
                                                    action={() => { dialogRef.current.showDialog('changePaymentCard'); }}
                                                />
                                            }
                                            {!state.BillogramStep1 && state.DirectDebitEnabled && state.subscription?.PaymentMethod === 'invoice' && state.IsAdmin &&

                                                <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                                    title="Betala via Autogiro"
                                                    subTitle="Ansök här. Chilimobil sköter resten."
                                                    icon="content-level"
                                                    buttonText="Ansök"
                                                    action={() => { dialogRef.current.showDialog('autogiro'); }}
                                                />

                                            }
                                            <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                                title="Chili kompisar"
                                                subTitle="Värva Kompisar och få rabatt"
                                                icon="friends"
                                                buttonText="Visa"
                                                action={() => { dialogRef.current.showDialog('viewFriends'); }}
                                            />
                                        </>
                                    }
                                    {state.subscription?.CanRecruit &&
                                        <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                            title="Chili Kompis"
                                            subTitle="Verv venner og få rabatt"
                                            icon="venner"
                                            buttonText="Vis"
                                            action={() => { dialogRef.current.showDialog('viewFriends'); }}
                                        />
                                    }
                                    {gstate.IsSweden.get() && state.subscription?.ShowFUP &&
                                        <ActionBox disabled={state.IsCancelled}
                                            title="Daglig datagräns/förbrukning"
                                            subTitle={state.subscription?.FUPActive ? 'Begränsad hastighet' : 'Full hastighet'}
                                            icon="settings"
                                            buttonText="Hantera"
                                            action={() => { dialogRef.current.showDialog('FUP'); }}
                                        />
                                    }
                                </Grid>
                                <Grid item style={{ width: wDim.desktop ? '47%' : '100%' }}>
                                    {((state.subscription?.ActiveServices && state.subscription?.ActiveServices.length > 0) || (state.subscription?.ShowHiddenNumber && state.subscription?.HiddenNumberActive)) &&
                                        <div id="services" style={{ color: theme.palette.primary.main, margin: '10px' }}>{gstate.IsNorway.get() ? 'Aktive tjenester' : 'Aktiva tjänster'}</div>}
                                    {gstate.IsNorway.get() && state.subscription?.ShowHiddenNumber && state.subscription?.HiddenNumberActive &&
                                            <ActionBox disabled={state.IsPaused || state.IsCancelled}
                                                title={state.subscription.HiddenNumberDescription}
                                                subTitle=''
                                                icon={state.subscription.HiddenNumberImage}
                                                buttonText={gstate.IsNorway.get() ? "Administrer" : "Hantera"}
                                        action={() => { dialogRef.current.navigatePage('/subscriptions/subscription/hiddennumber/?subscriptionno=' + state.subscription?.SubscriptionNo, 'glide-left'); }}
                                            />
                                        }
                                    {state.subscription?.ActiveServices && state.subscription?.ActiveServices.length > 0 ? state.subscription?.ActiveServices.map((service) => {
                                        return (
                                            <div key={service.ServiceNo}>
                                                <ActionBox disabled={(state.IsPaused && service.ServiceNo != '176') || state.IsCancelled}
                                                    title={service.Name}
                                                    subTitle={service.Status}
                                                    icon={service.Image}
                                                    buttonText={gstate.IsNorway.get() ? "Administrer" : "Hantera"}
                                                    action={service.ServiceNo === '158' ? () => { dialogRef.current.showDialog('insurance'); } : () => { sessionStorage.ServiceNo = service.ServiceNo; sessionStorage.GroupId = service.GroupId; dialogRef.current.showDialog('changeService'); }}
                                                />
                                            </div>
                                        );
                                    }) : null}

                                    {((state.subscription?.OtherServices && state.subscription?.OtherServices.length > 0) || (state.subscription.ShowHiddenNumber && !state.subscription?.HiddenNumberActive)) &&
                                        <div style={{ color: theme.palette.primary.main, margin: '10px' }}>{gstate.IsNorway.get() ? 'Andre tjenester' : 'Andra tjänster'}</div>}
                                    {gstate.IsNorway.get() && state.subscription?.ShowHiddenNumber && !state.subscription?.HiddenNumberActive &&
                                        <ActionBox greyBackground disabled={state.IsPaused || state.IsCancelled}
                                            title={state.subscription.HiddenNumberDescription}
                                            subTitle=''
                                            icon={state.subscription.HiddenNumberImage}
                                            buttonText={gstate.IsNorway.get() ? "Administrer" : "Hantera"}
                                            action={() => { dialogRef.current.navigatePage('/subscriptions/subscription/hiddennumber/?subscriptionno=' + state.subscription?.SubscriptionNo, 'glide-left'); }}
                                        />
                                    }
                                    {state.subscription?.OtherServices && state.subscription?.OtherServices.length > 0 ? state.subscription?.OtherServices.map((service) => {
                                        return (
                                            <div key={service.ServiceNo}>
                                                <ActionBox greyBackground disabled={state.IsPaused || state.IsCancelled}
                                                    title={service.Name}
                                                    subTitle={service.Status}
                                                    icon={service.Image}
                                                    buttonText={gstate.IsNorway.get() ? "Administrer" : "Hantera"}
                                                    action={service.ServiceNo === '158' ? () => { dialogRef.current.showDialog('insurance'); } : () => { sessionStorage.ServiceNo = service.ServiceNo; sessionStorage.GroupId = service.GroupId; dialogRef.current.showDialog('changeService'); }}
                                                />
                                            </div>
                                        );
                                    }) : null}

                                </Grid>
                            </Grid>
                            :
                            <div id="preloader">
                                <div id="loader"></div>
                            </div>
                        }
                    </Grid>
                </div>
            </div>
        </>
    );
}