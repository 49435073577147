import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Icon from "../components/Selfcare/Common/Icon";
import { Grid } from '@material-ui/core';
import { LargeButton } from '../controls/components/Buttons';

export default function ChangePaymentCard(props) {
    const { t } = useTranslation();
    const { vm, state } = useConnect("ChangePaymentCard", {}, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => {
        if (state.pInfo) {
            gstate.paymentInfo.set(state.pInfo);
        }
        if (state.GoNets)
            window.location.assign(state.GoNets);
    }, [state.GoNets]);
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, termsAccepted: false, msisdn: '', amount: '' });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    const theme = useTheme();

    return (
        <>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10}>
                        <Icon name="credit-card" size={48} color="#FFFFFF" />
                        <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                            {t('selfcare:title.change-payment')}
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: t('selfcare:info.change-payment') }} />
                    </Grid>
                </Grid>
            </div>
            <form onSubmit={(e) => { e.preventDefault(); vm.$dispatch({ ValidateCharge: { amount: localState.amount, termsAccepted: localState.termsAccepted } }); setLocalState({ buzy: true }); }}>
                <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                    {state.paymentInfo ?
                        <Grid item xs={12} sm={10} style={{ fontSize: '18px' }}>
                            <div style={{ textTransform: 'uppercase' }}>
                                {t('selfcare:label.card-number')}
                            </div>
                            <div>
                                {state.paymentInfo.CardNo}
                            </div>
                            <div style={{ textTransform: 'uppercase', marginTop: '10px' }}>
                                {t('selfcare:label.valid-to')}
                            </div>
                            <div>
                                {state.paymentInfo.ValidTo}
                            </div>
                            <Icon name={state.paymentInfo.CardType} size={36} />
                        </Grid>
                        :
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                        </div>
                    }
                    <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                            <div style={{ paddingTop: '20px' }}>
                                <LargeButton color="secondary" variant="text" style={{ marginRight: '14px' }} onClick={() => { props.handleClose(); }}>
                                    {t('selfcare:button.cancel')}
                                </LargeButton>
                                <LargeButton onClick={() => { setLocalState({ buzy: true }); vm.$dispatch({ ChangePaymentDetails: true }); }} disabled={localState.buzy}>
                                    {t('selfcare:button.change-credit-card')}
                                </LargeButton>
                            </div>
                        </Grid>
                    </Grid>
                    {localState.buzy &&
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                        </div>
                    }
                </Grid>
            </form>
        </>
    );
}