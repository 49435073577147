import React from "react";
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { SmallButton } from "./components/Buttons";
import Icon from "../components/Selfcare/Common/Icon";


export const ActionBox = (props) => {
    const theme = useTheme();
    return (
        <>
            <Grid role="button" item style={{ width: '100%', padding: '10px', marginBottom: '19px', borderRadius: '10px', backgroundColor: props.greyBackground ? theme.palette.boxGreyBackground.main : theme.palette.boxBackground.main, color: theme.palette.text.primary, cursor: props.disabled ? 'auto' : 'pointer' }} onClick={props.disabled ? null : () => { props.action(); }}>
                <div style={{ position: 'relative' }}>
                    <div style={{ minHeight: '65px' }}>
                        <div style={{ display: 'inline-block'} }>
                            <Icon name={props.icon} size={36} color={theme.palette.primary.main} />
                        </div>
                        <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                            <div style={{ fontSize: '14px', fontWeight: 400, maxWidth: '300px' }}>{props.title}</div>
                            <div style={{ fontSize: '12px', fontWeight: 200, fontFamily: theme.typography.fontFamily2 }}>{props.subTitle}</div>
                        </div>
                    </div>
                    <SmallButton disabled={props.disabled} style={{ position: 'absolute', top: '45px', right: '0px' }}>{props.buttonText}</SmallButton>
                </div>
            </Grid>
        </>
    );
};


