import React from "react";
import { useTheme } from '@material-ui/core/styles';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Grid } from '@material-ui/core';

const PotLegend = (props) => {
    const theme = useTheme();
    const wDim = useWindowDimensions();
    
    const divider = (wDim.width >= 338 && props?.PotLegend?.index === 2) || (wDim.width >= 488 && props?.PotLegend?.index === 3) ? true : false;
    return (
        <React.Fragment>
            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: '150px', alignSelf: 'flex-start' }}>
                {divider && <div style={{ height: '70px', width: '2px', backgroundColor: theme.palette.primary.main, marginTop: '-10px' }} />}
                <div style={{ width: '148px', marginBottom: '10px' }}>
                    {props && props.PotLegend &&
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid container direction="row">
                                <div style={{ height: '10px', width: '30px', borderRadius: '5px', margin: '0px 10px', backgroundColor: props.PotLegend.color }}></div><div style={{ fontSize: '12px', lineHeight: '12px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, fontStyle: 'normal', marginRight: '-10px' }}>{props.PotLegend.title}</div>
                            </Grid>
                            {props.PotLegend.label1 && <div style={{ fontSize: '10px', lineHeight: '14px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, fontStyle: 'normal' }} ><span dangerouslySetInnerHTML={{ __html: props.PotLegend.label1 }} /></div>}
                            {props.PotLegend.value1 && <div style={{ fontSize: '25px', lineHeight: '25px', fontWeight: 500 }}>{props.PotLegend.value1}</div>}
                            {props.PotLegend.label2 && <div style={{ fontSize: '12px', lineHeight: '20px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, fontStyle: 'normal' }}>{props.PotLegend.label2}</div>}
                            {props.PotLegend.value2 && <div style={{ fontSize: '13px', lineHeight: '17px', fontWeight: 500 }}>{props.PotLegend.value2}</div>}
                            {props.PotLegend.label3 && <div style={{ fontSize: '10px', lineHeight: '14px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, fontStyle: 'normal' }}><span dangerouslySetInnerHTML={{ __html: props.PotLegend.label3 }} /></div>}

                        </Grid>}
            </div>
            </Grid>
        </React.Fragment>
    );
}

export default PotLegend;