import React from "react";
import globalState from '../../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Link } from 'react-tiger-transition';
import NavigationHelper from '../../utils/NavigationHelper';

const nh = NavigationHelper;


const MenuLink = (props) => {
    const { t } = useTranslation();
    const gstate = useGlobalState(globalState);
    const getPageName = function (index, packageName) {
        return t(nh.getPageName(index, packageName));
    }
    const transition = "fade";

    return (
        <>
            {props.profile ?
                <Grid item id="menu-item-10" component={Link} to="/profile" transition="glide-left">
                    <div>Profil</div>
                </Grid>
                :
                <Grid item id={"menu-item-" + props.index} component={Link} to={nh.getPageUrl(props.index)} transition={transition}>
                    <div>{getPageName(props.index, gstate.PackageType.get())}</div>
                </Grid>
            }
        </>
    );
}

export default MenuLink;