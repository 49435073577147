import React, { useEffect, useState } from "react";
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import dotnetify, { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid, Link } from '@material-ui/core';
import { TextField } from '../controls/TextField';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import { Box, BoxTitle } from '../controls/Box';
import StrexImg from '../assets/img/StrexImage.png';
import StrexLogo from '../assets/img/strex_logo.png';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import TextMaskNumeric from '../components/Selfcare/TextMasks/TextMaskNumeric';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from "../components/Selfcare/Common/Icon";

//dotnetify.debug = true;
const ExpandMoreIcon = withStyles({
    root: {
        margin: '-5px'
    }
})(MuiExpandMoreIcon);

const Accordion = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#FFEFEF',
        borderBottom: 'none',
        marginBottom: -1,
        minHeight: 40,
        alignItems: 'flex-start',
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        backgroundColor: '#FFEFEF',
        padding: theme.spacing(2),
        flexDirection: 'column',
    },
}))(MuiAccordionDetails);

const AccordionInfo = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionInfoSummary = withStyles({
    root: {
        border: '1px solid #E3264E',
        borderRadius: '50px',
        marginBottom: -1,
        minHeight: 20,
        '&$expanded': {
            minHeight: 20,
        },
    },
    content: {
        margin: '0px',
        '&$expanded': {
            margin: '0px',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionInfoDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function StrexTransactions(props) {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [sessionId, setSessionId] = useState('');
    
    const { vm, state, setState } = useConnect("StrexTransactions", { Step: 1, disabled: false, busy: false, termsAccepted: true, regConfirmCode: '', expand: '', infoExpand: '', pep: true }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => { //OnMount
        setStep(1);
    }, [])
    useEffect(() => {
        switch (step) {
            case 1:
                props.setTitle(state.IsPrepaid ? 'Kjøp via mobilen' : 'Kjøp via mobilregningen');
                props.setBackFunction(null);
                break;
            case 2:
                props.setTitle(state.IsPrepaid ? 'Kjøp via mobilen' : 'Kjøp via mobilregningen');
                props.setBackFunction(() => { setState({ Step: 1 }); });
                break;

            case 3:
                setState({ regConfirmCode: '' });
                props.setTitle("SMS godkjennelse");
                props.setBackFunction(() => { setState({ Step: 2 }); });
                break;
            case 4:
                setState({ last5: '' });
                props.setTitle("Logg inn");
                props.setBackFunction(() => { setState({ Step: 1 }); });
                break;
            case 5:
                setState({ loginConfirmCode: '' });
                props.setTitle("Bekreft SMS");
                props.setBackFunction(() => { setState({ Step: 4 }); });
                break;
            case 6:
                props.setTitle(state.IsPrepaid ? 'Kjøp via mobilen' : 'Kjøp via mobilregningen');
                props.setBackFunction(null);
                break;
            case 7:
                props.setTitle("Endre kontaktinformasjon");
                props.setBackFunction(null);
                break;
            default:
                props.setTitle("");
                props.setBackFunction(null);
                break;
        }
    }, [step]);
    useEffect(() => {
        if (state.StateName === 'Step') {
            setStep(state.StateValue);
        }
        if (state.StateName === 'SessionId') {
            setSessionId(state.StateValue);
        }
    }, [state.StateName]);
    const theme = useTheme();

    const handleChangeTerms = (event) => {
        setState({ termsAccepted: event.target.checked })
    }
    const handleChangePEP = (event) => {
        setState({ pep: event.target.checked })
    }
    const handleExpand = (panel) => (event, newExpanded) => {
        if (newExpanded) {
            setState({ expand: panel });
        } else {
            setState({ expand: '' });
        }
    };
    const handleInfoExpand = (panel) => (event, newExpanded) => {
        if (newExpanded) {
            setState({ infoExpand: panel });
        } else {
            setState({ infoExpand: '' });
        }
    };
    return (
        <>
            <ServerState serverState={state} setState={setState} />
            {step === 0 &&
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'150px'} width={'150px'} />
                    </Grid>
                </Grid>
            }
            {step === 1 && /*Start page*/
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img src={StrexImg} style={{ margin: '0px', width: '100%' }} alt="" />
                    <div style={{ padding: '0px 20px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>
                        <div style={{ fontSize: '18px', marginTop: '29px', textAlign: 'left', fontWeight: 700 }}>
                            Kjøper du varer og tjenester via {state.IsPrepaid ? 'mobilen' : 'mobilregningen'}?
                        </div>
                        <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '14px', marginTop: '10px', textAlign: 'left' }}>
                            Slike kjøp er ofte bussbilletter, parkering, avis-abonnement, legetimer, spill i app, m.m.
                        </div>
                        <div style={{ marginTop: '23px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', textAlign: 'center' }}>
                            <div style={{ fontSize: '16px', textAlign: 'center', fontWeight: 700 }}>
                                Se alle kjøp
                            </div>
                            <LargeButton disabled={state.buzy} style={{ marginTop: '19px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ StartLogin: true }); }}>Logg inn</LargeButton>
                            {state.buzy &&
                                <Grid container justifyContent="center" alignItems="center" direction="row">
                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'45px'} width={'50px'} />
                                </Grid>
                            }
                            <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', textAlign: 'center', marginTop: '19px' }}>
                                Du må logge inn hver gang du skal se spesifiserte kjøp.
                            </div>
                        </div>
                        <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', textAlign: 'center', marginTop: '80px' }}>
                            <div>Oversikten er levert i samarbeid med Strex AS.</div>
                            <img src={StrexLogo} style={{ margin: '0px', width: '100px' }} alt="" />
                        </div>

                    </div>
                </div>
            }
            {step === 2 && /* Register */
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>

                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '14px', textAlign: 'left', margin: '20px 0px', padding: '0px 17px' }}>
                        Siden du ikke har logget inn for å se spesifiserte kjøp før, på dette nummeret, trenger du å registrere deg først.
                    </div>
                    <Grid item style={{ marginRight: '17px', alignSelf: 'flex-end', marginBottom: '-20px' }}>
                        <SmallButton onClick={() => { props.setBackFunction(null); props.showDialog('changeUser'); }}>Endre bruker</SmallButton>
                    </Grid>
                    <Grid container>
                        <TextField
                            localState={state}
                            name="msisdn"
                            setLocalState={setState}
                            id="msisdn"
                            label="Mobilnummer"
                            readonly
                            denseBottom
                        />
                        <TextField
                            localState={state}
                            name="firstname"
                            setLocalState={setState}
                            id="firstname"
                            label="Fornavn"
                            readonly
                            denseBottom
                        />
                        <TextField
                            localState={state}
                            name="lastname"
                            setLocalState={setState}
                            id="lastname"
                            label="Etternavn"
                            readonly
                            denseBottom
                        />
                        <Grid item xs={12} sm={6}>
                            <Grid container>
                                <TextField
                                    localState={state}
                                    name="ssn"
                                    setLocalState={setState}
                                    id="ssn"
                                    label="Fødselsnummer"
                                    type="tel"
                                    readonly
                                    denseBottom
                                    width="150px"
                                    cols={6}
                                />
                                <TextField
                                    autoFocus
                                    localState={state}
                                    name="last5"
                                    setLocalState={setState}
                                    id="last5"
                                    label="5 siste siffer"
                                    type="tel"
                                    denseBottom
                                    width="120px"
                                    cols={6}
                                    inputComponent={TextMaskNumeric}
                                    maxLength={5}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{ marginLeft: '10px', marginTop: '20px' }}>
                        <FormControlLabel
                            id="pep"
                            control={
                                <Checkbox
                                    disableRipple
                                    icon={<CheckBoxIcon color="primary" />}
                                    checkedIcon={<CheckBoxOutlineBlank color="primary" />}
                                    disabled={state.Disable}
                                    checked={state.pep}
                                    onChange={handleChangePEP}
                                    color="primary"
                                    name="pep"
                                />
                            }
                            label=
                            {
                                <div style={{ fontSize: '12px' }} className="noselect">
                                    Kryss av hvis du er en politisk eksponert person (PEP)<br />Hvorfor spør vi om dette? Les mer <Link color="primary" target="_blank" rel="noopener" href="https://www.chilimobil.no/sporsmal-hjelp/strex-pep/">her</Link>
                                </div>
                            }
                        />
                        <FormControlLabel
                            id="termsAccepted"
                            control={
                                <Checkbox
                                    disableRipple
                                    icon={<CheckBoxIcon color="primary" />}
                                    checkedIcon={<CheckBoxOutlineBlank color="primary" />}
                                    disabled={state.Disable}
                                    checked={state.termsAccepted}
                                    onChange={handleChangeTerms}
                                    color="primary"
                                    name="termsAccepted"
                                />
                            }
                            label=
                            {
                                <div style={{ fontSize: '12px' }} className="noselect">
                                    Ja, jeg har lest og akseptert <Link color="primary" target="_blank" rel="noopener" href={state.TermsUrl}>vilkårene</Link> til Chilimobil og <Link color="primary" target="_blank" rel="noopener" href={state.StrexTermsUrl}>vilkårene</Link> til Strex.
                                </div>
                            }
                        />
                    </Grid>
                    <LargeButton disabled={state.termsAccepted || state.buzy} style={{ margin: '46px 0px 36px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ Register: { msisdn: state.msisdn, firstname: state.firstname, lastname: state.lastname, ssn: state.ssn, last5: state.last5, pep: !state.pep } }); }}>Registrer</LargeButton>
                    {state.buzy &&
                        <Grid container justifyContent="center" alignItems="center" direction="row">
                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'45px'} width={'50px'} />
                        </Grid>
                    }
                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', textAlign: 'center', marginTop: '80px' }}>
                        <div>Oversikten er levert i samarbeid med Strex AS.</div>
                        <img src={StrexLogo} style={{ margin: '0px', width: '100px' }} alt="" />
                    </div>
                </div>
            }
            {step === 3 && /* Confirm registration */
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img src={StrexImg} style={{ margin: '0px', width: '100%' }} alt="" />
                    <div style={{ padding: '0px 20px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>
                        <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '14px', marginTop: '29px', marginBottom: '40px', textAlign: 'left' }}>
                            Du har nå fått enn SMS med en PIN-kode. Oppgi koden under for å bekrefte registreringen.
                        </div>
                        <TextField
                            autoFocus
                            localState={state}
                            name="regConfirmCode"
                            setLocalState={setState}
                            id="regConfirmCode"
                            label="Bekreftelsekode"
                            type="tel"
                            denseBottom
                            inputComponent={TextMaskNumeric}
                            maxLength={4}
                        />
                        <LargeButton disabled={state.buzy} style={{ marginTop: '28px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ ConfirmRegistration: state.regConfirmCode }); }}>Fortsett</LargeButton>
                        {state.buzy &&
                            <Grid container justifyContent="center" alignItems="center" direction="row">
                                <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'45px'} width={'50px'} />
                            </Grid>
                        }
                    </div>
                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', textAlign: 'center', marginTop: '80px' }}>
                        <div>Oversikten er levert i samarbeid med Strex AS.</div>
                        <img src={StrexLogo} style={{ margin: '0px', width: '100px' }} alt="" />
                    </div>

                </div>
            }
            {step === 4 && /* Login */
                <>
                    <img src={StrexImg} style={{ margin: '0px', width: '100%' }} alt="" />
                    <div style={{ padding: '0px 20px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center', padding: '0px 17px' }}>
                        <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '14px', textAlign: 'left' }}>
                            Du logger nå inn for:
                        </div>
                        <div style={{ fontSize: '16px', fontWeight: 700 }}>
                            {state.FormattedMsisdn}
                        </div>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6}>
                                <Grid container>
                                    <TextField
                                        localState={state}
                                        name="ssn"
                                        setLocalState={setState}
                                        id="ssn"
                                        label="Fødselsnummer"
                                        type="tel"
                                        readonly
                                        denseBottom
                                        width="150px"
                                        cols={6}
                                    />
                                    <TextField
                                        autoFocus
                                        localState={state}
                                        name="last5"
                                        setLocalState={setState}
                                        id="last5"
                                        label="5 siste siffer"
                                        type="tel"
                                        denseBottom
                                        width="120px"
                                        cols={6}
                                        inputComponent={TextMaskNumeric}
                                        maxLength={5}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <LargeButton disabled={state.buzy} style={{ margin: '46px 0px 0px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ Login: state.last5 }); }}>Logg inn</LargeButton>
                        {state.buzy &&
                            <Grid container justifyContent="center" alignItems="center" direction="row">
                                <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'45px'} width={'50px'} />
                            </Grid>
                        }
                    </div>
                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', textAlign: 'center', marginTop: '80px' }}>
                        <div>Oversikten er levert i samarbeid med Strex AS.</div>
                        <img src={StrexLogo} style={{ margin: '0px', width: '100px' }} alt="" />
                    </div>
                </>
            }
            {step === 5 && /* Confirm login */
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{ padding: '0px 20px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>
                        <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '14px', marginTop: '29px', marginBottom: '40px', textAlign: 'left' }}>
                            Innlogginskode er sendt til:
                        </div>
                        <div style={{ fontSize: '16px', fontWeight: 700 }}>
                            {state.FormattedMsisdn}
                        </div>
                        <TextField
                            autoFocus
                            localState={state}
                            name="loginConfirmCode"
                            setLocalState={setState}
                            id="loginConfirmCode"
                            label="Innloggingskode"
                            type="tel"
                            denseBottom
                            inputComponent={TextMaskNumeric}
                            maxLength={4}
                        />
                        <LargeButton disabled={state.buzy} style={{ marginTop: '28px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ ConfirmLogin: { pin: state.loginConfirmCode, sessionId: sessionId } }); }}>Logg inn</LargeButton>
                        {state.buzy &&
                            <Grid container justifyContent="center" alignItems="center" direction="row">
                                <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'45px'} width={'50px'} />
                            </Grid>
                        }
                    </div>
                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', textAlign: 'center', marginTop: '80px' }}>
                        <div>Oversikten er levert i samarbeid med Strex AS.</div>
                        <img src={StrexLogo} style={{ margin: '0px', width: '100px' }} alt="" />
                    </div>

                </div>
            }
            {step === 6 && /* Transactions */
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '17px' }}>
                    {state.strexTransactions && state.strexTransactions.length > 0 ? state.strexTransactions.map((t, index) => {
                        var id = t.TransactionID;
                        var date = new Date(t.Created);
                        var dateString = ('0' + date.getDate()).slice(-2) + '.'
                            + ('0' + (date.getMonth() + 1)).slice(-2) + '.'
                            + date.getFullYear();
                        var timeString = ('0' + date.getHours()).slice(-2) + ':'
                            + ('0' + date.getMinutes()).slice(-2);
                        var price = (Math.round(t.Amount * 100) / 100).toFixed(2);
                        let type = "";
                        switch (t.TransactionType) {
                            case "sell":
                                type = "Betaling " + dateString + " klokken " + timeString;
                                break;
                            case "reverse":
                                type = "Kreditering " + dateString + " klokken " + timeString;
                                break;
                            default:
                                type = dateString + " klokken " + timeString;
                                break;
                        }
                        return (
                            <div key={id} style={{ backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', padding: '5px', marginBottom: '19px', width: '100%' }}>
                                <Accordion expanded={state.expand === 'panel' + id} onChange={handleExpand('panel' + id)}>
                                    <AccordionSummary
                                        aria-controls={"panel" + id + "-content"}
                                        id={"panel" + id + "-header"}
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Grid container direction="column" style={{ marginBottom: '-10px' }}>
                                            <Grid container direction="row" justifyContent="space-between">
                                                <Grid item>
                                                    <Grid container direction="row" justifyContent="flex-start">
                                                        <Grid item style={{ fontSize: '12px', fontWeight: 200, width: '75px' }}>
                                                            {dateString}
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ fontSize: '14px', fontWeight: 500 }}>{t.CompanyName}</div>
                                                            <div style={{ fontSize: '10px', fontWeight: 200 }}>{t.ServiceText}</div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    Kr {price}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="space-between" style={{ border: '1px solid #FFCECE', borderRadius: '10px', padding: '3px 5px 0px 5px' }}>
                                                <Grid item>
                                                    <Icon name="newsletter" size={12} /><div style={{ fontSize: '10px', fontWeight: 200, color: theme.palette.primary.main, display: 'inline-block', marginLeft: '5px', verticalAlign: 'middle' }}>{t.EmailAddress}</div>
                                                </Grid>
                                                <Grid item>
                                                    <Icon name="contacts" size={12} /><div style={{ fontSize: '10px', fontWeight: 200, color: theme.palette.primary.main, display: 'inline-block', marginLeft: '5px', verticalAlign: 'middle' }}>{t.PhoneNo}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{ fontSize: '12px', fontWeight: 500, marginBottom: '15px' }}>
                                            {type}
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: 700 }}>
                                            Beløpet gjelder
                                        </div>
                                        <div style={{ fontSize: '12px', fontWeight: 200, marginBottom: '15px' }}>
                                            {t.CompanyName}
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: 700 }}>
                                            Produkttype
                                        </div>
                                        <div style={{ fontSize: '12px', fontWeight: 200, marginBottom: '15px' }}>
                                            {t.ServiceText}
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: 700 }}>
                                            Fakturatekst
                                        </div>
                                        <div style={{ fontSize: '12px', fontWeight: 200, marginBottom: '15px' }}>
                                            {t.InvoiceText}
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: 700 }}>
                                            Kortnummer
                                        </div>
                                        <div style={{ fontSize: '12px', fontWeight: 200, marginBottom: '15px' }}>
                                            {t.ShortNo}
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: 700 }}>
                                            Transaksjons-ID
                                        </div>
                                        <div style={{ fontSize: '12px', fontWeight: 200, marginBottom: '15px' }}>
                                            {t.TransactionID}
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: 700 }}>
                                            Butikk nettside
                                        </div>
                                        <div style={{ fontSize: '12px', fontWeight: 200, marginBottom: '15px' }}>
                                            <Link href={t.Webpage} target="_blank" rel="noopener">{t.Webpage}</Link>
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: 700 }}>
                                            Kundestøtte telefon
                                        </div>
                                        <div style={{ fontSize: '12px', fontWeight: 200, marginBottom: '15px' }}>
                                            {t.PhoneNo}
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: 700 }}>
                                            Kontakt
                                        </div>
                                        <div style={{ fontSize: '12px', fontWeight: 200, marginBottom: '15px' }}>
                                            <Link href={"mailto://" + t.EmailAddress} target="_blank" rel="noopener">{t.EmailAddress}</Link>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    }) :
                        <div style={{ marginTop: '23px', marginBottom: '23px', padding: '15px 15px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', textAlign: 'center' }}>
                            <div style={{ fontSize: '14px', textAlign: 'center', fontWeight: 700 }}>
                                Ingen transaksjoner på nummer {state.FormattedMsisdn}
                            </div>
                            <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '14px', textAlign: 'center', marginTop: '19px' }}>
                                Det er ingen registrerte kjøp på dette nummeret. Perioden vi sjekker er 3 måneder tilbake i tid. Det kan ta noen timer før en transaksjon vises i oversikten her hvis du nettopp har gjennomført et kjøp.
                            </div>
                        </div>
                    }
                    <AccordionInfo expanded={state.infoExpand === 'panel1'} onChange={handleInfoExpand('panel1')} style={{ maxWidth: '320px' }}>
                        <AccordionInfoSummary
                            aria-controls={"panel1-content"}
                            id={"panel1-header"}
                            expandIcon={<ExpandMoreIcon style={{ padding: '4px' }} />}
                        >
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ marginTop: '5px' }}>
                                <Grid item>
                                    <Icon name="question" size={16} color={theme.palette.primary.main} />
                                </Grid>
                                <Grid item>
                                    <div style={{ fontSize: '13px', fontWeight: 500, marginLeft: '5px' }}>
                                        Jeg vil avslutte et abonnement over
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionInfoSummary>
                        <AccordionInfoDetails>
                            <div style={{ fontSize: '12px', fontFamily: theme.typography.fontFamily2 }}>
                                Du kan enkelt avslutte ditt abonnement. Kontakt selskapet som har fakturert deg, via e-post, telefon eller deres nettsider. Du kan også avslutte abonnement ved å gå dirkete inn i Strex sin Min side og avslutte abonnementet der. <Link href="https://minside.strex.no" target="_blank" rel="noopener">https://minside.strex.no</Link>
                            </div>

                        </AccordionInfoDetails>
                    </AccordionInfo>
                    <AccordionInfo expanded={state.infoExpand === 'panel2'} onChange={handleInfoExpand('panel2')} style={{ maxWidth: '320px', marginTop: '19px' }}>
                        <AccordionInfoSummary
                            aria-controls={"panel2-content"}
                            id={"panel2-header"}
                            expandIcon={<ExpandMoreIcon style={{ padding: '4px' }} />}
                        >
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ marginTop: '5px' }}>
                                <Grid item>
                                    <Icon name="question" size={16} color={theme.palette.primary.main} />
                                </Grid>
                                <Grid item>
                                    <div style={{ fontSize: '13px', fontWeight: 500, marginLeft: '5px' }}>
                                        Se kjøp eldre enn 3 måneder
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionInfoSummary>
                        <AccordionInfoDetails>
                            <div style={{ fontSize: '12px', fontFamily: theme.typography.fontFamily2 }}>
                                Her kan du se alle kjøp tilknyttet ditt mobilnummer de siste 3 måneder. Det gjelder uavhenging av hvilket norsk mobilselskap du har brukt i denne perioden. For å se kjøp opp til de siste 6 måneder, gå til: <Link href="https://minside.strex.no" target="_blank" rel="noopener">https://minside.strex.no</Link>
                            </div>

                        </AccordionInfoDetails>
                    </AccordionInfo>

                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', textAlign: 'center', marginTop: '20px' }}>
                        <div>Oversikten er levert i samarbeid med Strex AS.</div>
                        <img src={StrexLogo} style={{ margin: '0px', width: '100px' }} alt="" />
                    </div>
                </div>
            }
            {step === 7 && /* Missing user information */
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{ padding: '0px 20px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>
                        <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '14px', marginTop: '29px', textAlign: 'left' }}>
                            Før du kan fortsette er du nødt til å legge til brukerinformasjon for dette telefonnumeret.<br />
                            Minst fornavn, etternavn og fødselsdato skal oppgis.
                        </div>
                    </div>
                    <LargeButton style={{ marginTop: '40px' }} onClick={() => { props.setBackFunction(null); props.showDialog('changeUser'); }}>Endre bruker</LargeButton>
                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', textAlign: 'center', marginTop: '80px' }}>
                        <div>Oversikten er levert i samarbeid med Strex AS.</div>
                        <img src={StrexLogo} style={{ margin: '0px', width: '100px' }} alt="" />
                    </div>
                </div>
            }
        </>
    );
}