import React, { useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Scroller from 'react-scroll';
import { clearCache } from "clear-cache";

const scroll = Scroller.scroller;

export default function ServerState({ serverState, setState, localState, setLocalState, handleRefresh, handleClose }) {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (serverState.Message !== undefined && serverState.Message !== null && (serverState.Message.MessageTitle || serverState.Message.MessageText)) {
            const MySwal = withReactContent(Swal);

            setTimeout(
                function () {
                    MySwal.fire({
                        title: serverState.Message.MessageTitle,
                        html: serverState.Message.MessageText,
                        icon: serverState.Message.MessageType,
                        confirmButtonText: serverState.Message.ConfirmButtonText,
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        switch (serverState.Message.Action) {
                            case 'close':
                                if (handleRefresh)
                                    handleRefresh();
                                if (handleClose)
                                    handleClose();
                                break;
                            case 'none':
                                break;
                            case 'reload':
                                window.location.reload();
                                break;
                            case 'redirect':
                                window.location.assign(serverState.Message.ActionParam);
                                break;
                            case 'clearcache':
                                clearCache();
                                break;
                            default:
                                break;
                        }
                    });
                },
                10
            );
        }
    }, [serverState.Message])
    useEffect(() => {
        if (serverState.StateName !== undefined && serverState.StateName !== null && serverState.StateName != '') {
            if (setLocalState) {
                setTimeout(
                    function () {
                        if (setLocalState) {
                            setLocalState({ [serverState.StateName]: serverState.StateValue });
                        }
                    },
                    10
                );
            } else if (setState) {
                setTimeout(
                    function () {
                        if (setState) {
                            setState({ [serverState.StateName]: serverState.StateValue });
                        }
                    },
                    10
                );
            }
        }
        if (serverState.StateName === 'ClearCache') {
            clearCache();
            sessionStorage.setItem("Reload", "Yes");
        }
    }, [serverState.StateName])
    useEffect(() => {
        if (serverState.DocumentTitle !== undefined && serverState.DocumentTitle !== null) {
            document.title = serverState.DocumentTitle;
        }
    }, [serverState.DocumentTitle])
    useEffect(() => {
        if (serverState.Notification !== undefined && serverState.Notification !== null) {
            setTimeout(
                function () {
                    enqueueSnackbar(serverState.Notification.Message, { variant: serverState.Notification.Variant })
                },
                10
            );
        }
    }, [serverState.Notification])
    useEffect(() => {
        if (serverState.ErrorField !== undefined && serverState.ErrorField !== null && serverState.ErrorField !== '') {
            const setFocus = (id) => {
                setTimeout(
                    function () {
                        scrollIntoView(id);
                        var el = document.getElementById(id);
                        if (!el)
                            el = document.getElementsByName(id);
                        if (el) {
                            setState({ buzy: false });
                            el.focus();
                        }

                    },
                    0
                );
            }
            const scrollIntoView = (name) => {
                scroll.scrollTo(name, {
                    duration: 0,
                    delay: 0,
                    smooth: true,
                    offset: -80, // Scrolls to element + 50 pixels down the page
                });
            }
            if (setLocalState) {
                setTimeout(
                    function () {
                        if (setLocalState) {
                            setLocalState({ ErrorField: serverState.ErrorField });
                            setLocalState({ ErrorText: serverState.ErrorText });
                            setFocus(serverState.ErrorField);
                        }
                    },
                    10
                );
            } else if (setState) {
                setFocus(serverState.ErrorField);
            }
        }
    }, [serverState.ErrorField])
    useEffect(() => {
        if (serverState.FocusField !== undefined && serverState.FocusField !== null && serverState.FocusField !== '') {
            const setFocus = (id) => {
                setTimeout(
                    function () {
                        scrollIntoView(id);
                        var el = document.getElementById(id);
                        if (!el)
                            el = document.getElementsByName(id);
                        if (el) {
                            setState({ buzy: false });
                            el.focus();
                        }

                    },
                    0
                );
            }
            const scrollIntoView = (name) => {
                scroll.scrollTo(name, {
                    duration: 0,
                    delay: 0,
                    smooth: true,
                    offset: -80, // Scrolls to element + 50 pixels down the page
                });
            }
            if (setLocalState) {
                setTimeout(
                    function () {
                        if (setLocalState) {
                            setLocalState({ FocusField: serverState.FocusField });
                            setFocus(serverState.FocusField);
                        }
                    },
                    10
                );
            } else if (setState) {
                setFocus(serverState.FocusField);
            }
        }
    }, [serverState.FocusField])
    useEffect(() => {
        if (serverState.CallbackPath !== undefined && serverState.CallbackPath !== null) {
            sessionStorage.CallbackPath = serverState.CallbackPath;
        }
    }, [serverState.CallbackPath])
    useEffect(() => {
        if (serverState.CallbackState !== undefined && serverState.CallbackState !== null) {
            sessionStorage.CallbackState = serverState.CallbackState;
        }
    }, [serverState.CallbackState])
    useEffect(() => {
        if (serverState.CallbackDialog !== undefined && serverState.CallbackDialog !== null) {
            sessionStorage.CallbackDialog = serverState.CallbackDialog;
        }
    }, [serverState.CallbackDialog])
    return (
        <>
        </>
    );
}