import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from "../components/Selfcare/Common/Icon";
import { Grid } from '@material-ui/core';
import { LargeButton } from '../controls/components/Buttons';

export default function SubscriptionUnPause(props) {
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("SubscriptionUnPause", { ConfirmDialogOpen: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, termsAccepted: false, msisdn: '', amount: '' });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const isNorway = gstate.IsNorway.get();
    const isSweden = !isNorway;

    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} handleRefresh={props.handleRefresh} />
            <Dialog
                open={state.ConfirmDialogOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setState({ ConfirmDialogOpen: false, buzy: false });
                    }
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <div style={{ display: 'flex' }}>
                        <Icon name="play-button" size={36} color={theme.palette.primary.main} />
                        <span style={{ marginLeft: '20px', fontWeight: '700' }}>{isNorway ? 'Gjenåpne fryst Hyttebredbånd' : 'Återöppna pausat abonnemang'}</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {isNorway ? <span>Jeg ønsker å gjenåpne Hyttebredbåndet.<br /><br />Abonnementet gjenåpnes i løpet av 15 minutter.</span> : 'Jag önskar återöppna mitt abonnemang. Abonnemanget återöppnas inom ca 15 minuter.'}
                </DialogContent>
                <DialogActions>
                    <LargeButton onClick={() => { vm.$dispatch({ UnPauseSubscription: true }); }}>
                        {isNorway ? 'Gjenåpne Hyttebredbåndet' : 'Återöppna mitt abonnemang'}
                    </LargeButton>
                    <LargeButton color="secondary" variant="text" onClick={() => { setState({ ConfirmDialogOpen: false, buzy: false }); }}>
                        {t('selfcare:button.canceluc')}
                    </LargeButton>
                </DialogActions>
            </Dialog>
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10}>
                        <Icon name="play-button" size={48} color="#FFFFFF" />
                        <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                            {isNorway ? 'Gjenåpne fryst Hyttebredbånd' : 'Återöppna pausat abonnemang'}
                        </div>
                        <div>
                            {isNorway ? <span>Her kan du gjenåpne Hyttebredbåndet ditt hos Chilimobil.<br /><br />Det tar ca 15 min å gjenåpne Hyttebredbåndet.</span> : <span>Här kan du återöppna ditt abonnemang som du har hos Chilimobil. Om du har fler abonnemang som du vill återöppna, så tar du ett abonnemang åt gången.<br /><br />Att återöppna ett abonnemang (aktivera ditt simkort) tar cirka 15 minuter.</span>}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center">
                        <div style={{ paddingTop: '20px' }}>
                            <LargeButton color="secondary" variant="text" style={{ marginRight: '14px' }} onClick={() => { props.handleClose(); }}>
                                {t('selfcare:button.cancel')}
                            </LargeButton>
                            <LargeButton onClick={() => { setState({ ConfirmDialogOpen: true }); }} disabled={localState.buzy}>
                                {isNorway ? 'Gjenåpne ' : 'Återöppna '}{state.MSISDN}
                            </LargeButton>
                        </div>
                    </Grid>
                </Grid>
                {state.buzy &&
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                    </div>
                }
            </Grid>
        </>
    );
}