import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Version from "../version";
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import theme from '../theme';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';

// dotNetify
import dotnetify from 'dotnetify';

import auth from "../auth";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();

const MySwal = withReactContent(Swal);

class OrderSimcard extends Component {
    constructor(props) {
        super(props);
        var simType = sessionStorage.simType || 'main';
        var biData, cbState;
        const callbackState = sessionStorage.CallbackStateRet;
        if (callbackState) {
            if (callbackState !== '[object Object]') {
                cbState = JSON.parse(callbackState);
                sessionStorage.removeItem('CallbackStateRet');
            }
            else {

            }
        }
        const bankIdData = sessionStorage.BankIdData;
        if (bankIdData) {
            biData = JSON.parse(bankIdData);
            sessionStorage.removeItem('BankIdData');
        }
        this.vm = dotnetify.react.connect("OrderSimcard", this, {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg:
            {
                SimType: simType,
                SubscriptionNo: props.SubscriptionNo,
                HostUrl: window.location.host,
                Path: window.location.pathname,
                BankIdData: biData,
                CallbackState: cbState,
            },
            exceptionHandler: _ => auth.signOut()
        });
        // Set up function to dispatch state to the back-end.
        this.dispatchState = state => this.vm.$dispatch(state);

        // we use this to make the card to appear after the page has been rendered
        this.state = {
            show: false,
            redirect: null,
            toUrl: null,
            OrderSent: false,
            OrderError: '',
            buzy: true,
            ErrorField: '',
            ErrorText: '',
            EnterPassCodeDialogOpen: false,
            PassCodeText: '',
            PassCode: '',
            anchorEl: null,
            OrderButtonText: props.t('selfcare:button.order'),
        };
    }
    componentDidMount() {
        setTimeout(
            function () {
                this.setState({ show: true });
            }.bind(this),
            700
        );
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.SelectedSimType !== this.state.SelectedSimType && this.state.SimType === 'main') {
            if (this.state.IsNorway && nextState.SelectedSimType === 'eSim') {
                this.setState({ OrderButtonText: 'Godkjenn med BankID' });
            } else {
                this.setState({ OrderButtonText: this.props.t('selfcare:button.order') })
            }
        }
        if (nextState.BankIdUrl !== this.state.BankIdUrl && nextState.BankIdUrl !== null) {
            window.location.replace(nextState.BankIdUrl);
        }
        if (nextState.CallbackPath !== undefined && nextState.CallbackPath !== null && nextState.CallbackPath !== this.state.CallbackPath) {
            sessionStorage.CallbackPath = nextState.CallbackPath;
        }
        if (nextState.CallbackState !== undefined && nextState.CallbackState !== null && nextState.CallbackState !== this.state.CallbackState) {
            sessionStorage.CallbackState = nextState.CallbackState;
        }
        if (nextState.CallbackDialog !== undefined && nextState.CallbackDialog !== null && nextState.CallbackDialog !== this.state.CallbackDialog) {
            sessionStorage.CallbackDialog = nextState.CallbackDialog;
        }
        if (nextState.StateName && !this.state.StateName) {
            setTimeout(
                function () {
                    this.setState({ [nextState.StateName]: nextState.StateValue });
                }.bind(this),
                5
            );
        }
        if (nextState.OrderSent && !this.state.OrderSent) {
            MySwal.fire({
                title: this.props.t('selfcare:notification.ordersent'),
                html: this.props.t('selfcare:notification.inmailboxwithindays'),
                icon: 'success',
                confirmButtonColor: theme.palette.primary.main,
            }).then((result) => {
                if (this.props.handleRefresh)
                    this.props.handleRefresh();
                if (this.props.handleClose)
                    this.props.handleClose();
                if (sessionStorage.returnUrl) {
                    let url = sessionStorage.returnUrl;
                    sessionStorage.removeItem('returnUrl');
                    window.location.assign(url);
                }
            });
        }
        if (nextState.OrderError && !this.state.OrderError) {
            MySwal.fire({
                title: this.props.t('selfcare:notification.orderfailed'),
                html: nextState.OrderError,
                icon: 'error',
                confirmButtonColor: theme.palette.primary.main,
            });
            this.setState({ buzy: false });
        }
        if (nextState.Address && !this.state.Address) {
            this.setState({ buzy: false });
        }
        if (nextState.Message && !this.state.Message) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: nextState.Message.MessageTitle,
                        html: nextState.Message.MessageText,
                        icon: nextState.Message.MessageType,
                        confirmButtonText: nextState.Message.ConfirmButtonText,
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        switch (nextState.Message.Action) {
                            case 'close':
                                if (this.props.handleRefresh)
                                    this.props.handleRefresh();
                                if (this.props.handleClose)
                                    this.props.handleClose();
                                break;
                            case 'none':
                                break;
                            case 'reload':
                                window.location.reload();
                                break;
                            case 'redirect':
                                window.location.assign(nextState.Message.ActionParam);
                                break;
                            default:
                                break;
                        }
                    });
                }.bind(this),
                10
            );
        }
        if (nextState.ErrorField && !this.state.ErrorField) {
            this.setState({ buzy: false });
            var el = document.getElementById(this.state.ErrorField);
            if (el)
                el.focus();
        }

    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.vm.$destroy();
    }
    handleEnterPassCodeDialogClose = () => {
        this.setState({ EnterPassCodeDialogOpen: false });
        this.setState({ buzy: false })
    }
    hideAlert = () => {
        this.setState({
            alert: null, buzy: false, OrderError: '', OrderSent: false
        });
    }
    navigateTo = url => {
        this.setState({ show: false });
        setTimeout(
            function () {
                this.setState({
                    toUrl: url,
                    redirect: true
                });
            }.bind(this),
            300
        );
    }
    handleClick = (event, popoverId) => {
        this.setState({
            openedPopoverId: popoverId,
            anchorEl: event.currentTarget,
        });
    };
    handleClose = () => {
        this.setState({
            openedPopoverId: null,
            anchorEl: null,
        });
    };

    render() {
        const classes = this.props;
        const { t } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div style={{ height: '100vh' }}>
                <Dialog
                    open={this.state.EnterPassCodeDialogOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                            this.handleEnterPassCodeDialogClose();
                        }
                    }}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <Icon name="edit" size={36} color={theme.palette.primary.main} />
                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>Tast inn kode fra SMS</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.PassCodeText}<br /><br />
                        <TextField fullWidth
                            autoFocus
                            localState={this.state}
                            name="PassCode"
                            setLocalState={this.setState.bind(this)}
                            id="PassCode"
                            label={t('selfcare:label.code')}
                            type="text"
                            margin="normal"
                            afterChange={(value) => { this.setState({ PassCodeErrorText: '' }); }}
                        />
                        {this.state.PassCodeErrorText && <div style={{ width: '100%', padding: '5px', backgroundColor: theme.palette.primary.main, color: theme.palette.common.white, fontSize: '12px' }}>{this.state.PassCodeErrorText}</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton color="primary" onClick={() => { this.dispatchState({ SetPassCode: { PassCode: this.state.PassCode, SimType: this.state.SelectedSimType } }); }} >
                            {t('selfcare:button.send-order-uc')}
                        </LargeButton>
                        <LargeButton variant="text" color="secondary" onClick={this.handleEnterPassCodeDialogClose}>
                            {t('selfcare:button.cancel-uc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                {this.state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: this.state.AdministratedMsisdn }) }} /></div>}
                <div style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white, padding: '20px' }} className='white-links'>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10} >
                            <Icon name="simcard" size={48} color={theme.palette.common.white} />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                {this.state.PricePlanType === 'postpaid' ? t('selfcare:item.didlosesim') : t('selfcare:item.didlosesim-prepaid')}
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                <span dangerouslySetInnerHTML={{ __html: this.state.PricePlanType === 'postpaid' ? t('selfcare:info.simorder', { 0: this.state.SimPrice, 1: this.state.eSimPrice }) : t('selfcare:info.simorder-prepaid', { 0: this.state.SimPrice, 1: this.state.eSimPrice }) }} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10} >
                            {!this.state.OrderOpen && <div>
                                {(this.state.IsSweden || this.state.PricePlanType === 'prepaid') &&
                                    <React.Fragment>
                                        <div style={{ fontSize: '16px', paddingTop: '20px' }}>{this.state.Title}</div>
                                        <div style={{ paddingTop: '20px', paddingLeft: '10px', fontWeight: '700' }}>{t('selfcare:item.simsentto')}:</div>
                                        <div style={{ paddingLeft: '10px' }} dangerouslySetInnerHTML={{ __html: this.state.Address }} />
                                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                            <div style={{ paddingTop: '20px' }}>
                                                <LargeButton color="primary" onClick={() => { this.setState({ buzy: true }); this.dispatchState({ PerformChange: { SimType: 'Standard' } }); }} disabled={this.state.buzy} style={{ marginRight: '10px' }}>{t('selfcare:button.order')}</LargeButton>
                                                <LargeButton id='cancel' color="secondary" variant="text" disabled={this.state.buzy} onClick={() => { this.props.handleRefresh(); this.props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                            </div>
                                        </Grid>
                                    </React.Fragment>}
                                {this.state.IsNorway && this.state.PricePlanType === 'postpaid' &&
                                    <div style={{ padding: '20px' }}>
                                        <div style={{ fontSize: '16px', marginBottom: '17px' }}>{this.state.Title}</div>
                                        <div style={{ marginBottom: '5px' }}><b>Velg SIM-type:</b></div>
                                        <FormControl error={this.state.ErrorField === 'SelectedSimType'}>
                                            <FormGroup>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={this.state.SelectedSimType === 'Standard'}
                                                                onChange={() => { this.setState({ SelectedSimType: 'Standard', SendSimCard: true }); }}
                                                                value='Standard'
                                                                disabled={this.state.DisableButtons || !this.state.CanChangeServices}
                                                            />
                                                        }
                                                        label={t('selfcare:label.physical-sim') + ' - ' + this.state.SimPrice}
                                                        labelPlacement="end"
                                                    />
                                                    <div>
                                                        <IconButton
                                                            aria-owns="Standard"
                                                            aria-haspopup="true"
                                                            aria-label={t('selfcare:label.help')}
                                                            onClick={(e) => { this.handleClick(e, "Standard"); }}
                                                        >
                                                            <Icon name="information" size={24} color={theme.palette.primary.main} />
                                                        </IconButton>
                                                        <Popover
                                                            id="Standard"
                                                            open={this.state.openedPopoverId === "Standard"}
                                                            anchorEl={anchorEl}
                                                            onClose={this.handleClose}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                        >
                                                            <>
                                                                <div style={{ textAlign: 'right' }}>
                                                                    <IconButton role="button" aria-label={this.state.IsSweden ? 'Stäng' : 'Lukk'} size="small" onClick={(e) => { this.handlePopupClose(); }}>
                                                                        <Icon name="close" size={24} color={theme.palette.primary.main} />
                                                                    </IconButton>
                                                                </div>

                                                                <div style={{ padding: '10px', maxWidth: '400px', fontSize: '12px' }}>
                                                                    <span dangerouslySetInnerHTML={{ __html: t('selfcare:info.after-order-sim-is-sent', { 0: this.state.Address }) }} />
                                                                </div>
                                                            </>
                                                        </Popover>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={this.state.SelectedSimType === 'eSim'}
                                                                onChange={() => { this.setState({ SelectedSimType: 'eSim', SendSimCard: true }); }}
                                                                value='eSim'
                                                                disabled={this.state.DisableButtons || !this.state.CanChangeServices}
                                                            />
                                                        }
                                                        label={t('selfcare:label.esim') + ' - ' + this.state.eSimPrice}
                                                        labelPlacement="end"
                                                    />
                                                    <div>
                                                        <IconButton
                                                            aria-owns="eSim"
                                                            aria-haspopup="true"
                                                            aria-label={t('selfcare:label.help')}
                                                            onClick={(e) => { this.handleClick(e, "eSim"); }}
                                                        >
                                                            <Icon name="information" size={24} color={theme.palette.primary.main} />
                                                        </IconButton>
                                                        <Popover
                                                            id="eSim"
                                                            open={this.state.openedPopoverId === "eSim"}
                                                            anchorEl={anchorEl}
                                                            onClose={this.handleClose}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                        >
                                                            <>
                                                                <div style={{ textAlign: 'right' }}>
                                                                    <IconButton role="button" aria-label={this.state.IsSweden ? 'Stäng' : 'Lukk'} size="small" onClick={(e) => { this.handlePopupClose(); }}>
                                                                        <Icon name="close" size={24} color={theme.palette.primary.main} />
                                                                    </IconButton>
                                                                </div>

                                                                <div style={{ padding: '10px', maxWidth: '400px', fontSize: '12px' }}>
                                                                    <span dangerouslySetInnerHTML={{ __html: t('selfcare:info.esim', { 0: this.state.SimEmail }) }} />
                                                                </div>
                                                            </>
                                                        </Popover>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            <FormHelperText>{this.state.ErrorField === 'SelectedSimType' && this.state.ErrorText}</FormHelperText>
                                        </FormControl>
                                        {this.state.SelectedSimType === 'Standard' &&
                                            <Grid item>
                                                <p><b>{t('selfcare:item.simsentto')}:</b></p>
                                                <span dangerouslySetInnerHTML={{ __html: this.state.Address }} />
                                            </Grid>}
                                        {this.state.SelectedSimType === 'eSim' &&
                                            <Grid item>
                                                <p><b>{t('selfcare:info.qr-esim-sent')}:</b></p>
                                                <span dangerouslySetInnerHTML={{ __html: this.state.SimEmail }} />
                                            </Grid>}
                                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                            <div style={{ paddingTop: '20px' }}>
                                                <LargeButton color="primary" onClick={() => { this.setState({ buzy: true }); this.dispatchState({ PerformChange: { SimType: this.state.SelectedSimType, PassCode: this.state.PassCode } }); }} disabled={this.state.buzy} style={{ marginRight: '10px' }} >{this.state.OrderButtonText}</LargeButton>
                                                <LargeButton id='cancel' color="secondary" variant="text" disabled={this.state.buzy} onClick={() => { this.props.handleRefresh(); this.props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                            </div>
                                        </Grid>
                                    </div>}
                            </div>}
                            {this.state.OrderOpen &&
                                <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                    <div style={{ paddingTop: '20px', paddingLeft: '10px' }}>
                                        {this.state.OrderStatus}{this.state.CanActivate &&
                                            <SmallButton onClick={() => { this.setState({ buzy: true }); this.props.handleChangeDialog('activateSimcard'); }} disabled={this.state.buzy} style={{ marginLeft: '10px' }}>{t('selfcare:button.toactivation')}</SmallButton>}
                                    </div>
                                    <div style={{ paddingTop: '30px' }}>
                                        <LargeButton id='back' disabled={this.state.buzy} onClick={() => { this.props.handleRefresh(); this.props.handleClose(); }}><div style={{ marginRight: '5px', marginTop: '-5px' }}><Icon name="back" size={24} color="#FFFFFF" /></div>{t('selfcare:button.back')}</LargeButton>
                                    </div>
                                </Grid>
                            }
                            {this.state.buzy &&
                                <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        );
    }
}
OrderSimcard.propTypes = {
};

export default compose(
    withTranslation(),
)(withAITracking(reactPlugin, OrderSimcard));
