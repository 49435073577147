import React, { Component } from 'react';
import Version from "../version";
import { Grid } from "@material-ui/core";
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';

// dotNetify
import dotnetify from 'dotnetify';

import auth from "../auth";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import theme from '../theme';
var reactPlugin = new ReactPlugin();

const MySwal = withReactContent(Swal);


class Blocking extends Component {
    constructor(props) {
        super(props);
        this.vm = dotnetify.react.connect("Blocking", this, {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg:
            {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
        // Set up function to dispatch state to the back-end.
        this.dispatchState = state => this.vm.$dispatch(state);

        // we use this to make the card to appear after the page has been rendered
        this.state = {
            show: false,
            redirect: null,
            toUrl: null,
            Password: '',
            OrderSent: false,
            OrderError: '',
            buzy: false,
            ErrorField: '',
            ErrorText: '',
            Type: '',
            reason: '',
        };
    }
    componentDidMount() {
        setTimeout(
            function () {
                this.setState({ show: true });
            }.bind(this),
            700
        );
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.OrderConfirmation && !this.state.OrderConfirmation) {
            setTimeout(
                function () {
                    MySwal.fire({
                        html: nextState.OrderConfirmation,
                        icon: 'success',
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        if (this.props.handleRefresh)
                            this.props.handleRefresh();
                        if (this.props.handleClose)
                            this.props.handleClose();
                    });
                }.bind(this),
                10
            );
        }
        if (nextState.OrderError && !this.state.OrderError) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: this.props.t('selfcare:notification.blockingfailed'),
                        html: nextState.OrderError,
                        icon: 'error',
                        confirmButtonColor: theme.palette.primary.main,
                    });
                    this.setState({ buzy: false });
                }.bind(this),
                10
            );
        }
        if (nextState.Message && !this.state.Message) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: nextState.Message.MessageTitle,
                        html: nextState.Message.MessageText,
                        icon: nextState.Message.MessageType,
                        confirmButtonText: nextState.Message.ConfirmButtonText,
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        switch (nextState.Message.Action) {
                            case 'close':
                                if (this.props.handleRefresh)
                                    this.props.handleRefresh();
                                if (this.props.handleClose)
                                    this.props.handleClose();
                                break;
                            case 'none':
                                break;
                            case 'reload':
                                window.location.reload();
                                break;
                            case 'redirect':
                                window.location.assign(nextState.Message.ActionParam);
                                break;
                            default:
                                break;
                        }
                    });
                }.bind(this),
                10
            );
        }
        if (nextState.ErrorField && !this.state.ErrorField) {
            this.setState({ buzy: false });
            this.setFocus(nextState.ErrorField);
        }
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.vm.$destroy();
    }
    setFocus = fieldName => {
        switch (fieldName) {
            case "Password":
                if (this.Password) {
                    setTimeout(() => {
                        this.Password.focus();
                    }, 10);
                }
                break;
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value, ErrorField: '' });
    }

    hideAlert = () => {
        this.setState({
            alert: null, buzy: false, OrderError: '', OrderSent: false
        });
    }

    navigateTo = url => {
        this.setState({ show: false });
        setTimeout(
            function () {
                this.setState({
                    toUrl: url,
                    redirect: true
                });
            }.bind(this),
            300
        );
    }
    render() {
        const classes = this.props;
        const { t } = this.props;
        return (
            <div style={{ height: '100vh' }}>
                {this.state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: this.state.AdministratedMsisdn }) }} /></div>}
                <div style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white, padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="block-sim" size={48} color={theme.palette.common.white} />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                {t('selfcare:item.crisismustblock')}
                            </div>
                            <div style={{ fontSize: '14px', lineHeight: '16px' }}>
                                <span dangerouslySetInnerHTML={{ __html: t('selfcare:item.howtoblock', { 0: this.state.Type }) }} />
                                {this.state.Type === 'abonnement' && <span dangerouslySetInnerHTML={{ __html: t('selfcare:item.blockedfixedprices') }} />}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); this.setState({ buzy: true, ErrorField: '' }); this.dispatchState({ PerformChange: { Password: this.state.Password, Reason: this.state.reason } }); }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            {this.state.Type &&
                                <>
                                    {this.state.BlockingMessage && <div style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                        {this.state.BlockingMessage}
                                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                            <div style={{ paddingTop: '10px' }}>
                                                <LargeButton id='back' disabled={this.state.buzy} onClick={() => { this.props.handleClose(); }}><Icon name="back" size={32} color="#FFFFFF" />{t('selfcare:button.back')}</LargeButton>
                                            </div>
                                        </Grid>
                                    </div>}
                                    {!this.state.BlockingMessage && !this.state.Blocked && <div style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                        <span style={{ fontWeight: '700' }}>{t('selfcare:info.block-sim-for')} </span><span style={{ color: theme.palette.primary.main, fontWeight: '700' }}>{this.state.FormattedMSISDN}</span>
                                        <div style={{ fontWeight: '700', paddingTop: '10px' }}>{t('selfcare:phrase.reason')}:</div>
                                        <FormControl component="fieldset" required error={this.state.ErrorField === 'Reason'}>
                                            <RadioGroup
                                                aria-label={t('selfcare:label.reason')}
                                                name="reason"
                                                value={this.state.reason}
                                                onChange={this.handleChange('reason')}
                                            >
                                                <FormControlLabel value="4" control={<Radio color="primary" disabled={this.state.buzy} />} label={t('selfcare:label.lostphone')} />
                                                <FormControlLabel value="5" control={<Radio color="primary" disabled={this.state.buzy} />} label={t('selfcare:label.stolenphone')} />
                                            </RadioGroup>
                                            <FormHelperText>{this.state.ErrorField === 'Reason' && this.state.ErrorText}</FormHelperText>
                                        </FormControl>
                                        <div style={{ maxWidth: '320px', paddingTop: '17px' }}>
                                            <TextField fullWidth
                                                noPadding
                                                disabled={this.state.buzy}
                                                localState={this.state}
                                                setLocalState={this.setState.bind(this)}
                                                label={t('selfcare:label.password')}
                                                id="Password"
                                                name="Password"
                                                type="text"
                                                maxLength={30}
                                                helperText={this.state.ErrorField === 'Password' && this.state.ErrorText}
                                                inputRef={(el) => { this.Password = el }}
                                                margin="normal"
                                            />
                                        </div>
                                        <div style={{ fontSize: '12px', fontStyle: 'italic', paddingTop: '20px' }}>{t('selfcare:info.iploggedblock', { 0: this.state.Type })}</div>
                                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                            <div style={{ paddingTop: '20px' }}>
                                                <LargeButton type="submit" disabled={this.state.buzy} style={{ marginRight: '10px' }} >{t('selfcare:button.block')}</LargeButton>
                                                <LargeButton id='cancel' color="secondary" variant="text" disabled={this.state.buzy} onClick={() => { this.props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                            </div>
                                            {this.state.buzy &&
                                                <Grid item>
                                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                                </Grid>
                                            }
                                        </Grid>
                                    </div>}
                                    {!this.state.BlockingMessage && this.state.Blocked && this.state.CanUnblock && <div style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                        <span style={{ fontWeight: '700' }}>{t('selfcare:info.open-sim-for')} </span><span style={{ color: theme.palette.primary.main, fontWeight: '700' }}>{this.state.FormattedMSISDN}</span>
                                        <div style={{ maxWidth: '320px', paddingTop: '17px' }}>
                                            <TextField fullWidth error={this.state.ErrorField === 'Password'}
                                                noPadding
                                                disabled={this.state.buzy}
                                                localState={this.state}
                                                setLocalState={this.setState.bind(this)}
                                                label={t('selfcare:label.password')}
                                                name="Password"
                                                id="Password"
                                                inputProps={{ maxLength: 30, type: 'password' }}
                                                helperText={this.state.ErrorField === 'Password' && this.state.ErrorText}
                                                inputRef={(el) => { this.Password = el }}
                                                margin="normal"
                                            />
                                        </div>
                                        <div style={{ fontSize: '12px', fontStyle: 'italic', paddingTop: '20px' }}>{t('selfcare:info.iploggedunlock', { 0: this.state.Type })}</div>
                                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                            <div style={{ paddingTop: '20px' }}>
                                                <LargeButton type="submit" disabled={this.state.buzy} style={{ marginRight: '10px' }}>{t('selfcare:button.unlock')}</LargeButton>
                                                <LargeButton id='cancel' variant="text" color="secondary" disabled={this.state.buzy} onClick={() => { this.props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                            </div>
                                            {this.state.buzy &&
                                                <Grid item>
                                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                                </Grid>
                                            }
                                        </Grid>
                                    </div>}
                                </>
                            }
                        </Grid>
                    </Grid>
                </form>
            </div >
        );
    }
}
Blocking.propTypes = {
};

export default compose(
    withTranslation(),
)(withAITracking(reactPlugin, Blocking));

