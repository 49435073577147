import React from "react";
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Icon from "../../components/Selfcare/Common/Icon";


const DataMeter = (props) => {
    const theme = useTheme();
    return (
        <React.Fragment>
            <div style={{ width: '150px', height: '150px' }}>
                <CircularProgressbarWithChildren
                    value={props && props.Pot && props.Pot.values && props.Pot.values.length > 0 ? props.Pot.values[0].percentage : 0}
                    strokeWidth={8}
                    styles={buildStyles({
                        pathColor: props && props.Pot && props.Pot.values && props.Pot.values.length > 0 ? props.Pot.values[0].color : '#eee',
                        trailColor: "#eee",
                        pathTransitionDuration: 0,
                    })}
                >
                    {/* Foreground path */}
                    <CircularProgressbarWithChildren
                        value={props && props.Pot && props.Pot.values && props.Pot.values.length > 1 ? props.Pot.values[1].percentage : 0}
                        strokeWidth={8}
                        styles={buildStyles({
                            pathColor: props && props.Pot && props.Pot.values && props.Pot.values.length > 1 ? props.Pot.values[1].color : '#eee',
                            trailColor: "transparent",
                            pathTransitionDuration: 0,
                        })}
                    >
                        <CircularProgressbarWithChildren
                            value={props && props.Pot && props.Pot.values && props.Pot.values.length > 2 ? props.Pot.values[2].percentage : 0}
                            strokeWidth={8}
                            styles={buildStyles({
                                pathColor: props && props.Pot && props.Pot.values && props.Pot.values.length > 2 ? props.Pot.values[2].color : '#eee',
                                trailColor: "transparent",
                                pathTransitionDuration: 0,
                            })}
                        >
                            {props && props.Pot && <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {props.Pot.label1 && <div style={{ fontSize: '12px', height: '15px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, fontStyle: 'normal' }} ><span dangerouslySetInnerHTML={{ __html: props.Pot.label1 }} /></div>}
                                {!props.Pot.isFreeData ?
                                    <>
                                        {props.Pot.value1 && <div style={{ fontSize: '25px', height: '30px', fontWeight: 500 }}>{props.Pot.value1}</div>}
                                        {props.Pot.label2 && <div style={{ fontSize: '12px', height: '15px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, fontStyle: 'normal' }}>{props.Pot.label2}</div>}
                                        {props.Pot.value2 && <div style={{ fontSize: '16px', height: '20px', fontWeight: 500 }}>{props.Pot.value2}</div>}
                                    </>
                                    :
                                    <div style={{ marginTop: '-8px' }}>
                                        <Icon name="infinity" size={48} color={theme.palette.text.primary} />
                                    </div>

                                }

                            </Grid>}
                        </CircularProgressbarWithChildren>
                    </CircularProgressbarWithChildren>
                </CircularProgressbarWithChildren>
            </div>
        </React.Fragment>
    );
}

export default DataMeter;