import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Version from "../version";
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Button from "../components/CustomButtons/Button";
import ButtonX from '@material-ui/core/Button';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";
import pink from '@material-ui/core/colors/pink';
import Clipboard from 'react-clipboard.js';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import pricingPageStyle from "../assets/jss/material-dashboard-pro-react/views/pricingPageStyle";
import FriendsList from './FriendsList';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';

import dotnetify from 'dotnetify';

import auth from "../auth";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();

const MySwal = withReactContent(Swal);

const styles = theme => ({
    ...pricingPageStyle,
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    cssLabel: {
        '&$cssFocused': {
            color: pink[500],
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: pink[500],
        },
    },
});

const theme = createTheme({
    palette: {
        primary: pink,
    },
    typography: { useNextVariants: true },
});


class Friends extends Component {
    constructor(props) {
        super(props);
        this.vm = dotnetify.react.connect("Friends", this, {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
        // Set up function to dispatch state to the back-end.
        this.dispatchState = state => this.vm.$dispatch(state);

        // we use this to make the card to appear after the page has been rendered
        this.state = {
            show: false,
            redirect: null,
            toUrl: null,
            OrderSent: false,
            OrderError: '',
            buzy: true,
            DataLoading: true,
            ErrorField: '',
            ErrorText: '',
            tooltipOpen: false,
            registerDialogOpen: false,
            PhoneNo: '',
            Details: false,
        };
    }
    componentDidMount() {
        setTimeout(
            function () {
                this.setState({ show: true });
            }.bind(this),
            700
        );
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.StateName && !this.state.StateName) {
            setTimeout(
                function () {
                    this.setState({ [nextState.StateName]: nextState.StateValue });
                }.bind(this),
                5
            );
        }
        if (nextState.Message && !this.state.Message) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: nextState.Message.MessageTitle,
                        html: nextState.Message.MessageText,
                        icon: nextState.Message.MessageType,
                        confirmButtonText: nextState.Message.ConfirmButtonText,
                        confirmButtonColor: '#e91e63',
                    }).then((result) => {
                        if (nextState.Message.OpenUrl) {
                            this.openExternalDialog(nextState.Message.OpenUrl);
                        }
                        if (nextState.Message.RedirectToUrl) {
                            this.setState({ redirect: true, toUrl: nextState.Message.RedirectToUrl });
                        }
                    });
                }.bind(this),
                10
            );
        }
        if (nextState.ErrorField && !this.state.ErrorField) {
            this.setState({ buzy: false });
            this.setFocus(nextState.ErrorField);
        }
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.vm.$destroy();
    }

    hideAlert = () => {
        this.setState({
            alert: null, buzy: false, OrderError: '', OrderSent: false
        });
    }

    navigateTo = url => {
        this.setState({ show: false });
        setTimeout(
            function () {
                this.setState({
                    toUrl: url,
                    redirect: true
                });
            }.bind(this),
            300
        );
    }
    getFriendsText = () => {
        return "Hej!<br/>Jag vill tipsa dig om att byta till Chilimobil så får vi båda rabatt. Kolla ditt erbjudande och fixa abonnemang här: https://chilimobil.se/kompisar";
    }
    getFriendsClipboardText = () => {
        return this.getFriendsText().replace(/<br\s*[\/]?>/gi, "\n");
    }

    onSuccess = () => {
        this.setState({ tooltipOpen: true });
        setTimeout(
            function () {
                this.setState({
                    tooltipOpen: false
                });
            }.bind(this),
            2000
        );
    }
    handleCloseRegisterDialog = () => {
        this.setState({ registerDialogOpen: false });
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.value, ErrorField: '' });
        if (name === 'PostalCode') {
            this.dispatchState({ Lookup: event.target.value });
        }
    }
    setFocus = fieldName => {
        switch (fieldName) {
            case "PhoneNo":
                if (this.PhoneNo) {
                    setTimeout(() => {
                        this.PhoneNo.focus();
                    }, 10);
                }
                break;
        }
    }

    render() {
        const classes = this.props;
        const { t } = this.props;
        return (
            <div style={{ height: '100vh' }}>
                <MuiThemeProvider theme={theme}>
                    <Dialog
                        open={this.state.registerDialogOpen}
                        onClose={this.handleCloseRegisterDialog}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            <Icon name="friends" size={36} color="#e91e63" />
                            <div style={{ marginLeft: '60px', marginTop: '-45px' }}>Efterregistrera min kompisrabatt</div>
                        </DialogTitle>
                        <DialogContent>
                            <TextField fullWidth error={this.state.ErrorField === 'PhoneNo'}
                                autoFocus
                                disabled={this.state.dialogBuzy}
                                label="Ange din kompis/värvares mobilnummer"
                                value={this.state.PhoneNo}
                                id="Firstname"
                                onChange={this.handleChange('PhoneNo')}
                                className={classes.textField}
                                inputProps={{ maxLength: 14 }}
                                helperText={this.state.ErrorField === 'PhoneNo' && this.state.ErrorText}
                                InputLabelProps={{ shrink: true }}
                                inputRef={(el) => { this.PhoneNo = el; }}
                            />
                            {this.state.dialogBuzy && <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                <ReactLoading type={"bubbles"} color={"#e91e63"} height={'50px'} width={'50px'} />
                            </div>}
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ borderRadius: '50px' }} onClick={() => { this.dispatchState({ RegisterFriend: this.state.PhoneNo }); this.setState({ dialogBuzy: true }); }} color="rose" disabled={this.state.dialogBuzy}>
                                Registrera
                            </Button>
                            <ButtonX onClick={this.handleCloseRegisterDialog} disabled={this.state.dialogBuzy}>
                                Avbryt
                        </ButtonX>
                        </DialogActions>
                    </Dialog>
                    {!this.state.Details &&
                        <>
                            <div style={{ backgroundColor: '#e91e63', color: '#FFF', padding: '20px' }}>
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} sm={10}>
                                        <Icon name="friends" size={48} color="#FFFFFF" />
                                        <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                            Värva kompisar till Chilimobil!
                                            
                                </div>
                                        <div style={{ fontSize: '14px' }}>
                                            Du får rabatt per kompis varje månad
                                </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} sm={10} style={{ padding: '20px' }} >
                                        <div style={{ marginBottom: '20px' }}>
                                            <b>Så här funkar det</b><br /><br />
                                        Du kan bjuda in kompisar att bli kunder hos Chilimobil. När kompisen blir kund anger kompisen bara ditt mobilnummer vid registrering så får ni båda rabatt* <br />Värvar du många kompisar <b>så kan du helt 0:a din egen mobilkostnad!</b><br />
                                        Ganska bra deal va!.<br />
                                            <br />
                                        *rabatten kan variera över tid beroende på hur många kompisar du har och som fortfarande är kund,  samt beroende på rabattbeloppet som kan variera mellan 10-20 kr/mån .
                                    </div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <b>Sätt igång direkt!</b><br /><br />
                                        Bjud in kompisar:<br />
                                        Kopiera denna text och länk nedan och messa till dina kompisar med sms, Whatsapp etc. du kan ju också bara prata med dem
                                    </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                open={this.state.tooltipOpen}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Texten kopierad"
                                                placement="top"
                                                arrow
                                            >
                                                <Clipboard option-text={this.getFriendsClipboardText} onSuccess={this.onSuccess}>
                                                    Kopiera text
                                            </Clipboard>
                                            </Tooltip>
                                        </div>
                                        <div style={{ padding: '4px', border: '1px solid #898989', borderLeft: '5px solid #898989', backgroundColor: '#FFF' }}>
                                            <i><span dangerouslySetInnerHTML={{ __html: this.getFriendsText() }} /></i>
                                        </div>
                                        {this.state.DataLoading ? <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                            <ReactLoading type={"bubbles"} color={"#e91e63"} height={'50px'} width={'50px'} />
                                        </div>
                                            :
                                            <div style={{ width: '100%', height: '50px' }}>
                                            </div>}
                                        <Grid item xs={12}>
                                            <div>
                                                <List>
                                                    {this.state.CanBeEnlisted &&
                                                        <React.Fragment>
                                                            <Divider variant="inset" component="li" />
                                                            <ListItem button onClick={() => { this.setState({ registerDialogOpen: true }); }}>
                                                                <ListItemAvatar>
                                                                    <Icon name="add-friend" size={36} color="#e91e60" />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary="Efterregistrera min kompisrabatt"
                                                                    secondary="Jag glömde registrera mig vid beställning"
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton onClick={() => { this.setState({ registerDialogOpen: true }); }}>
                                                                        <Icon name="right-arrow" size={24} color="#e91e60" />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </React.Fragment>}
                                                    <Divider variant="inset" component="li" />
                                                <ListItem button onClick={() => { this.setState({ Details: true }); }}>
                                                        <ListItemAvatar>
                                                            <Icon name="friends" size={36} color="#e91e60" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Mina Kompisar"
                                                            secondary="Lista över Chilikompisar"
                                                        />
                                                        <ListItemSecondaryAction>
                                                        <IconButton onClick={() => { this.setState({ Details: true }); }}>
                                                                <Icon name="right-arrow" size={24} color="#e91e60" />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                </List>
                                            </div>
                                        </Grid>
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <Button id='back' color="rose" disabled={this.state.buzy} onClick={() => { this.props.handleClose(); }}><Icon name="back" size={32} color="#FFFFFF" />{t('selfcare:button.back')}</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    }
                    {this.state.Details &&
                        <FriendsList SubscriptionNo={this.props.SubscriptionNo} GoBack={() => { this.setState({ Details: false }); }}/>
                    }
                </MuiThemeProvider>
            </div>
        );
    }
}
Friends.propTypes = {
};

export default compose(
    withStyles(styles),
    withTranslation(),
)(withAITracking(reactPlugin, Friends));
