import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import SmallDataMeter from './components/SmallDataMeter';
import { SmallButton } from "./components/Buttons";
import { Link } from 'react-tiger-transition';

export const SubscriptionBox = (props) => {
    const wDim = useWindowDimensions();
    const theme = useTheme();
    const width = props.fullWidth ? '100%' : wDim.desktop ? '47%' : '100%'
    const navigateTo = (subscriptionNo) => {
        var e = document.getElementById('administrate-' + subscriptionNo);
        if (e)
            e.click();
    };
    return (
        <React.Fragment>
            <Grid role="button" item style={{
                minHeight: '139px', fontWeight: '300', width: width, padding: wDim.phone ? '14px 7px 17px 7px' : '14px 17px 17px 17px', marginBottom: '19px', borderRadius: '10px', backgroundColor: props.main ? theme.palette.primary.main : props.subscription?.Color, color: props.inverse ? theme.palette.common.white : theme.palette.text.primary, boxShadow: props.inverse ? '0px 2px 12px #00000029' : 'none', cursor: props.isAdmin ? 'pointer' : 'auto'
            }} onClick={props.isAdmin ? () => { navigateTo(props.subscription?.SubscriptionNo); } : null}>
                <div style={{ position: 'relative' }}>
                    <SmallButton id={'administrate-' + props.subscription?.SubscriptionNo} component={Link} to={'/subscriptions/subscription?subscriptionno=' + props.subscription?.SubscriptionNo} transition="glide-left" style={{ display: 'none' }} disabled={!props.isAdmin}>{props.isNorway ? 'Administrer' : 'Hantera'}</SmallButton>
                    <SmallButton style={{ position: 'absolute', top: '90px', letterSpacing: '0px', color: props.isAdmin ? props.inverse ? theme.palette.primary.main : theme.palette.common.white : '#cbb', WebkitTextFillColor: props.isAdmin ? props.inverse ? theme.palette.primary.main : theme.palette.common.white : '#cbb', backgroundColor: props.isAdmin ? props.inverse ? theme.palette.common.white : theme.palette.primary.main : '#eee' }} disabled={!props.isAdmin}>{props.isNorway ? 'Administrer' : 'Hantera'}</SmallButton>
                </div>
                <div style={{ textAlign: 'center', width: '100%', fontWeight: '400', minHeight: '20px' }}>{props.subscription?.UserName}</div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ width: '90px', textAlign: 'center' }}>
                        
                        {props.isNorway ?
                            <>
                            <div style={{ fontWeight: 400 }}>{props.subscription?.Msisdn}</div>
                            <div style={{ fontSize: '12px' }}>{props.subscription?.PackageDescription}</div>
                            </>
                            :
                            <div style={{ fontWeight: 400, fontSize: '13px' }}>{props.subscription?.Msisdn}</div>
                        }
                    </div>
                    <SmallDataMeter inverse={props.inverse} Pot={props.subscription?.Pot} />
                    <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ width: '90px' }}>
                        {props.isNorway ?
                            <>
                                <div style={{ fontWeight: 400 }}>{props.subscription?.SaldoLabel}</div>
                                {props.subscription?.SaldoText ?
                                    <div style={{ fontFamily: theme.typography.fontFamily2 }}>{props.subscription?.SaldoText}</div>
                                    :
                                    <ReactLoading type="bubbles" color={props.inverse ? theme.palette.common.white : theme.palette.primary.main} height={20} width={32} />
                                }
                            </>
                            :
                            <div style={{ fontSize: '12px', textAlign: 'center' }}>{props.subscription?.PackageName}<br />{props.subscription?.PackagePrice}</div>
                        }
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>
    );
};


