import React, { useEffect } from "react";
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

export default function Loading(props) {
    const gstate = useGlobalState(globalState);
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("Loading", {
        LoadingSubscription: true
    }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
        },
        exceptionHandler: _ => auth.signOut()
    });
    const theme = useTheme();
    const ReactGA = window.ReactGA;

    useEffect(() => {
        if (state.ServerToken) {
            window.sessionStorage.ServerToken = state.ServerToken;
        }
    }, [state.ServerToken]);

    useEffect(() => {
        const hasLocalStorage = function () {
            try {
                localStorage.setItem("__test", "data");
                localStorage.removeItem("__test");
            } catch (e) {
                return false;
            }
            return true;
        }

        if (state.EncryptedPassword && hasLocalStorage() && localStorage.chRememberMe) {
            localStorage.chPassword = state.EncryptedPassword;
        }
    }, [state.EncryptedPassword]);

    useEffect(() => {
        if (state.Greeting) {
            gstate.Greeting.set(state.Greeting);
            gstate.PackageType.set(state.PackageType);
            gstate.IsNorway.set(state.IsNorway);
            gstate.IsSweden.set(state.IsSweden);
            gstate.HasBirthday.set(state.HasBirthday);
            gstate.Fullname.set(state.Fullname);
            gstate.Msisdn.set(state.Msisdn);
            gstate.CustomerSource.set(state.CustomerSource);
            gstate.HideMenu.set(state.HideMenu);
        }
    }, [state.Greeting]);

    useEffect(() => {
        if (state.GoToOverview) {
            ReactGA.set({ page: '/home' });
            ReactGA.pageview('/home');
            window.location.assign('/home');
        }
    }, [state.GoToOverview]);

    useEffect(() => {
    if (state.SetNewPassword) {
        window.location.assign('/set-new-password');
    }
    }, [state.SetNewPassword]);

    return (
        <div id="preloader">
            <div id="loader"></div>
        </div>
    );
}

//import React, { Component } from "react";
//import PropTypes from "prop-types";
//import withStyles from "@material-ui/core/styles/withStyles";
//import overviewPageStyle from "../assets/jss/material-dashboard-pro-react/views/overviewPageStyle";
//import ReactGA from 'react-ga';

//// dotNetify
//import dotnetify from 'dotnetify';

////Authorization
//import auth from "../auth";
//import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

//var reactPlugin = new ReactPlugin();


//export class Loading extends Component {
//    constructor(props) {
//        super(props);
//        this.vm = dotnetify.react.connect("Loading", this, {
//            headers: { Authorization: "Bearer " + auth.getAccessToken() },
//            exceptionHandler: _ => auth.signOut()
//        });
//        // Set up function to dispatch state to the back-end.
//        this.dispatchState = state => this.vm.$dispatch(state);
//        // we use this to make the card to appear after the page has been rendered
//        this.state = {
//            LoadingSubscription: true,
//        };
//    }
//    UNSAFE_componentWillMount() {
//    }
//    componentWillUnmount() {
//        this.vm.$destroy();
//    }
//    componentDidMount() {
//    }
//    UNSAFE_componentWillUpdate(nextProps, nextState) {
//        if (nextState.ServerToken && !this.state.ServerToken) {
//            window.sessionStorage.ServerToken = nextState.ServerToken;
//        }
//        if (nextState.EncryptedPassword && !this.state.EncryptedPassword) {
//            if (this.hasLocalStorage() && localStorage.chRememberMe) {
//                localStorage.chPassword = nextState.EncryptedPassword;
//            }
//        }
//        if (nextState.Greeting && !this.state.Greeting) {
//            sessionStorage.setItem("Greeting", nextState.Greeting);
//            sessionStorage.setItem("PackageType", nextState.PackageType);
//            sessionStorage.setItem("IsNorway", nextState.IsNorway);
//            sessionStorage.setItem("IsSweden", nextState.IsSweden);
//            sessionStorage.setItem("HasBirthday", nextState.HasBirthday);
//            sessionStorage.setItem("Fullname", nextState.Fullname);
//            sessionStorage.setItem("Msisdn", nextState.Msisdn);
//            sessionStorage.setItem("CustomerSource", nextState.CustomerSource);
//        }
//        if (nextState.GoToOverview && !this.state.GoToOverview) {
//            ReactGA.set({ page: '/home' });
//            ReactGA.pageview('/home');
//            window.location.assign('/home');
//        }
//        if (nextState.SetNewPassword && !this.state.SetNewPassword) {
//            window.location.assign('/set-new-password');
//        }

//    }
//    hasLocalStorage = function () {
//        try {
//            window.localStorage.setItem("__test", "data");
//            window.localStorage.removeItem("__test");
//        } catch (e) {
//            return false;
//        }
//        return true;
//    }

//    render() {
//        const { classes } = this.props;
//        return (
//            <div style={{ height: '100vh' }}>
//                {/*{this.state.GoToOverview && (<Redirect to='/home' />)}*/}
//                {/*{this.state.SetNewPassword && (<Redirect to='/set-new-password' />)}*/}
//                {this.state.LoadingSubscription &&
//                    <div id="preloader">
//                        <div id="loader"></div>
//                    </div>
//                }
//            </div>
//        );
//    }
//}

//Loading.propTypes = {
//    classes: PropTypes.object.isRequired,
//    onAuthenticated: PropTypes.func
//};

//export default withStyles(overviewPageStyle)(withAITracking(reactPlugin,Loading));