import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const LargeButton = styled(({ ...other }) => (
    <Button variant='contained' color='primary' {...other} />
))`
    border-radius: 24px;
    padding: 14px 28px 12px 28px;
    line-height: 14px;
    font-size: 14px;
    font-weight: 400;
`;

export const SmallButton = styled(({ ...other }) => (
    <Button  variant='contained' color='primary' size='small' {...other} />
))`
    border-radius: 12px;
    padding: 5px 12px 4px 12px;
    line-height: 13px;
    font-size: 13px;
    font-weight: 400;
`;
