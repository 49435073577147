import React, { useEffect } from "react";
import Collapse from '@material-ui/core/Collapse';
import { Grid, Fade } from '@material-ui/core';
import PotControl from './PotControl';
import PotLegend from './components/PotLegend';
import { SmallButton, LargeButton } from './components/Buttons';
import SpinningCogWheels from './components/SpinningCogWheels';
import { disable } from "workbox-navigation-preload";

const PotContainer = (props) => {
    const hasLegend = props.Pots && props.Pots.length > 0 && props.Pots[0].hasDetails;
    const showLegend = props.Pots && props.Pots.length > 0 && props.Pots[0].showDetails;
    const { vm, state, localState, setLocalState } = props;
    let disableSuperboost = false;
    let disableDataReload = false;
    if (props.Pots && props.Pots.length > 0)
        disableSuperboost = !props.Pots[0].canBuySuperboost;
    else
        disableSuperboost = true;
    if (props.Pots && props.Pots.length > 0)
        disableDataReload = !props.Pots[0].canBuyDataReload;
    else
        disableDataReload = true;

    const handleButtons = () => {
        if (state.CanBuyData) {
            if (showLegend) {
                setTimeout(
                    function () {
                        setLocalState({ showBuyDataButton: true });
                    },
                    150
                );
            } else {
                setTimeout(
                    function () {
                        setLocalState({ showBuyDataButton: false });
                    },
                    50
                );
            }
        }
        if (state.CanBuyEUData) {
            if (showLegend) {
                setTimeout(
                    function () {
                        setLocalState({ showBuyEUDataButton: true });
                    },
                    150
                );
            } else {
                setTimeout(
                    function () {
                        setLocalState({ showBuyEUDataButton: false });
                    },
                    50
                );
            }
        }
        if (state.CanBuySuperboost) {
            if (showLegend) {
                setTimeout(
                    function () {
                        setLocalState({ showBuySuperboostButton: true });
                    },
                    150
                );
            } else {
                setTimeout(
                    function () {
                        setLocalState({ showBuySuperboostButton: false });
                    },
                    50
                );
            }
        }
        if (state.CanBuyDataReload) {
            if (showLegend) {
                setTimeout(
                    function () {
                        setLocalState({ showBuyDataReloadButton: true });
                    },
                    150
                );
            } else {
                setTimeout(
                    function () {
                        setLocalState({ showBuyDataReloadButton: false });
                    },
                    50
                );
            }
        }
        if (state.IsNorway && state.CanChangePriceplan && state.IsData) {
            if (showLegend) {
                setTimeout(
                    function () {
                        setLocalState({ showChangeDataLevelButton: true });
                    },
                    150
                );
            } else {
                setTimeout(
                    function () {
                        setLocalState({ showChangeDataLevelButton: false });
                    },
                    50
                );
            }
        }
        if (state.IsDailyData) {
            if (showLegend) {
                setTimeout(
                    function () {
                        setLocalState({ showStartPauseButton: true });
                    },
                    150
                );
            } else {
                setTimeout(
                    function () {
                        setLocalState({ showStartPauseButton: false });
                    },
                    50
                );
            }
        }
        if (state.CanPause) {
            setTimeout(
                function () {
                    setLocalState({ showPauseButton: true });
                },
                150
            );
        }
        if (state.CanUnPause) {
            setTimeout(
                function () {
                    setLocalState({ showUnPauseButton: true });
                },
                150
            );
        }
    }
    useEffect(() => {
        setLocalState({ showBuyDataButton: state.CanBuyData && !showLegend });
        setLocalState({ showChangeDataLevelButton: state.CanChangePriceplan && state.IsData && state.IsNorway && !showLegend });
    }, [state.CanBuyData])
    useEffect(() => {
        setLocalState({ showBuyEUDataButton: state.CanBuyEUData && !showLegend });
    }, [state.CanBuyEUData])
    useEffect(() => {
        setLocalState({ showBuySuperboostButton: state.CanBuySuperboost && !showLegend });
    }, [state.CanBuySuperboost])
    useEffect(() => {
        setLocalState({ showBuyDataReloadButton: state.CanBuyDataReload && !showLegend });
    }, [state.CanBuyDataReload])
    useEffect(() => {
        setLocalState({ showStartPauseButton: state.IsDailyData && !showLegend });
    }, [state.IsDailyData])
    useEffect(() => {
        setLocalState({ showPauseButton: state.CanPause });
    }, [state.CanPause])
    useEffect(() => {
        setLocalState({ showUnPauseButton: state.CanUnPause });
    }, [state.CanUnPause])

    return (
        <React.Fragment>
            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ cursor: hasLegend ? 'pointer' : 'auto' }} onClick={() => { if (hasLegend) { handleButtons(); vm.$dispatch({ ToggleDetails: 1 }); } }}>
                {!props.Pots &&
                    <React.Fragment>
                        <SpinningCogWheels style={{ height: '199px' }} />
                    </React.Fragment>}
                <Grid role="button" container direction="row" justifyContent="center" alignItems="center">
                    {props.Pots && props.Pots.map((pot) => {
                        var multiple = false;
                        if (props.Pots.length > 1) {
                            multiple = true;
                        }
                        return (
                            <PotControl key={pot.index}
                                Pot={pot}
                                multiple={multiple}
                            />
                        );
                    })
                    }
                </Grid>
                <div style={{ width: '100%', maxWidth: '450px' }}>
                    <Collapse in={showLegend}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: '10px', width: '100%', maxWidth: '450px', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 5px 10px #76767629', borderRadius: '14px', marginTop: '17px' }} >
                            {hasLegend && props.Pots[0].legends.map((legend) => {
                                return (
                                    <PotLegend key={legend.index}
                                        PotLegend={legend}
                                    />
                                );
                            })
                            }

                        </Grid>
                    </Collapse>
                </div>
                {props.Pots && props.Pots.length > 0 &&
                    <div style={{ fontSize: '10px', lineHeight: '14px', fontWeight: 300, fontStyle: 'normal', marginTop: '10px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: props.Pots[0].label3 }} /></div>
                }
                {state.DailyDataStatus &&
                    <div style={{ fontStyle: 'normal', textAlign: 'center' }}>{state.DailyDataStatus}</div>
                }
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Fade in={localState.showBuyDataReloadButton} style={{ display: localState.showBuyDataReloadButton ? 'block' : 'none' }}>
                    <LargeButton disabled={props.disabled || disableDataReload} style={{ margin: '17px 8px 13px' }} onClick={() => { props.handleBuyDataReload(); }}>{state.IsSweden ? 'Köp Data Reload' : 'Bestill Data Reload'}</LargeButton>
                </Fade>
                <Fade in={localState.showBuySuperboostButton} style={{ display: localState.showBuySuperboostButton ? 'block' : 'none' }}>
                    <LargeButton disabled={props.disabled || disableSuperboost} style={{ margin: '17px 8px 13px' }} onClick={() => { props.handleBuySuperboost(); }}>{state.IsSweden ? 'Köp Full Fart' : '+ Bestill Superboost'}</LargeButton>
                </Fade>
                <Fade in={localState.showBuyDataButton} style={{ display: localState.showBuyDataButton ? 'block' : 'none' }}>
                    <LargeButton disabled={props.disabled} style={{ margin: '17px 8px 13px' }} onClick={() => { props.handleBuyData(); }}>{state.IsSweden ? 'Köp mer Surf' : '+ Bestill datapakke'}</LargeButton>
                </Fade>
                <Fade in={localState.showBuyEUDataButton} style={{ display: localState.showBuyEUDataButton ? 'block' : 'none' }}>
                    <LargeButton disabled={props.disabled} style={{ margin: '17px 8px 13px' }} onClick={() => { props.handleBuyEUData(); }}>{state.IsSweden ? 'Köp mer EU-Surf' : '+ Bestill EU-datapakke'}</LargeButton>
                </Fade>
                <Fade in={localState.showChangeDataLevelButton} style={{ display: localState.showChangeDataLevelButton ? 'block' : 'none' }}>
                    <LargeButton disabled={props.disabled} style={{ margin: '17px 8px 13px' }} onClick={() => { props.handleChangeDataLevel(); }}>Endre datagrense</LargeButton>
                </Fade>
                <Fade in={localState.showStartPauseButton} style={{ display: localState.showStartPauseButton ? 'block' : 'none' }}>
                    <SmallButton disabled={props.disabled} style={{ margin: '5px 8px 17px' }} onClick={() => { props.handleDailyData(); }}>{state.StartPauseButtonText}</SmallButton>
                </Fade>
                <Fade in={localState.showPauseButton} style={{ display: localState.showPauseButton ? 'block' : 'none' }}>
                    <LargeButton disabled={props.disabled} style={{ margin: '17px 8px 13px' }} onClick={() => { props.handleSubscriptionPause(true); }}>Sett på pause</LargeButton>
                </Fade>
                <Fade in={localState.showUnPauseButton} style={{ display: localState.showUnPauseButton ? 'block' : 'none' }}>
                    <LargeButton disabled={props.disabled} style={{ margin: '17px 8px 13px' }} onClick={() => { props.handleSubscriptionPause(false); }}>Gjenåpne abonnement</LargeButton>
                </Fade>
            </Grid>
        </React.Fragment >
    );
}

export default PotContainer;