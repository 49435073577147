import React, { useEffect, useRef } from "react";
import auth from "../auth";
import Version from "../version";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import ServerState from '../controls/ServerState';
import SparkleDialog from '../controls/components/SparkleDialog';
import ReactLoading from 'react-loading';
import { Paper, Slide, Button, FormControlLabel, FormLabel } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Radio, RadioGroup } from '@material-ui/core';
import Mobile from "@material-ui/icons/PhoneIphone";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { LargeButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
import Logo from '../assets/img/svg/ChilimobilLogo-White.svg';

export default function NewPassword(props) {
    const { t } = useTranslation();
    const dialogRef = useRef();
    const { vm, state, setState } = useConnect("NewPassword", {
        username: '', dialogDisabled: false, NotCustomerDialogOpen: false, deliveryMethod: 'sms',
    }, {
        headers: {
            ClientVersion: Version.getVersion(),
        },
        exceptionHandler: _ => auth.signOut()
    });
    const year = 1900 + new Date().getYear();
    const locale = process.env.REACT_APP_DEFAULTLOCALE;
    var isNorway = false;
    if (locale.toLowerCase() === 'nb-no') {
        isNorway = true;
    }
    const theme = useTheme();

    const init = () => {
        setTimeout(
            function () {
                setState({ showDialog: true });
                var el = document.getElementById('username');
                el.focus();
            },
            200
        );
    }
    useEffect(() => {
        init();
    }, [])
    const handleNotCustomerDialogClose = () => {
        setState({ NotCustomerDialogOpen: false });
    }
    const handleChange = (event) => {
        setState({ deliveryMethod: event.target.value });
    }
    const handleSendPassword = e => {
        e.preventDefault();
        setState({ loading: true, dialogDisabled: true });
        vm.$dispatch({ Username: state.username });
        vm.$dispatch({ SendPassword: state.deliveryMethod });
    }

    return (
        <>
            <ServerState serverState={state} setState={setState} />
            <SparkleDialog ref={dialogRef} />
            <Dialog
                open={state.NotCustomerDialogOpen}
                onClose={handleNotCustomerDialogClose}
                aria-labelledby="responsive-dialog-title">
                <DialogContent>
                    {t('selfcare:dialog.welcome')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNotCustomerDialogClose} color="rose">
                        {t('selfcare:button.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: "center", height: '100vh', paddingTop: '10vh', overflowX: 'hidden', overflowY: 'scroll', WebkitOverflowScrolling: 'touch'
            }}>
                <div style={{ maxWidth: '320px', width: '100%' }}>
                    <Slide direction="down" in={state.showDialog} mountOnEnter unmountOnExit>
                        <Paper elevation={5} style={{ padding: '15px', borderRadius: '6px' }}>
                            <Paper elevation={3} style={{ background: 'linear-gradient(60deg, #E3264E, #fd3c65)', width: '100%', borderRadius: '6px', marginTop: '-40px', padding: '20px 5px 0px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={Logo} alt='' style={{ width: '70%' }} />
                                    <div style={{ fontSize: '14px', color: '#FFF' }}>
                                        {t('selfcare:title.newpassword')}
                                    </div>
                                </div>
                                <div style={{ textAlign: 'right', fontSize: '11px', color: '#FFF' }}>
                                    ver. {Version.getVersion()}
                                </div>
                            </Paper>
                            <form onSubmit={(e) => handleSendPassword(e)}>
                                <div style={{ padding: '34px 0px 0px' }}>
                                    <TextField fullWidth
                                        localState={state}
                                        name="username"
                                        setLocalState={setState}
                                        id="username"
                                        label={isNorway ? 'Mobilnummer' : 'Mobilnummer'}
                                        type="tel"
                                        noPadding
                                        disabled={state.rememberMe || state.dialogDisabled}
                                        endIcon={<Mobile style={{ color: '#555', marginRight: '-10px' }} />}
                                    />
                                </div>
                                <div style={{ paddingBottom: '34px' }}>
                                    <FormLabel style={{ marginTop: '20px' }} color="secondary" component="legend">{t('selfcare:label.sendpasswordon')}</FormLabel>
                                    <RadioGroup style={{ marginBottom: '-20px' }} row aria-label={t('selfcare:label.sendpasswordon')} name="deliverymethod" value={state.deliveryMethod} onChange={handleChange}>
                                        <FormControlLabel
                                            value="sms"
                                            control={<Radio color="primary" />}
                                            label="SMS"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="email"
                                            control={<Radio color="primary" />}
                                            label="E-post"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <LargeButton type="submit" fullWidth disabled={state.dialogDisabled}>
                                        {t('selfcare:button.singleusepassword')}
                                    </LargeButton>
                                    <div style={{ position: 'absolute', top: 0, left: '45%' }}>
                                        {state.dialogDisabled && <ReactLoading type={"spinningBubbles"} color={theme.palette.primary.main} height={'38px'} width={'38px'} />}
                                    </div>
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    <LargeButton variant="outlined" fullWidth disabled={state.dialogDisabled} onClick={(e) => { e.stopPropagation(); dialogRef.current.navigatePage('/', 'glide-right'); }}>
                                        <ArrowBack style={{ margin: '-12px 0px -6px' }} />
                                        {t('selfcare:button.backuc')}
                                    </LargeButton>
                                </div>
                            </form>
                        </Paper>
                    </Slide>
                </div>
                <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
                    © {year}{isNorway ? ' Chilimobil AS' : ' Chilimobil Sweden AB'}
                </div>
            </div>
        </>
    );
}