import React, { useState, useRef, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import NavigationHelper from '../utils/NavigationHelper';
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { Transition } from "../extras";
import Confetti from 'react-confetti';
import Version from "../version";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import MasonryLayout from '../controls/MasonryLayout';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from "../components/Selfcare/Common/Icon";
import { ActionBox } from '../controls/ActionBox';
import PrepaidContainer from '../controls/PrepaidContainer';
import PotContainer from '../controls/PotContainer';
import TopContainer from '../controls/TopContainer';
import { Box, BoxTitle, BoxContent } from '../controls/Box';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import SparkleDialog from '../controls/components/SparkleDialog';
import viaplayLogo from '../assets/img/viaplay.png';
import HomeOrder from '../controls/components/HomeOrder';

const nh = NavigationHelper;
export default function Home() {
    const { t } = useTranslation();
    const { vm, state } = useConnect("Home", { PaymentDialogOpen: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
        },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, showBuyDataButton: false, dialogOpen: false, ViaplayBuzy: false, RegisterBuzy: false, CancelViaplayDialogOpen: false, ActivateViaplayDialogOpen: false });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    useEffect(() => {
        if (state.pInfo) {
            gstate.paymentInfo.set(state.pInfo);
        }
        if (state.GoNets)
            window.location.assign(state.GoNets);
        if (state.StateName)
            setLocalState({ [state.StateName]: state.StateValue });
    }, [state.GoNets]);
    useEffect(() => {
        var cn = gstate.IsSweden.get() ? "Hem" : "Hjem";
        gstate.topModuleName.set(cn);
    }, []);

    useEffect(() => {
        gstate.InvoiceCount.set(state.InvoiceCount);
    }, [state.InvoiceCount]);

    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const wDim = useWindowDimensions();
    const dialogRef = useRef();
    const handleOpenPostpaidTopupDialog = () => {
        dialogRef.current.showDialog("postpaidTopup");
    }
    const handleOpenPostpaidEUTopupDialog = () => {
        dialogRef.current.showDialog("postpaidEUTopup");
    }
    const handleOpenDataReloadDialog = () => {
        dialogRef.current.showDialog("postpaidDataReload");
    }
    const handleOpenPostpaidSuperboostDialog = () => {
        dialogRef.current.showDialog("postpaidSuperboost");
    }
    const handleOpenPrepaidTopupDialog = () => {
        dialogRef.current.showDialog("prepaidTopup");
    }
    const handleOpenChargePrepaidDialog = () => {
        dialogRef.current.showDialog("chargePrepaid");
    }
    const handleChangeDataLevel = () => {
        dialogRef.current.showDialog("changeSubscriptionDataLevel");
    }
    const handleDailyData = () => {
        dialogRef.current.showDialog("dailyData");
    }
    const handleSubscriptionPause = (pause) => {
        if (pause) {
            dialogRef.current.showDialog('subscriptionpause');
        } else {
            dialogRef.current.showDialog('subscriptionunpause');
        }
    }
    const handleRefresh = () => {
        vm.$dispatch({ Refresh: true });
    }
    const handlePaymentDialogClose = () => {
        vm.$dispatch({ PaymentDialogOpen: false });
    }
    let { width, height } = wDim;
    if (width > 800)
        width = 800;
    const SafeMasonryLayout = ({ children, ...props }) => <MasonryLayout {...props}>{[].concat(children).filter(Boolean)}</MasonryLayout>
    let MessageStatus = null;
    if (state.HasUnseenMessages) {
        MessageStatus = 'new';
    } else if (state.HasMessages) {
        MessageStatus = 'messages';
    }
    const isPrepaid = gstate.PackageType.get() === 'prepaid';
    const isData = gstate.PackageType.get() === 'data';
    const isPostpaid = gstate.PackageType.get() === 'postpaid';

    return (
        <>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            <SparkleDialog ref={dialogRef} handleRefresh={handleRefresh} subscriptionNo={state.Subscription?.subscriptionNo} />
            <Dialog
                open={state.PaymentDialogOpen}
                onClose={handlePaymentDialogClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Icon name="credit-card" size={36} color={theme.palette.primary.main} />
                    {state.PaymentDialogType === "pay" &&
                        <span style={{ marginLeft: '20px' }}>{t('selfcare:title.make-payment')}</span>}
                    {state.PaymentDialogType === "invoice" &&
                        <span style={{ marginLeft: '20px' }}>Betala faktura med Swish eller kort</span>}
                </DialogTitle>
                <DialogContent>
                    {state.Subscription && (state.Subscription.cardInfo || state.PaymentDialogType === "invoice") && <Grid container style={{ color: '#000', fontSize: '1rem' }}>
                        {state.Subscription?.cardInfo &&
                            <Grid item xs={12} sm={6}>
                                <div style={{ textTransform: 'uppercase' }}>
                                    {t('selfcare:label.card-number')}
                                </div>
                                <div>
                                    {state.Subscription?.cardInfo?.CardNo}
                                </div>
                                <div style={{ textTransform: 'uppercase', marginTop: '10px' }}>
                                    {t('selfcare:label.valid-to')}
                                </div>
                                <div>
                                    {state.Subscription?.cardInfo?.ValidTo}
                                </div>
                                <Icon name={state.Subscription?.cardInfo?.CardType} size={36} />
                            </Grid>}
                        <Grid item xs={12} sm={state.Subscription?.cardInfo ? 6 : 12}>
                            {state.PaymentDialogType === "pay" &&
                                <span dangerouslySetInnerHTML={{ __html: state.PaymentDialogText }} />}
                            {state.PaymentDialogType === "invoice" &&
                                <span dangerouslySetInnerHTML={{ __html: state.PaymentDialogText }} />}
                        </Grid>
                    </Grid>}
                </DialogContent>
                <DialogActions>
                    {state.PaymentDialogType === "pay" &&
                        <LargeButton onClick={() => vm.$dispatch({ ConfirmPayment: true })}>
                            {t('selfcare:button.accept')}
                        </LargeButton>}
                    {state.PaymentDialogType === "invoice" &&
                        <LargeButton onClick={() => vm.$dispatch({ ConfirmPayment: true })}>
                            Till betalning
                        </LargeButton>}

                    <LargeButton variant="outlined" onClick={handlePaymentDialogClose}>
                        {t('selfcare:button.cancel')}
                    </LargeButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={localState.CancelViaplayDialogOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setLocalState({ CancelViaplayDialogOpen: false });
                        setLocalState({ ViaplayBuzy: false });
                    }
                }}
                style={{ zIndex: 3000 }}
            >
                <DialogContent style={{ justifyContent: 'center' }}>
                    <div style={{ fontSize: '1.5rem' }}>
                        Avsluta Viaplay
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        {state.ViaplayOptOutConfirmText}
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <LargeButton onClick={() => { vm.$dispatch({ CancelViaplayTrial: true }); setLocalState({ CancelViaplayDialogOpen: false }); setLocalState({ ViaplayBuzy: false }); }}>Bekräfta</LargeButton>
                    <LargeButton variant="outlined" onClick={() => { setLocalState({ CancelViaplayDialogOpen: false }); setLocalState({ ViaplayBuzy: false }); }}>Avbryt</LargeButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={localState.ActivateViaplayDialogOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setLocalState({ ActivateViaplayDialogOpen: false });
                    }
                }}
                TransitionComponent={Transition}
                transitionDuration={500}
                style={{ zIndex: 3000 }}
            >
                <DialogContent style={{ justifyContent: 'center' }}>
                    <div style={{ fontSize: '1.5rem' }}>
                        Registrera dig hos Viaplay
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        Du behöver nu registrera dig hos Viaplay. Vi öppnar registreringen i ett nytt fönster.
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <LargeButton onClick={() => { setLocalState({ ActivateViaplayDialogOpen: false }); dialogRef.current.openUrl(state.OpenUrl, true); }}>Till Viaplay</LargeButton>
                </DialogActions>
            </Dialog>
            {gstate.CustomerSource.get() === 'Sparkle' &&
                <>
                    {gstate.HasBirthday.get() && <Confetti width={width} height={height} numberOfPieces={200} confettiSource={{
                        w: 10,
                        h: 10,
                        x: width / 2,
                        y: height / 4,
                    }} recycle={false} />}
                    <TopContainer bell logo noSelect messageStatus={MessageStatus}>
                        {state.Messages?.map((message, index) => {
                            return (
                                <Box border relative key={index}>
                                    <BoxContent>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <div style={{ width: message.HasImage ? '60%' : '100%' }}>
                                                {message.HasTitle && <div style={{ fontFamily: theme.typography.fontFamily, fontSize: '14px', fontWeight: 400, }}>
                                                    {message.Title}
                                                </div>}
                                                <div>
                                                    <div dangerouslySetInnerHTML={{ __html: message.Message }} />
                                                </div>
                                            </div>
                                            {message.HasImage && <div style={{ width: message.HasImage ? '40%' : '100%', textAlign: 'center', marginBottom: '-30px' }}>
                                                <img src={message.ImageType + message.Image} style={{ width: '30vw', maxWidth: '100px', marginRight: '-30px' }} alt='' />
                                            </div>}
                                        </Grid>
                                    </BoxContent>
                                    {message.HasButton && <SmallButton onClick={() => { dialogRef.current.openUrl(message.Url, true); }}>{message.ButtonText}</SmallButton>}
                                    {message.HasActionButton && <SmallButton onClick={() => { dialogRef.current.showDialog(message.ActionVariant, message.ActionParam); }}>{message.ButtonText}</SmallButton>}
                                    <IconButton aria-label={gstate.IsSweden.get() ? 'Göm meddelandet' : 'Skjul meldingen'} style={{ position: 'absolute', top: '-5px', right: '-5px' }} onClick={() => { vm.$dispatch({ RemoveMessage: message.MessageId }) }}>
                                        <Icon name="close" size={36} color={theme.palette.primary.main} />
                                    </IconButton>
                                </Box>
                            )
                        })}

                        {state.Subscription?.CanActivateSimcard &&
                            <div onClick={() => { dialogRef.current.showDialog("activateSimcard"); }}
                                style={{ marginBottom: "36px", display: "flex", flexFlow: "row nowrap", alignItems: "center", justifyContent: "flex-start", width: wDim.desktop ? "47%" : "100%", padding: "10px", borderRadius: "10px", background: theme.palette.boxBackground.main, color: theme.palette.text.primary, cursor: (state.IsPaused || state.IsCancelled) ? 'auto' : 'pointer', }}>
                                <Icon name="simcard" size={48} color={theme.palette.primary.main} />
                                <div style={{ marginLeft: "10px", }}>
                                    <div style={{ fontSize: "14px", fontWeight: 400, }}>{t('selfcare:item.activatesim')}</div>
                                    <div style={{ fontSize: "12px", fontWeight: 200, fontFamily: theme.typography.fontFamily2, }}>{t('selfcare:item.receivedsimcard')}</div>
                                </div>
                                <div style={{ flexGrow: "1", display: "flex", justifyContent: "flex-end", }}>
                                    <SmallButton>{gstate.IsNorway.get() ? "Aktiver" : "Aktivera"}</SmallButton>
                                </div>
                            </div>
                        }

                        <div style={{ fontSize: '20px', fontWeight: 300 }} dangerouslySetInnerHTML={{ __html: gstate.Greeting.get() }} />

                        {(isPostpaid || isData) &&
                        <PotContainer disabled={state.IsPaused} Pots={state.Pots} vm={vm} state={state} localState={localState} setLocalState={setLocalState} handleBuyData={handleOpenPostpaidTopupDialog} handleChangeDataLevel={handleChangeDataLevel} handleBuyEUData={handleOpenPostpaidEUTopupDialog} handleBuySuperboost={handleOpenPostpaidSuperboostDialog} handleDailyData={handleDailyData} handleSubscriptionPause={handleSubscriptionPause} handleBuyDataReload={handleOpenDataReloadDialog}  />}
                        {isPrepaid &&
                            <PrepaidContainer state={state} handleBuyData={handleOpenPrepaidTopupDialog} handleChargePrepaid={handleOpenChargePrepaidDialog} />
                        }
                        {state.ShowFUP &&
                            <Box background border>
                                <BoxContent>
                                    Du har idag förbrukat mer än 20 GB data/surf och behöver därför aktivera tillgång till ytterligare 5 GB.
                                </BoxContent>
                                <div style={{ textAlign: 'center' }}>
                                    <SmallButton id='ActivateFUP' onClick={() => { dialogRef.current.showDialog("FUP"); }}>
                                        Aktivera mer data
                                    </SmallButton>
                                </div>
                            </Box>

                    }
                    {state.ServiceRegistration &&
                        <Box border>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ textAlign: 'left', fontSize: '1rem', fontWeight: 400, fontFamily: theme.typography.fontFamily }}><span dangerouslySetInnerHTML={{ __html: state.ServiceRegistration.Title }} /></div>
                                <div style={{ textAlign: 'left', fontSize: '0.875rem', fontFamily: theme.typography.fontFamily2 }}><span dangerouslySetInnerHTML={{ __html: state.ServiceRegistration.Text }} /></div>
                                <img style={{ marginBottom: '10px', marginTop: '10px' }} src={state.ServiceRegistration.PictureUrl} alt="" />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                    <SmallButton id='register-service' disabled={localState.RegisterBuzy} onClick={() => {
                                        vm.$dispatch({ ActivateService: true }); setLocalState({ RegisterBuzy: true }); dialogRef.current.openUrl(state.ServiceRegistration.Url, true);
                                    }}>
                                        {state.ServiceRegistration.ButtonText}
                                    </SmallButton>
                                </div>
                                {localState.RegisterBuzy &&
                                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                    </div>
                                }
                            </div>
                        </Box>}

                        {state.ShowViaplayOptOut &&
                            <Box border>
                                <div style={{ textAlign: 'left', fontSize: '0.875rem', fontFamily: theme.typography.fontFamily2 }}><span dangerouslySetInnerHTML={{ __html: state.ViaplayOptOutText }} /></div>
                                <img style={{ width: '100px', marginBottom: '10px' }} src={viaplayLogo} alt="Viaplay" />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                    <SmallButton id='register-viaplay' disabled={localState.ViaplayBuzy} onClick={() => { setLocalState({ CancelViaplayDialogOpen: true }); setLocalState({ ViaplayBuzy: true }); }}>
                                        Avsluta Viaplay
                                    </SmallButton>
                                </div>
                                {localState.ViaplayBuzy &&
                                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                    </div>
                                }
                            </Box>
                        }
                        {state.ShowViaplayOffer &&
                            <Box border>
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left', fontSize: '0.875rem', fontFamily: theme.typography.fontFamily2 }}><span dangerouslySetInnerHTML={{ __html: state.ViaplayText }} /></div>
                                    <img style={{ width: '100px', marginBottom: '10px' }} src={viaplayLogo} alt="Viaplay" />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                                        <SmallButton id='register-viaplay' disabled={localState.ViaplayBuzy} onClick={() => { vm.$dispatch({ AcceptViaplayOffer: true }); setLocalState({ ViaplayBuzy: true }); }}>
                                            Acceptera erbjudande
                                        </SmallButton>
                                        <SmallButton variant="outlined" disabled={localState.ViaplayBuzy} onClick={() => { vm.$dispatch({ AcceptViaplayOffer: false }); setLocalState({ ViaplayBuzy: true }); }}>
                                            Nej tack
                                        </SmallButton>
                                    </div>
                                    {localState.ViaplayBuzy &&
                                        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                        </div>
                                    }
                                </div>
                            </Box>
                        }
                        {
                            state.ShowActivateViaplay &&
                            <Box border>
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left', fontSize: '0.875rem', fontFamily: theme.typography.fontFamily2 }}><span dangerouslySetInnerHTML={{ __html: state.ViaplayText }} /></div>
                                    <img style={{ width: '100px', marginBottom: '10px' }} src={viaplayLogo} alt="Viaplay" />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                        <SmallButton id='register-viaplay' disabled={localState.ViaplayBuzy} onClick={() => {
                                            vm.$dispatch({ ActivateViaplay: true }); setLocalState({ ViaplayBuzy: true }); dialogRef.current.openUrl(state.OpenUrl, true);
                                        }}>
                                            {t('selfcare:button.activate-viaplay')}
                                        </SmallButton>
                                        <SmallButton variant="outlined" disabled={localState.ViaplayBuzy} onClick={() => { vm.$dispatch({ ActivateViaplay: false }); setLocalState({ ViaplayBuzy: true }); }}               >
                                            {t('selfcare:button.cancelorder')}l
                                        </SmallButton>
                                    </div>
                                    {localState.ViaplayBuzy &&
                                        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                        </div>
                                    }
                                </div>
                            </Box>
                        }
                        {state.IsSweden && state.PaymentInfoMessage && state.HasCardRegistered &&
                            <Box border>
                                {state.PaymentInfoMessage}
                            </Box>}
                        {state.IsSweden && state.PaymentMessage && state.HasCardRegistered &&
                            <Box border>
                                {state.PaymentMessage}
                                <div style={{ textAlign: 'center', width: '100%' }}>
                                    {(state.HasNonBillogramInvoices && !state.HasBillogramInvoices) ?
                                        <>
                                            <SmallButton style={{ marginTop: '10px' }} onClick={() => vm.$dispatch({ SettleDebt: true })}>Betala {state.PaymentAmount} med registrerat kort</SmallButton>
                                            <SmallButton style={{ marginTop: '10px' }} onClick={() => vm.$dispatch({ PayInvoices: true })}>Betala {state.PaymentAmount} med Swish eller annat kort</SmallButton>
                                        </>
                                        :
                                        <SmallButton style={{ marginTop: '10px' }} onClick={() => { dialogRef.current.navigatePage('/invoice-list', 'fade'); }}>Se fakturor</SmallButton>
                                    }
                                </div>
                            </Box>}
                        {state.IsSweden && state.PaymentMessage && state.InvoicePayment &&
                            <Box border>
                                {state.PaymentMessage}
                                <div style={{ textAlign: 'center', width: '100%' }}>
                                    {(state.HasNonBillogramInvoices && !state.HasBillogramInvoices) ?
                                        <SmallButton style={{ marginTop: '10px' }} onClick={() => vm.$dispatch({ PayInvoices: true })}>Betala {state.PaymentAmount} med Swish eller kort</SmallButton>
                                        :
                                        <SmallButton style={{ marginTop: '10px' }} onClick={() => { dialogRef.current.navigatePage('/invoice-list', 'fade'); }}>Se fakturor</SmallButton>
                                    }
                                </div>
                            </Box>}
                        <SafeMasonryLayout columns={wDim.width > 759 ? 2 : 1}>
                            {gstate.IsNorway.get() && state.IsPostpaid &&
                                <Box background fullWidth linkAction={() => { dialogRef.current.navigatePage('/invoice-list', 'fade'); }}>
                                    <BoxTitle>
                                        Forbruk utover månedspris
                                    </BoxTitle>
                                    <BoxContent>
                                        {!state.UnbilledAmountHTML ?
                                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={30} width={50} />
                                            :
                                            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ fontFamily: theme.typography.fontFamily }} dangerouslySetInnerHTML={{ __html: state.UnbilledAmountHTML }} />
                                        }
                                    </BoxContent>
                                    <SmallButton>Se mer</SmallButton>
                                </Box>}
                            {state.IsPrepaid && 1 === 2 &&
                                <Box background fullWidth linkAction={() => { dialogRef.current.navigatePage('/invoice-list', 'fade'); }}>
                                    <BoxTitle>
                                        Forbruk siste 30 dager
                                    </BoxTitle>
                                    <BoxContent>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ fontFamily: theme.typography.fontFamily }}><div>Väntar på data från Telia</div></Grid>
                                    </BoxContent>
                                    <SmallButton>Se mer</SmallButton>
                                </Box>}
                            {state.IsPrepaid &&
                                <Box background fullWidth linkAction={() => { dialogRef.current.navigatePage('/invoice-list', 'fade'); }}>
                                    <BoxTitle>
                                        Siste påfylling
                                    </BoxTitle>
                                    <BoxContent>
                                        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ fontFamily: theme.typography.fontFamily }}>
                                            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ fontSize: '12px', fontWeight: 600 }}>
                                                <div>Beskrivelse</div>
                                                <div>Dato</div>
                                                <div>Beløp</div>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="space-between" alignItems="center" dangerouslySetInnerHTML={{ __html: state.RefillText }} />
                                        </Grid>
                                    </BoxContent>
                                    <SmallButton>Se mer</SmallButton>
                                </Box>}
                            {state.IsPrepaid &&
                                <Box background fullWidth weight={5} linkAction={() => { dialogRef.current.openUrl('https://www.chilimobil.no/kontantkort/'); }}>
                                    <BoxTitle>
                                        Priser i Norge
                                    </BoxTitle>
                                    <BoxContent>
                                        <Grid container style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', lineHeight: '24px' }}>
                                            <Grid item xs={2}>Tale</Grid>
                                            <Grid item xs={8}>Minuttpris</Grid>
                                            <Grid item xs={2} style={{ textAlign: 'right' }}>0,69</Grid>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={8}>Oppstart</Grid>
                                            <Grid item xs={2} style={{ textAlign: 'right' }}>0,99</Grid>
                                            <Divider style={{ width: '100%', marginBottom: '1px', marginTop: '1px' }} />
                                            <Grid item xs={2}>SMS</Grid>
                                            <Grid item xs={8}>Per stk.</Grid>
                                            <Grid item xs={2} style={{ textAlign: 'right' }}>0,59</Grid>
                                            <Divider style={{ width: '100%', marginBottom: '1px', marginTop: '1px' }} />
                                            <Grid item xs={2}>MMS</Grid>
                                            <Grid item xs={8}>Per stk.</Grid>
                                            <Grid item xs={2} style={{ textAlign: 'right' }}>1,29</Grid>
                                            <Divider style={{ width: '100%', marginBottom: '1px', marginTop: '1px' }} />
                                            <Grid item xs={2}>Data</Grid>
                                            <Grid item xs={8}>Per MB (uten datapakke)</Grid>
                                            <Grid item xs={2} style={{ textAlign: 'right' }}>0,99</Grid>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={8}>Makspris per dag</Grid>
                                            <Grid item xs={2} style={{ textAlign: 'right' }}>15,00</Grid>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={8}>(Maks bruk per dag 300 MB)</Grid>
                                            <Grid item xs={2} style={{ textAlign: 'right' }}></Grid>
                                        </Grid>
                                    </BoxContent>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: '10px' }}>
                                        <SmallButton>Les mer</SmallButton>
                                        <SmallButton onClick={(e) => { e.stopPropagation(); dialogRef.current.openUrl('https://www.chilimobil.no/sporsmal-hjelp/kontantkort-utlandet/'); }}>Priser til og i utland</SmallButton>
                                    </Grid>
                                </Box>}
                            {(state.IsPostpaid || state.IsData || state.UnpaidInvoiceText) &&
                                <Box background fullWidth linkAction={() => { dialogRef.current.navigatePage('/invoice-list', 'fade'); }}>
                                    <BoxTitle>
                                        {gstate.IsNorway.get() ? 'Ubetalte fakturaer' : 'Obetalda fakturor'}
                                    </BoxTitle>
                                    <BoxContent>
                                        {!state.UnpaidInvoiceText ?
                                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={30} width={50} />
                                            :
                                            <Grid container direction="row" justifyContent="space-between" alignItems="center" dangerouslySetInnerHTML={{ __html: state.UnpaidInvoiceText }} />
                                        }
                                    </BoxContent>
                                    <SmallButton>Se mer</SmallButton>
                                </Box>}
                            {(state.IsPrepaid || state.IsPostpaid) &&
                                <Box background fullWidth linkAction={() => { dialogRef.current.navigatePage('/subscriptions/subscription/?subscriptionno=' + state.Subscription?.subscriptionNo, 'glide-left'); }}>
                                    <BoxTitle>
                                        {gstate.IsNorway.get() ? state.IsPrepaid ? "Kontantkort" : "Abonnement" : "Abonnemang"}
                                    </BoxTitle>
                                    <BoxContent>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <Grid item xs={8} style={{ alignSelf: 'self-start' }}>
                                                {gstate.IsNorway.get() ? state.IsPrepaid ? 'Du kan enkelt gjøre endringer på ditt kontantkort her.' : 'Du kan enkelt gjøre endringer på ditt abonnement her.' : 'Du kan enkelt göra ändringar på ditt abonnemang här.'}
                                            </Grid>
                                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                                                {!state.Subscription ?

                                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={30} width={50} />
                                                    :
                                                    <React.Fragment>
                                                        <div style={{ fontFamily: theme.typography.fontFamily, fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, fontSize: '14px' }}>
                                                            {state.Subscription.packageName}
                                                        </div>
                                                        <div>{state.Subscription.msisdn}</div>
                                                    </React.Fragment>}
                                            </Grid>
                                        </Grid>
                                    </BoxContent>
                                    <SmallButton>{gstate.IsNorway.get() ? 'Administrere' : 'Administrera'}</SmallButton>
                                    <SmallButton disabled={state.IsPaused} onClick={(e) => { e.stopPropagation(); dialogRef.current.showDialog('viewPinPuk'); }} style={{ marginLeft: '10px' }}>{state.IsNorway ? 'Pin-kode' : 'Pin-kod'}</SmallButton>
                                </Box>
                            }
                            {!isData && state.Subscription &&
                                <Box disabled={state.IsPaused} background fullWidth linkAction={() => { dialogRef.current.navigatePage('/subscriptions/subscription/?subscriptionno=' + state.Subscription?.subscriptionNo, 'glide-left'); }}>
                                    <BoxTitle>
                                        {gstate.IsNorway.get() ? "Legg til eller endre tjenester" : "Lägg till eller ändra tjänster"}
                                    </BoxTitle>
                                    <BoxContent>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <Grid item xs={8}>
                                                {gstate.IsNorway.get() ? 'Legg til eller endre på produkter og tjenester her.' : 'Lägg till eller ändra på produkter och tjänster här.'}
                                            </Grid>
                                        </Grid>
                                    </BoxContent>
                                    <SmallButton disabled={state.IsPaused}>{gstate.IsNorway.get() ? 'Endre' : 'Ändra'}</SmallButton>
                                </Box>}
                        </SafeMasonryLayout>
                    </TopContainer>
                </>}
            {gstate.CustomerSource.get() === "Order" &&
                <>
                    <HomeOrder />
                </>
            }
        </>
    );
}