import React, { Component } from 'react';
import { Redirect } from 'react-router';
import withStyles from "@material-ui/core/styles/withStyles";
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import Icon from "../components/Selfcare/Common/Icon";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import Slider from "react-slick";
import { Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactLoading from 'react-loading';
import Collapse, { Panel } from 'rc-collapse';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AppBar from '@material-ui/core/AppBar';
import { Transition } from "../extras";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Iframe from 'react-iframe';
import { YouTubeVideo } from '../controls/YouTubeVideo';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Version from "../version";

import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';

// dotNetify
import dotnetify from 'dotnetify';

import auth from "../auth";
import ReactGA from 'react-ga';
import viaplayLogo from '../assets/img/viaplay.png';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import theme from '../theme';

var reactPlugin = new ReactPlugin();

const MySwal = withReactContent(Swal);


const styles = theme => ({
    AppBar: {
        backgroundColor: theme.palette.primary.main,
    },
});

class ChangeService extends Component {
    constructor(props) {
        super(props);
        var GroupId = window.sessionStorage.GroupId;
        var ServiceNo = window.sessionStorage.ServiceNo;
        var fromOrder = sessionStorage.FromOrder;
        var biData, cbState;
        const callbackState = sessionStorage.CallbackStateRet;
        if (callbackState) {
            if (callbackState !== '[object Object]') {
                cbState = JSON.parse(callbackState);
                sessionStorage.removeItem('CallbackStateRet');
            }
            else {

            }
        }
        const bankIdData = sessionStorage.BankIdData;
        if (bankIdData) {
            biData = JSON.parse(bankIdData);
            sessionStorage.removeItem('BankIdData');
        }
        //var GroupId = props.groupId;
        //var ServiceNo = props.serviceNo;
        this.vm = dotnetify.react.connect("ChangeService", this, {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
                FromOrder: fromOrder,
                GroupId,
                ServiceNo,
                Param: props.param,
                HostUrl: window.location.host,
                Path: window.location.pathname,
                BankIdData: biData,
                CallbackState: cbState,
            },
            exceptionHandler: _ => auth.signOut()
        });
        // Set up function to dispatch state to the back-end.
        this.dispatchState = state => this.vm.$dispatch(state);

        // we use this to make the card to appear after the page has been rendered
        this.state = {
            show: false,
            redirect: null,
            toUrl: null,
            Packages: null,
            ChangeTVServiceDialogOpen: false,
            ExternalDialogOpen: false,
            ActivateViaplayDialogOpen: false,
            OrderSent: false,
            OrderError: '',
            OrderOpen: '',
            ErrorField: '',
            ErrorText: '',
            DisableButtons: false,
            buzy: false,
            Type: 'Loading',
            password: '',
            passCode: '',
            EnterPasswordDialogOpen: false,
            EnterPassCodeDialogOpen: false,
            PassCodeText: '',
            CancelTVServiceDialogOpen: false,
            anchorEl: null,
            ButtonText: ' ',
            SendSimCard: false,
            SelectSimType: false,
            SelectedSimType: '',
            termsAccepted: false,
        };
    }
    componentDidMount() {
        //setTimeout(
        //    function () {
        //        this.dispatchState({ Init: { GroupId: window.sessionStorage.GroupId, ServiceNo: window.sessionStorage.ServiceNo } });
        //    }.bind(this),
        //    100
        //);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.RedirectUrl && !this.state.RedirectUrl) {
            this.setState({ redirect: true, toUrl: nextState.RedirectUrl });
        }
        if (nextState.StateName && !this.state.StateName) {
            setTimeout(
                function () {
                    this.setState({ [nextState.StateName]: nextState.StateValue });
                }.bind(this),
                5
            );
        }
        if (nextState.SelfcareDisabled && !this.state.SelfcareDisabled) {
            auth.signOut();
        }
        if (nextState.Type !== "Loading" && this.state.Type === "Loading") {
            setTimeout(
                function () {
                    this.setState({ show: true });
                }.bind(this),
                10
            );
        }
        if (nextState.Message && !this.state.Message) {
            setTimeout(
                function () {
                    MySwal.fire({
                        title: nextState.Message.MessageTitle,
                        html: nextState.Message.MessageText,
                        icon: nextState.Message.MessageType,
                        confirmButtonText: nextState.Message.ConfirmButtonText,
                        confirmButtonColor: theme.palette.primary.main,
                    }).then((result) => {
                        switch (nextState.Message.Action) {
                            case 'close':
                                if (this.props.handleRefresh)
                                    this.props.handleRefresh();
                                if (this.props.handleClose)
                                    this.props.handleClose();

                                if (sessionStorage.returnUrl) {
                                    let url = sessionStorage.returnUrl;
                                    sessionStorage.removeItem('returnUrl');
                                    window.location.assign(url);
                                }

                                break;
                            case 'none':
                                break;
                            case 'reload':
                                window.location.reload();
                                break;
                            case 'redirect':
                                window.location.assign(nextState.Message.ActionParam);
                                break;
                            default:
                                break;
                        }
                    });
                }.bind(this),
                5
            );
        }
        if (nextState.OrderOpen && !this.state.OrderOpen) {
            setTimeout(
                function () {
                    this.setState({ DisableButtons: true });
                }.bind(this),
                100
            );
        }
        if (!nextState.OrderOpen && this.state.OrderOpen) {
            setTimeout(
                function () {
                    this.setState({ DisableButtons: false });
                }.bind(this),
                100
            );
        }
        if (nextState.ErrorField && !this.state.ErrorField) {
            this.setState({ buzy: false });
        }
        if (nextState.BankIdUrl && !this.state.BankIdUrl) {
            window.location.href = nextState.BankIdUrl;
        }
        if (nextState.CallbackPath && !this.state.CallbackPath) {
            sessionStorage.CallbackPath = nextState.CallbackPath;
        }
        if (nextState.CallbackState && !this.state.CallbackState) {
            sessionStorage.CallbackState = nextState.CallbackState;
        }
        if (nextState.CallbackDialog && !this.state.CallbackDialog) {
            sessionStorage.CallbackDialog = nextState.CallbackDialog;
        }
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.vm.$destroy();
    }
    handleConfirmChangeTVServiceDialogClose = () => {
        this.dispatchState({
            PerformChangeTVService: { SelectedServiceNo: this.state.SelectedPackage.ServiceNo, SelectedServiceName: this.state.SelectedPackage.Variant }
        });
        this.setState({ ChangeTVServiceDialogOpen: false, buzy: true });
    }
    handleChange = event => {
        this.setState({ value: event.target.value });
        this.setState({ error: null });
    };
    handleExternalDialogClose = () => {
        this.setState({ ExternalDialogOpen: false });
        this.setState({ redirect: true, toUrl: '/overview' });
    };
    handleActivateViaplayDialogClose = () => {
        this.setState({ ActivateViaplayDialogOpen: false });
        this.setState({ redirect: true, toUrl: '/overview' });
    };

    openExternalDialog = (url) => {
        this.setState({ ExternalDialogOpen: true, ExternalUrl: url });
    };

    handleChangeTVServiceDialogClose = () => {
        this.setState({ ChangeTVServiceDialogOpen: false, SelectedPackage: null, error: null });
    }

    handleEnterPasswordDialogClose = () => {
        this.dispatchState({ EnterPasswordDialogOpen: false });
    }
    handleEnterPassCodeDialogClose = () => {
        this.setState({ EnterPassCodeDialogOpen: false });
        this.setState({ buzy: false })
    }

    handleInputChange = name => event => {
        this.setState({ [name]: event.target.value, PasswordErrorText: '' });
    };
    handleInputChange2 = (name, obj) => event => {
        if (event.target.type === 'checkbox') { // Handle checkboxes
            if (!obj)
                this.setState({ [name]: event.target.checked, ErrorField: '', ErrorText: '' });
            else
                this.setState({ [obj.objId]: { ...obj, [name]: event.target.checked } });//

        } else { //Handle other controls
            if (!obj)
                this.setState({ [name]: event.target.value, ErrorField: '', ErrorText: '' });
            else
                this.setState({ [obj.objId]: { ...obj, [name]: event.target.value } });//
        }
        //Clear any validation errors
        this.setState({ ErrorField: '', ErrorText: '', OrderError: '' });
    }

    hideAlert = () => {
        this.setState({
            alert: null, buzy: false, OrderError: '', OrderSent: false
        });
    }

    handleSelectPackage = selectedPackage => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Sumo package selected'
        });
        if (!selectedPackage.CanChangeTo) { //Order package
            this.dispatchState({ OrderTVService: selectedPackage.ServiceNo });
            this.setState({ buzy: true });
        }
        else { //Change package
            this.setState({ SelectedPackage: selectedPackage, ChangeTVServiceDialogOpen: true });
        }

    };

    handleClick = (event, popoverId) => {
        this.setState({
            openedPopoverId: popoverId,
            anchorEl: event.currentTarget,
        });
    };
    handleCancelTVServiceDialogClose = () => {
        this.setState({ CancelTVServiceDialogOpen: false, CancelService: null, error: null, value: null });
    };

    //handleConfirmCancelTVServiceDialogClose = () => {
    //    if (!this.state.value) {
    //        this.setState({ error: this.props.t('selfcare:error.mustselecttimeforcancellation') });
    //        return;
    //    }
    //    this.setState({ error: null, CancelTVServiceDialogOpen: false });
    //    console.log(this.state.CancelService);
    //    this.dispatchState({ CancelTVService: { ProductNo: this.state.CancelService.ServiceNo, CancelAtEndOfPeriod: this.state.value === "endofperiod" } });
    //};
    cancelTVService = service => {
        ReactGA.event({
            category: 'Navigation',
            action: 'TVService cancel'
        });

        this.dispatchState({ CancelTVService: { ProductNo: service.ServiceNo, CancelAtEndOfPeriod: true } });
    }

    handleClose = () => {
        if (this.props.handleRefresh)
            this.props.handleRefresh();
        if (this.props.handleClose)
            this.props.handleClose();
    };

    handlePopupClose = () => {
        this.setState({
            openedPopoverId: null,
            anchorEl: null,
        });
    };

    navigateTo = url => {
        this.setState({ show: false });
        setTimeout(
            function () {
                this.setState({
                    toUrl: url,
                    redirect: true
                });
            }.bind(this),
            300
        );
    }

    render() {
        var settings = {
            arrows: true,
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 2,
                        infinite: this.state.Options && this.state.Options.length > 2 ? true : false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        infinite: this.state.Options && this.state.Options.length > 1 ? true : false,
                    }
                }
            ],
        };

        const { classes } = this.props;
        const { t } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect ? <Redirect to={this.state.toUrl} /> : null}
                <Dialog
                    open={this.state.EnterPasswordDialogOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                            this.setState({ EnterPasswordDialogOpen: false, buzy: false });
                        }
                    }}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <Icon name="edit" size={36} color={theme.palette.primary.main} />
                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{t('selfcare:dialog.codesetbylegalowner')}</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.PasswordText}<br /><br />
                        <TextField
                            style={{ width: '100%' }}
                            id="password"
                            label={t('selfcare:label.code')}
                            type="password"
                            value={this.state.password}
                            onChange={this.handleInputChange('password')}
                            margin="normal"
                        />
                        {this.state.PasswordErrorText && <div style={{ width: '100%', padding: '5px', backgroundColor: theme.palette.primary.main, color: 'white', fontSize: '12px' }}>{this.state.PasswordErrorText}</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton onClick={() => { this.dispatchState({ SetPassword: { Password: this.state.password, Add: true, SimType: this.state.SelectedSimType } }); }}>
                            {t('selfcare:button.proceeduc')}
                        </LargeButton>
                        <LargeButton color="secondary" variant="text" onClick={() => { this.setState({ EnterPasswordDialogOpen: false, buzy: false }); }}>
                            {t('selfcare:button.canceluc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.ActivateViaplayDialogOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                            this.handleActivateViaplayDialogClose();
                        }
                    }}
                    TransitionComponent={Transition}
                    transitionDuration={500}
                    style={{ zIndex: 3000 }}
                >
                    <DialogContent style={{ justifyContent: 'center' }}>
                        <div style={{ fontSize: '1.5rem' }}>
                            {this.state.ViaplayTitle}
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            Du behöver nu registrera dig hos Viaplay. Vi öppnar registreringen i ett nytt fönster.
                        </div>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <LargeButton href={this.state.OpenUrl} target="_blank" onClick={() => { this.handleActivateViaplayDialogClose() }}>Till Viaplay</LargeButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen
                    open={this.state.ExternalDialogOpen}
                    onClose={this.handleExternalDialogClose}
                    TransitionComponent={Transition}
                    transitionDuration={500}
                    style={{ zIndex: 3000 }}
                >
                    <AppBar className={classes.AppBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleExternalDialogClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1 }}>

                            </Typography>
                            <LargeButton autoFocus onClick={this.handleExternalDialogClose}>
                                Stäng
                            </LargeButton>
                        </Toolbar>
                    </AppBar>
                    <div style={{ height: '64px' }} />
                    <Iframe url={this.state.ExternalUrl}
                        id="external-frame"
                        width="100%"
                        height="100%"
                        display="initial"
                        position="relative"
                        border="none"
                    />
                </Dialog>
                <Dialog
                    open={this.state.EnterPassCodeDialogOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                            this.handleEnterPassCodeDialogClose();
                        }
                    }}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <Icon name="edit" size={36} color={theme.palette.primary.main} />
                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>Tast inn kode fra SMS</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.PassCodeText}<br />
                        <TextField
                            autoFocus
                            style={{ width: '100%' }}
                            id="passcode"
                            label={t('selfcare:label.code')}
                            type="tel"
                            value={this.state.passCode}
                            onChange={this.handleInputChange('passCode')}
                            margin="normal"
                        />
                        {this.state.PassCodeErrorText && <div style={{ width: '100%', padding: '5px', backgroundColor: theme.palette.primary.main, color: 'white', fontSize: '12px' }}>{this.state.PassCodeErrorText}</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton onClick={() => { this.dispatchState({ SetPassCode: { PassCode: this.state.passCode, Add: true, SimType: this.state.SelectedSimType } }); }}>
                            {t('selfcare:button.send-order-uc')}
                        </LargeButton>
                        <LargeButton color="secondary" variant="text" onClick={this.handleEnterPassCodeDialogClose}>
                            {t('selfcare:button.cancel-uc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.ChangeTVServiceDialogOpen}
                    onClose={this.handleChangeTVServiceDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{t('selfcare:title.change-tv-package', { packageName: this.state.GroupName })}</DialogTitle>
                    <DialogContent>
                        {t('selfcare:dialog.wishtochangepackage', { 0: this.state.CurrentService ? this.state.CurrentService.Variant : null, 1: this.state.SelectedPackage ? this.state.SelectedPackage.Variant : null })}
                        <br /><br />
                        {this.state.SelectedPackage && this.state.SelectedPackage.IsDowngrade ? t('selfcare:dialog.changeeffectiveafterperiod') : null}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton onClick={this.handleConfirmChangeTVServiceDialogClose} >
                            {t('selfcare:button.confirmuc')}
                        </LargeButton>
                        <LargeButton color="secondary" variant="text" onClick={this.handleChangeTVServiceDialogClose}>
                            {t('selfcare:button.canceluc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                {this.state.Type === 'Loading' &&
                    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                        <Grid item>
                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                        </Grid>
                    </Grid>}
                {this.state.Type !== 'Loading' &&
                    <div className="noselect">
                        {this.state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: this.state.AdministratedMsisdn }) }} /></div>}
                        <div style={{ backgroundColor: theme.palette.primary.main, color: "#FFF", padding: '20px' }} className='white-links'>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={10}>
                                    <Icon name={this.state.Image} size={48} color="#FFFFFF" />
                                    <div style={{ fontSize: '24px' }}>
                                        {this.state.Title}
                                    </div>
                                    <div style={{ fontSize: '18px', fontWeight: 700 }}>
                                        {this.state.InitialFee &&
                                            <div>{t('selfcare:item.initialfee')}: {this.state.InitialFee}</div>}
                                        {this.state.MonthlyFee &&
                                            <div>{t('selfcare:item.permonth')}: {this.state.MonthlyFee}</div>}
                                    </div>
                                    {this.state.Description && <div style={{ paddingTop: '10px', fontSize: '14px', lineHeight: '16px', fontFamily: theme.typography.fontFamily2 }} dangerouslySetInnerHTML={{ __html: this.state.Description }} />}
                                    {this.state.LongDescription && <Collapse accordion={true} style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>
                                        <Panel header={t('selfcare:label.readmore')}>
                                            <span dangerouslySetInnerHTML={{ __html: this.state.LongDescription }} />
                                        </Panel>
                                    </Collapse>}
                                    {this.state.VideoUrl &&
                                        <YouTubeVideo VideoUrl={this.state.VideoUrl} />
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            {!this.state.CanChangeServices && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center', borderBottom: '1px grey solid' }}>{t('selfcare:info.howtochangeservices')}</div>}
                            <div>
                                {(this.state.Type === 'Service' || this.state.Type === 'Group') &&
                                    <div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={12} sm={10}>
                                                <Grid container justifyContent="center" direction="column">
                                                    {this.state.SelectSimType &&
                                                        <React.Fragment>
                                                            <FormControl error={this.state.ErrorField === 'SelectedSimType'}>
                                                                <FormGroup>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Radio
                                                                                    color="primary"
                                                                                    checked={this.state.SelectedSimType === 'Standard'}
                                                                                    onChange={() => { this.setState({ SelectedSimType: 'Standard', SendSimCard: true, ErrorField: '', ErrorText: '' }); }}
                                                                                    value='Standard'
                                                                                    disabled={this.state.DisableButtons || !this.state.CanChangeServices}
                                                                                />
                                                                            }
                                                                            label={t('selfcare:label.physical-sim')}
                                                                            labelPlacement="end"
                                                                        />
                                                                        <div>
                                                                            <IconButton
                                                                                aria-owns="Standard"
                                                                                aria-haspopup="true"
                                                                                aria-label={t('selfcare:label.help')}
                                                                                onClick={(e) => { this.handleClick(e, "Standard"); }}
                                                                            >
                                                                                <Icon name="information" size={24} color={theme.palette.primary.main} />
                                                                            </IconButton>
                                                                            <Popover
                                                                                id="Standard"
                                                                                open={this.state.openedPopoverId === "Standard"}
                                                                                anchorEl={anchorEl}
                                                                                onClose={this.handlePopupClose}
                                                                                anchorOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'right',
                                                                                }}
                                                                            >                                                                                    <>
                                                                                    <div style={{ textAlign: 'right' }}>
                                                                                        <IconButton role="button" aria-label={this.state.IsSweden ? 'Stäng' : 'Lukk'} size="small" onClick={(e) => { this.handlePopupClose(); }}>
                                                                                            <Icon name="close" size={24} color={theme.palette.primary.main} />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                    <div style={{ padding: '10px', maxWidth: '400px', fontSize: '12px' }}>
                                                                                        <span dangerouslySetInnerHTML={{ __html: t('selfcare:info.after-order-sim-is-sent', { 0: this.state.SimAddress }) }} />
                                                                                    </div>
                                                                                </>
                                                                            </Popover>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Radio
                                                                                    color="primary"
                                                                                    checked={this.state.SelectedSimType === 'eSim'}
                                                                                    onChange={() => { this.setState({ SelectedSimType: 'eSim', SendSimCard: true, ErrorField: '', ErrorText: '' }); }}
                                                                                    value='eSim'
                                                                                    disabled={this.state.DisableButtons || !this.state.CanChangeServices}
                                                                                />
                                                                            }
                                                                            label={t('selfcare:label.esim')}
                                                                            labelPlacement="end"
                                                                        />
                                                                        <div>
                                                                            <IconButton
                                                                                aria-owns="eSim"
                                                                                aria-haspopup="true"
                                                                                aria-label={t('selfcare:label.help')}
                                                                                onClick={(e) => { this.handleClick(e, "eSim"); }}
                                                                            >
                                                                                <Icon name="information" size={24} color={theme.palette.primary.main} />
                                                                            </IconButton>
                                                                            <Popover
                                                                                id="eSim"
                                                                                open={this.state.openedPopoverId === "eSim"}
                                                                                anchorEl={anchorEl}
                                                                                onClose={this.handlePopupClose}
                                                                                anchorOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'right',
                                                                                }}
                                                                            >
                                                                                <>
                                                                                    <div style={{ textAlign: 'right' }}>
                                                                                        <IconButton role="button" aria-label={this.state.IsSweden ? 'Stäng' : 'Lukk'} size="small" onClick={(e) => { this.handlePopupClose(); }}>
                                                                                            <Icon name="close" size={24} color={theme.palette.primary.main} />
                                                                                        </IconButton>
                                                                                    </div>

                                                                                    <div style={{ padding: '10px', maxWidth: '400px', fontSize: '12px' }}>
                                                                                        <span dangerouslySetInnerHTML={{ __html: t('selfcare:info.esim', { 0: this.state.SimEmail }) }} />
                                                                                    </div>
                                                                                </>
                                                                            </Popover>
                                                                        </div>
                                                                    </div>
                                                                </FormGroup>
                                                                <FormHelperText>{this.state.ErrorField === 'SelectedSimType' && this.state.ErrorText}</FormHelperText>
                                                            </FormControl>
                                                        </React.Fragment>
                                                    }
                                                    {this.state.SendSimCard && this.state.SelectedSimType === 'Standard' &&
                                                        <Grid item>
                                                            <p><b>{t('selfcare:item.simsentto')}:</b></p>
                                                            <span dangerouslySetInnerHTML={{ __html: this.state.SimAddress }} />
                                                            {/*<a href="/change-address" style={{ marginLeft: '30px' }}><BorderColorIcon style={{ color: '#da2f63', marginBottom: '-6px', width: '20px', marginRight: '6px' }} />{t('selfcare:phrase.change')}</a>*/}
                                                        </Grid>}
                                                    {this.state.SendSimCard && this.state.SelectedSimType === 'eSim' &&
                                                        <Grid item>
                                                            <p><b>{t('selfcare:info.qr-esim-sent')}:</b></p>
                                                            <span dangerouslySetInnerHTML={{ __html: this.state.SimEmail }} />
                                                        </Grid>}
                                                    <Grid item>
                                                        {this.state.GroupHeader &&
                                                            <span dangerouslySetInnerHTML={{ __html: this.state.GroupHeader }} />
                                                        }
                                                        <FormGroup>
                                                            {this.state.GroupType === 'Single' && this.state.ServiceOptions && this.state.ServiceOptions.map((option) => {
                                                                return (
                                                                    <FormControlLabel key={option.ServiceNo}
                                                                        control={
                                                                            <Radio
                                                                                color="primary"
                                                                                checked={option.Checked}
                                                                                onChange={() => { this.dispatchState({ SelectionChanged: option.ServiceNo }); this.setState({ ErrorField: '', ErrorText: '' }); }}
                                                                                value={option.ServiceNo}
                                                                                disabled={this.state.DisableButtons || !this.state.CanChangeServices}
                                                                            />
                                                                        }
                                                                        label={option.Name}
                                                                        labelPlacement="end"
                                                                    />
                                                                );
                                                            })}
                                                            {this.state.GroupType === 'Multi' && this.state.ServiceOptions && this.state.ServiceOptions.map((option) => {
                                                                return (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }} key={option.ServiceNo}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={option.Checked}
                                                                                    onChange={() => { this.dispatchState({ SelectionChanged: option.ServiceNo }) }}
                                                                                    value={option.ServiceNo}
                                                                                    disabled={this.state.DisableButtons || !this.state.CanChangeServices}
                                                                                />
                                                                            }
                                                                            label={<div style={{ display: 'flex', alignItems: 'center', marginRight: '-15px', fontSize: '12px' }}>
                                                                                {option.Name}
                                                                            </div>}
                                                                            labelPlacement="end"
                                                                        />
                                                                        {option.HelpText &&
                                                                            <div>
                                                                                <IconButton
                                                                                    aria-owns={option.ServiceNo}
                                                                                    aria-haspopup="true"
                                                                                    aria-label={t('selfcare:label.help')}
                                                                                    onClick={(e) => { this.handleClick(e, option.ServiceNo); }}
                                                                                >
                                                                                    <Icon name="information" size={24} color={theme.palette.primary.main} />
                                                                                </IconButton>
                                                                                <Popover
                                                                                    id={option.ServiceNo}
                                                                                    open={this.state.openedPopoverId === option.ServiceNo}
                                                                                    anchorEl={anchorEl}
                                                                                    onClose={this.handlePopupClose}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'center',
                                                                                        horizontal: 'left',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical: 'center',
                                                                                        horizontal: 'right',
                                                                                    }}
                                                                                >
                                                                                    <>
                                                                                        <div style={{ textAlign: 'right' }}>
                                                                                            <IconButton role="button" aria-label={this.state.IsSweden ? 'Stäng' : 'Lukk'} size="small" onClick={(e) => { this.handlePopupClose(); }}>
                                                                                                <Icon name="close" size={24} color={theme.palette.primary.main} />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                        <div style={{ padding: '10px', maxWidth: '400px', fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: option.HelpText }} />
                                                                                    </>
                                                                                </Popover>
                                                                            </div>}
                                                                    </div>
                                                                );
                                                            })}
                                                        </FormGroup>
                                                    </Grid>
                                                    {this.state.OrderOpen &&
                                                        <Grid item>
                                                            <div style={{ width: '100%' }}>
                                                                {t('selfcare:info.orderprocessed')}
                                                            </div>
                                                        </Grid>
                                                    }
                                                    {this.state.IsCostControl &&
                                                        <Grid item>
                                                            <div style={{ width: '100%' }}>
                                                                <div><b>Tjänsten Kostnadskontroll är {this.state.TurnOn ? "just nu inte aktiverad" : "aktiverad"} på ditt abonnemang</b></div>
                                                                <div style={{ marginTop: '10px' }}>
                                                                    {this.state.TurnOn ? "När tjänsten Kostnadskontroll aktiveras betyder det att tex utlandssamtal, betalsamtal och andra betaltjänster inte kan nyttjas. Det betyder också att inga rörliga kostnader kan komma att belasta ditt abonnemang förutom den fasta månadsavgiften. Kostnadskontroll, helt enkelt." : "Om du stänger av tjänsten Kostnadskontroll så betyder det att du kan använda tjänster såsom utlandssamtal, betalsamtal och andra betaltjänster, vilka kan medföra rörliga och extra kostnader. Dessa kostnader kommer att belasta ditt abonnemang förutom den fasta månadsavgiften."}<br /><br />{this.state.PerformCreditCheck ? "Om du väljer att stänga av tjänsten Kostnadskontroll kommer en kreditkontroll att genomföras." : ""}
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    }
                                                    {this.state.ShowSecretNumberOrder &&
                                                        <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                                                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ maxWidth: '400px', marginTop: '20px' }}>
                                                                <Grid item style={{ marginRight: '10px' }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#9D9D9D" viewBox="0 0 16 16">
                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                    </svg>
                                                                </Grid>
                                                                <Grid item style={{ marginBottom: '26px', maxWidth: '90%', fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>
                                                                    Vis nummeret ditt som «skjult nummer» når du ringer andre vanlige nummer i Norge.
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ maxWidth: '400px' }}>
                                                                <Grid item style={{ marginRight: '10px' }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#9D9D9D" viewBox="0 0 16 16">
                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                    </svg>
                                                                </Grid>
                                                                <Grid item style={{ marginBottom: '26px', maxWidth: '90%', fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>
                                                                    Nummer, navn og adresse unntas fra opplysningstjenester og all annen utlevering til allmenheten.
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ maxWidth: '400px' }}>
                                                                <Grid item style={{ marginRight: '10px' }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#9D9D9D" viewBox="0 0 16 16">
                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                    </svg>
                                                                </Grid>
                                                                <Grid item style={{ marginBottom: '26px', maxWidth: '90%', fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>
                                                                    BankID er påkrevd for å gjøre endringer tilknyttet denne tjenesten og din adresse.
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>}
                                                    <Grid item>
                                                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                                            <div style={{ paddingTop: '10px' }}>
                                                                {this.state.NeedAccept &&
                                                                    <FormControlLabel
                                                                        style={{ maxWidth: '500px', display: 'table', marginBottom: '10px' }}
                                                                        control={
                                                                            <Checkbox
                                                                                id="termsAccepted"
                                                                                disabled={this.state.Disabled || this.state.buzy || this.state.OrderOpen}
                                                                                checked={this.state.termsAccepted}
                                                                                onChange={this.handleInputChange2('termsAccepted')}
                                                                                color="primary"
                                                                                style={{ display: 'table-cell', verticalAlign: 'top' }}
                                                                            />
                                                                        }
                                                                        label=
                                                                        {
                                                                            <div style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>
                                                                                <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: this.state.AcceptHTML }} />
                                                                            </div>
                                                                        }
                                                                    />
                                                                }
                                                                {this.state.ErrorField === 'termsAccepted' && <div style={{ color: theme.palette.error.main, marginTop: '5px' }}>{this.state.ErrorText}</div>}
                                                            </div>
                                                            {this.state.ShowSecretNumberOrder &&
                                                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                                    <div>Må bestilles med BankID av:</div>
                                                                    <div><b>{this.state.BankIdUserFullname}</b></div>
                                                                </div>
                                                            }
                                                            {this.state.ShowSecretNumberCancel &&
                                                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                                    <div>Må avbestilles med BankID av:</div>
                                                                    <div><b>{this.state.BankIdUserFullname}</b></div>
                                                                </div>
                                                            }
                                                            {this.state.CancelNotification && <div style={{ color: 'red' }}>
                                                                <span dangerouslySetInnerHTML={{ __html: this.state.CancelNotification }} />
                                                            </div>}
                                                            <div style={{ marginTop: '10px' }}>
                                                                <LargeButton onClick={() => { this.dispatchState({ PerformChange: { Add: true, SimType: this.state.SelectedSimType, TermsAccepted: this.state.termsAccepted } }); this.setState({ buzy: true }); }} disabled={!this.state.CanSave || this.state.DisableButtons || this.state.buzy || !this.state.CanChangeServices || this.state.CancelNotification} style={{ marginRight: '10px' }} > {this.state.ButtonText} </LargeButton>
                                                                <LargeButton color="secondary" variant="text" id='cancel' disabled={this.state.buzy} onClick={() => { this.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                                            </div>
                                                            <div style={{ paddingTop: '20px' }}>
                                                                {this.state.CanCancelService && <LargeButton onClick={() => { this.dispatchState({ PerformChange: { Add: false } }); this.setState({ buzy: true }); }} disabled={this.state.DisableButtons || this.state.buzy || !this.state.CanChangeServices} style={{ marginRight: '10px' }} >{t('selfcare:button.cancelservice')}</LargeButton>}
                                                            </div>
                                                            {this.state.buzy &&
                                                                <Grid item>
                                                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>}
                                {this.state.Type === 'TV' &&
                                    <div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                                        <form>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item xs={10} >
                                                    <div>
                                                        <Slider {...settings}>
                                                            {this.state.Options ? this.state.Options.map((pack) => {
                                                                return (
                                                                    <Card raised key={pack.ServiceNo}>
                                                                        <CardBody>
                                                                            <Grid container justifyContent="center">
                                                                                <Grid ktem>
                                                                                    <div style={{ fontSize: '22px', width: '240px', height: '70px', textAlign: 'center' }} className={classes.cardCategory}>{pack.Variant}</div>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <div style={{ height: '180px', textAlign: 'left' }} className={classes.cardDescription} dangerouslySetInnerHTML={{ __html: pack.Description }} />
                                                                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                                                                <Grid item>
                                                                                    <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                                                                                        {pack.Fee}
                                                                                    </h3>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div style={{ height: '20px', color: 'red', textAlign: 'center' }}>
                                                                                        {pack.Message}
                                                                                    </div>
                                                                                    <div style={{ textAlign: 'center' }}>
                                                                                        {pack.Ordered ? <SmallButton disabled>{t('selfcare:button.ordered')}</SmallButton> : !pack.Current ? <SmallButton disabled={this.state.DisableButtons || this.state.buzy || !this.state.CanChangeServices} onClick={() => this.handleSelectPackage(pack)}>{pack.CanChangeTo ? t('selfcare:button.change-to') : t('selfcare:button.order')}</SmallButton> : <SmallButton disabled>{t('selfcare:button.current')}</SmallButton>}
                                                                                        {pack.CanCancel && <SmallButton onClick={() => { this.cancelTVService(pack); }} style={{ marginLeft: '10px' }}>{t('selfcare:button.cancelorder')}</SmallButton>}
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardBody>
                                                                    </Card>
                                                                )
                                                            }) : null}
                                                        </Slider>
                                                        <Grid container justifyContent="center">
                                                            <Grid item>
                                                                <div style={{ paddingTop: '40px' }}>
                                                                    {this.state.buzy && <CircularProgress size={48} style={{
                                                                        color: '#C2185B', position: 'absolute',
                                                                        top: '20%',
                                                                        left: '50%',
                                                                        marginTop: -12,
                                                                        marginLeft: -12,
                                                                    }} />}
                                                                    {this.state.OrderOpen && <div>{t('selfcare:info.orderprocessed')}</div>}
                                                                    <LargeButton id='back' disabled={this.state.buzy} onClick={() => { this.handleClose(); }}><Icon name="back" size={32} color="#FFFFFF" />{t('selfcare:button.back')}</LargeButton>
                                                                </div>
                                                            </Grid>
                                                        </Grid>

                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </div>}
                                {this.state.Type === "Activate" &&
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        {this.state.ViaplayPreOfferText ?
                                            <div style={{ padding: '20px' }}>{this.state.ViaplayPreOfferText}</div>
                                            : <React.Fragment>
                                                {this.state.ShowViaplayOffer &&
                                                    <div style={{ maxWidth: '360px', margin: '20px 10px', borderRadius: '10px', border: '1px solid #555', textAlign: 'center', backgroundColor: '#FFF', boxShadow: '4px 9px 22px 0 rgba(0, 0, 0, 0.13)', WebkitBoxShadow: '4px 9px 22px 0 rgba(0, 0, 0, 0.13)', MozBoxShadow: '4px 9px 22px 0 rgba(0, 0, 0, 0.13)' }}>
                                                        <div style={{ padding: '10px', textAlign: 'center' }}>
                                                            <p style={{ textAlign: 'left', fontSize: '0.875rem' }}><span dangerouslySetInnerHTML={{ __html: this.state.ViaplayText }} /></p>

                                                            <img style={{ width: '100px', marginBottom: '10px' }} src={viaplayLogo} alt="Viaplay" />
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                                                                <SmallButton id='register-viaplay' disabled={this.state.ViaplayBuzy} onClick={() => {
                                                                    this.dispatchState({ AcceptViaplayOffer: true }); this.setState({ ViaplayBuzy: true });
                                                                }}
                                                                >
                                                                    Acceptera erbjudande
                                                                </SmallButton>
                                                                <SmallButton variant="outlined" disabled={this.state.ViaplayBuzy} onClick={() => {
                                                                    this.dispatchState({ AcceptViaplayOffer: false }); this.setState({ ViaplayBuzy: true });
                                                                }}
                                                                >
                                                                    Nej tack
                                                                </SmallButton>
                                                            </div>
                                                            {this.state.ViaplayBuzy &&
                                                                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                                                </div>}
                                                        </div>
                                                    </div>}
                                                {this.state.ShowActivateViaplay &&
                                                    <div style={{ maxWidth: '360px', margin: '20px 10px', borderRadius: '10px', border: '1px solid #555', textAlign: 'center', backgroundColor: '#FFF', boxShadow: '4px 9px 22px 0 rgba(0, 0, 0, 0.13)', WebkitBoxShadow: '4px 9px 22px 0 rgba(0, 0, 0, 0.13)', MozBoxShadow: '4px 9px 22px 0 rgba(0, 0, 0, 0.13)' }}>
                                                        <div style={{ padding: '10px', textAlign: 'center' }}>
                                                            <p style={{ textAlign: 'left', fontSize: '0.875rem' }}><span dangerouslySetInnerHTML={{ __html: this.state.ViaplayText }} /></p>
                                                            <img style={{ width: '100px', marginBottom: '10px' }} src={viaplayLogo} alt="Viaplay" />
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                <SmallButton href={this.state.OpenUrl} target="_blank" style={{ marginRight: '5px' }} id='register-viaplay' disabled={this.state.ViaplayBuzy} onClick={() => {
                                                                    this.dispatchState({ ActivateViaplay: true }); this.setState({ ViaplayBuzy: true });
                                                                }}
                                                                >
                                                                    {t('selfcare:button.activate-viaplay')}
                                                                </SmallButton>
                                                                <SmallButton variant="outlined" size="sm" disabled={this.state.ViaplayBuzy} onClick={() => {
                                                                    this.dispatchState({ ActivateViaplay: false }); this.setState({ ViaplayBuzy: true });
                                                                }}
                                                                >
                                                                    {t('selfcare:button.cancelorder')}l
                                                                </SmallButton>
                                                            </div>
                                                            {this.state.ViaplayBuzy &&
                                                                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                                                </div>}
                                                        </div>
                                                    </div>}
                                            </React.Fragment>}
                                    </div>
                                }
                                {this.state.Type === "Activate" &&
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <LargeButton id='back' disabled={this.state.buzy} onClick={() => { this.handleClose(); }}><Icon name="back" size={32} color="#FFFFFF" />{t('selfcare:button.back')}</LargeButton>
                                    </div>}
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}
ChangeService.propTypes = {
};

export default compose(
    withStyles(styles),
    withTranslation(),
)(withAITracking(reactPlugin, ChangeService));

