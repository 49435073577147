import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { AppStart } from './views/AppStart';
import NewPassword from './views/NewPassword';
import SetNewPassword from './views/SetNewPassword';
import ReactGA from 'react-ga';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import theme from './theme'
import CssBaseline from "@material-ui/core/CssBaseline";
import Support from './pages/Support';
import ConfirmOwnerChange from './views/ConfirmOwnerChange';
import InvoiceList from './pages/InvoiceList';
import Subscriptions from './pages/Subscriptions';
import Subscription from './pages/Subscription';
import Home from './pages/Home';
import Shopping from './pages/Shopping';
import Profile from './pages/Profile';
import Invoice from './pages/Invoice';
import Callback from './pages/Callback';
import InsCallback from './pages/InsCallback';
import AuthCallback from './pages/AuthCallback';
import SelectSimType from './pages/SelectSimType';
import HiddenNumber from './pages/HiddenNumber';
import Messages from './pages/Messages';
import MenuLinks from './controls/MenuLinks';
import MenuDrawer from './controls/MenuDrawer';
import MenuBar from './controls/MenuBar';

//import { BrowserRouter, Switch, Route } from 'react-router-dom';
import "react-tiger-transition/styles/main.min.css";

import {
    Navigation,
    Route,
    scale,
    glideIn,
    glideOut,
    fade,
    fold,
    drop,
    flip,
    glide,
} from "react-tiger-transition";

import { Router } from 'react-router-dom';
//inject styles
drop({
    name: 'drop-left',
    direction: 'left',
    enter: {
        duration: 600,
        easing: 'ease',
        opacity: 1,
        zIndex: 2,
        delay: 0
    },
    exit: {
        duration: 600,
        easing: 'ease',
        opacity: 0.3,
        zIndex: 1,
        scale: 0.6,
        delay: 0
    }
});
drop({
    name: 'drop-right',
    direction: 'right',
    enter: {
        duration: 600,
        easing: 'ease',
        opacity: 1,
        zIndex: 2,
        delay: 0
    },
    exit: {
        duration: 600,
        easing: 'ease',
        opacity: 0.3,
        zIndex: 1,
        scale: 0.6,
        delay: 0
    }
});
fold({
    name: 'fold-left',
    direction: 'left',
    enter: {
        duration: 600,
        easing: 'ease',
        opacity: 0.3,
        zIndex: 2,
        delay: 0
    },
    exit: {
        duration: 600,
        easing: 'ease',
        opacity: 0.3,
        zIndex: 1,
        angle: 90,
        delay: 0
    }
});
fold({
    name: 'fold-right',
    direction: 'right',
    enter: {
        duration: 600,
        easing: 'ease',
        opacity: 0.3,
        zIndex: 2,
        delay: 0
    },
    exit: {
        duration: 600,
        easing: 'ease',
        opacity: 0.3,
        zIndex: 1,
        angle: 90,
        delay: 0
    }
});
fade({
    name: 'fade',
    duration: 200,
    enter: {
        easing: 'easeOutQuad',
        opacity: 0.0,
        zIndex: 1,
        depth: 1000,
        delay: 100
    },
    exit: {
        easing: 'easeInQuad',
        opacity: 0.0,
        zIndex: 2,
        depth: 1000
    }
});
scale({
    name: "scale",
    scale: 1.2,
    exit: {
        delay: 100
    }
});
glide({
    name: 'glide-left',
    direction: 'left',
    enter: {
        duration: 300,
        easing: 'easeOutQuad',
        opacity: 1,
        zIndex: 2,
        scale: 1,
        delay: 0
    },
    exit: {
        duration: 300,
        easing: 'easeOutQuad',
        opacity: 0.3,
        zIndex: 1,
        scale: 1,
        delay: 0
    }
});
glide({
    name: 'glide-right',
    direction: 'right',
    enter: {
        duration: 300,
        easing: 'easeOutQuad',
        opacity: 1,
        zIndex: 2,
        scale: 1,
        delay: 0
    },
    exit: {
        duration: 300,
        easing: 'easeOutQuad',
        opacity: 0.3,
        zIndex: 1,
        scale: 1,
        delay: 0
    }
});
glideIn({
    name: "glideIn-left",
    direction: "left",
    enter: {
        duration: 300,
        easing: 'ease',
        opacity: 1,
        zIndex: 2,
        delay: 400
    },
    exit: {
        duration: 300,
        easing: 'ease-in',
        opacity: 0,
        zIndex: 1,
        depth: 200,
        angle: 15
    }
});
glideIn({
    name: "glideIn-right",
    direction: "right",
    enter: {
        duration: 300,
        easing: 'ease',
        opacity: 1,
        zIndex: 2,
        delay: 400
    },
    exit: {
        duration: 300,
        easing: 'ease-in',
        opacity: 0,
        zIndex: 1,
        depth: 200,
        angle: 15
    }
});
glideIn({
    name: "glideIn-top",
    direction: "top"
});
glideOut({
    name: "glideOut-bottom",
    direction: "bottom"
});
glideOut({
    name: "glideOut-right",
    direction: "right"
});
flip({
    name: "flip-right",
    direction: "right",
    duration: 300,
    enter: {
        easing: 'easeOutQuad',
        opacity: 0.2,
        zIndex: 1,
        depth: 1000,
        delay: 300
    },
    exit: {
        easing: 'easeInQuad',
        opacity: 0.2,
        zIndex: 2,
        depth: 1000
    }
});
flip({
    name: "flip-left",
    direction: 'left',
    duration: 300,
    enter: {
        easing: 'easeOutQuad',
        opacity: 0.2,
        zIndex: 1,
        depth: 1000,
        delay: 300
    },
    exit: {
        easing: 'easeInQuad',
        opacity: 0.2,
        zIndex: 2,
        depth: 1000
    }
});
const history = createBrowserHistory();
var instKey;
var analyticsKey;
var reactPlugin = new ReactPlugin();

if (window.location.hostname.includes("sesprint")) { //Sprint Sweden
    instKey = 'db30de88-772b-4eeb-a726-db153c6a3cf5';
    analyticsKey = 'UA-121924115-9';
} else if (window.location.hostname.includes("sprint")) { //Sprint Norway
    instKey = 'f29c3110-9a67-4472-ab72-8f447521faa3';
    analyticsKey = 'UA-121924115-4';
} else if (window.location.hostname.includes("sedev")) { //Dev Sweden
    instKey = '7765a4c2-30a2-40fb-ac89-1f5393b591ec';
    analyticsKey = 'UA-121924115-7';
} else if (window.location.hostname.includes("dev") || window.location.hostname.includes("localhost")) { //Dev norway and localhost
    instKey = '314beb69-3980-4c4c-bf38-45ade7c7fc43';
    analyticsKey = 'UA-121924115-1';
} else if (window.location.hostname.includes("selfcare30no")) { // Selfcare 3.0 no
    instKey = 'a6d66cfd-9075-4062-828e-e58edffc2503';
    analyticsKey = 'UA-121924115-1';
} else if (window.location.hostname.includes("selfcare30se")) { // Selfcare 3.0 se
    instKey = 'fe721993-0403-4b46-b3e3-38d01a935436';
    analyticsKey = 'UA-121924115-1';
} else if (window.location.hostname.includes("se-test")) { //Test Sweden
    instKey = 'eacd6062-f7ef-450d-9811-4cf1219c9e3d';
    analyticsKey = 'UA-121924115-8';
} else if (window.location.hostname.includes("test")) { //Test Norway
    instKey = '8c018a25-7e81-41f2-9f92-c30248edb6ca';
    analyticsKey = 'UA-121924115-2';
} else if (window.location.hostname.includes("mittchili")) { //Prod Sweden
    instKey = 'eacd6062-f7ef-450d-9811-4cf1219c9e3d';
    analyticsKey = 'UA-121924115-6';
} else { //Prod Norway
    instKey = "0a2b015d-c0a7-40f5-9d3e-6c8b998f218f";
    analyticsKey = 'UA-121924115-3';
}

ReactGA.initialize(analyticsKey);

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: instKey,
        isCookieUseDisabled: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: history }
        }
    },
});
appInsights.loadAppInsights();
window.appInsights = appInsights;
window.ReactGA = ReactGA;

history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

export default class App extends Component {
    displayName = App.name;
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const hideInsteadOfUnmount = {
            mountOnEnter: true,
            unmountOnExit: false,
            onExited: node => node.classList.add('hide'),
            onEnter: node => node.classList.remove('hide')
        }

        //transitionProps={{ ...hideInsteadOfUnmount }}


        return (
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}>
                    <CssBaseline />
                    <div style={{ display: 'flex' }}>
                        <MenuDrawer history={history} />
                        <MenuBar history={history} />
                        <div style={{ width: '100%', height: '100vh', marginLeft: 'auto', marginRight: 'auto', flex: 1 }}>
                            <Router history={history}>
                                <Navigation
                                    globalTransitionProps={{
                                        classNames: 'fade',
                                        timeout: 400,
                                    }}
                                >
                                    <Route exact path='/'>
                                        <AppStart />
                                    </Route>
                                    <Route path='/home'>
                                        <Home />
                                    </Route>
                                    <Route path='/new-password' screen screenProps={{
                                        style: { overflowY: 'overlay' },
                                    }}>
                                        <NewPassword />
                                    </Route>
                                    <Route path='/set-new-password' screen screenProps={{
                                        style: { overflowY: 'overlay' },
                                    }}>
                                        <SetNewPassword />
                                    </Route>
                                    <Route path='/support'>
                                        <Support />
                                    </Route>
                                    <Route path='/messages'>
                                        <Messages />
                                    </Route>
                                    <Route exact path='/invoice-list'>
                                        <InvoiceList />
                                    </Route>
                                    <Route path='/invoice-list/invoice'>
                                        <Invoice />
                                    </Route>
                                    <Route exact path='/subscriptions'>
                                        <Subscriptions />
                                    </Route>
                                    <Route exact path='/subscriptions/subscription'>
                                        <Subscription />
                                    </Route>
                                    <Route path='/subscriptions/subscription/selectsimtype'>
                                        <SelectSimType />
                                    </Route>
                                    <Route path='/subscriptions/subscription/hiddennumber'>
                                        <HiddenNumber />
                                    </Route>
                                    <Route path='/shopping'>
                                        <Shopping />
                                    </Route>
                                    <Route path='/profile'>
                                        <Profile />
                                    </Route>
                                    <Route path='/callback'>
                                        <Callback />
                                    </Route>
                                    <Route path='/inscallback'>
                                        <InsCallback />
                                    </Route>
                                    <Route path='/authcallback'>
                                        <AuthCallback />
                                    </Route>
                                    <Route path='/confirmownerchange'>
                                        <ConfirmOwnerChange />
                                    </Route>
                                    <MenuLinks />
                                </Navigation>
                            </Router>
                        </div>
                    </div>
                </SnackbarProvider>
            </MuiThemeProvider>
        );
    }
}

