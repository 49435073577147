import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { SmallButton } from "./components/Buttons";
import { Box, BoxTitle, BoxContent } from './Box';

export const ShoppingBox = (props) => {
    const wDim = useWindowDimensions();
    const theme = useTheme();
    return (
        <>
            <Box disabled={props.disabled} fullWidth background linkAction={props.linkAction}>
                <BoxTitle>
                    <span style={{ color: props.titleColor ? props.titleColor : theme.palette.text.main }}>
                        {props.title}
                    </span>
                </BoxTitle>
                <BoxContent>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={8} style={{ alignSelf: 'start' }}>
                            {props.leftContent}
                        </Grid>
                        <Grid item xs={4} style={{ paddingTop: props.rightContentType==='text' ? '10px' : '0px', marginTop: props.rightContentType === 'text' ? '0px' : '-20px', marginBottom: props.rightContentType === 'text' ? '-10px' : '-40px', textAlign: 'center', paddingLeft: '10px' }}>
                            {props.rightContent}
                        </Grid>
                    </Grid>
                </BoxContent>
                <SmallButton disabled={props.disabled} style={{ marginTop: '10px' }}>{props.buttonText}</SmallButton>
            </Box>
        </>
    );
};


